<div class="columns">

  <div class="column">

    <div class="block">
      <div class="list-title">Song List</div>
      <div class="list-header">Tap/Click a song title to view the diagrams I use to help me play/remember songs.</div>
    </div>

    <ol>
      <li *ngFor="let song of songs">
        <a class="navbar-item" [routerLink]="['/song', song.songTitle]">
          <span class="song-title">{{song.songTitle}}</span>
        </a> by
        <span class="artist-name">{{song.artist}}</span>
        <div>Tuning: <span class="tuning">{{song.tuning}}</span></div>
      </li>
    </ol>



  </div>

</div>
