import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SONGS } from '../../constants/song-chord-mapping';

@Component({
  selector: 'app-song',
  templateUrl: './song.component.html',
  styleUrls: ['./song.component.scss']
})
export class SongComponent implements OnInit {
  public song: any = null;
  public tuning: string = 'Standard';
  public artist: string = 'ARTIST';
  public construction: any[] = [];
  public youtubeLink: string = '';
  public songTitle: string = '';
  public baseImagePath: '../../../assets/images/';

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.songTitle = this.route.snapshot.paramMap.get('title');

    this.song = SONGS.find((song) => song.songTitle === this.songTitle);
    this.artist = this.song.artist;
    this.tuning = this.song.tuning;
    this.youtubeLink = this.song.youtubeLink;
    this.construction = this.song.construction;

  }

}
