import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { NakedFretComponent } from './naked-fret/naked-fret.component';
import { ReleaseHistoryComponent } from './release-history/release-history.component';
import { VideosComponent } from './videos/videos.component';
import { SongListComponent } from './songs/song-list/song-list.component';
import { SongComponent } from './songs/song/song.component';

const routes: Routes = [
  { path: 'home', component: NakedFretComponent },
  { path: 'about', component: AboutComponent },
  { path: 'releases', component: ReleaseHistoryComponent },
  { path: 'songs', component: SongListComponent },
  { path: 'song/:title', component: SongComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'help', component: VideosComponent },

  //catchall
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
