<div class="main-menu">
  <div class="find-us">
    <a href="https://www.facebook.com/groups/nakedfrets/">
      <img src="../../assets/images/f_logo_RGB-Blue_58.png" width="58" height="58" title="Find us on Facebook!">
    </a>
    <a href="https://www.facebook.com/groups/nakedfrets/">
      <img src="../../assets/images/bmc-logo.svg" width="58" height="58" title="Consider giving back">
    </a>
  </div>
  <div class="link-list">
    <ul>
      <li>
        <span class="link-label">EMAIL</span>
        <a class="email-link" href="mailto:mike@nakedfrets.com">me</a>
      </li>
      <li class="link" (click)="handleVideosPage()">
        <span class="link-label">HELP</span>reference
      </li>
      <li class="link" (click)="handleAboutPage()">
        <span class="link-label">ABOUT</span>this tool
      </li>
      <li class="link" (click)="handleReleaseHistoryPage()">
        <span class="link-label">RELEASE</span>History
      </li>
    </ul>
  </div>
</div>
