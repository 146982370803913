<h1>Release History</h1>

<article class="message release-item-container is-info" *ngFor="let release of releases">
  <div class="message-header">
    <p>{{release.date}}</p>
  </div>
  <div class="message-body">
    <div *ngFor="for let change of release.changes" class="history-item mb-2">
      <h4>{{change.title}}</h4>
      <p><span class="heavy">Description</span> {{change.description}}</p>
    </div>
  </div>
</article>
