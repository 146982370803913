<section class="section pl-1 pr-1">
  <div class="container">
    <app-nav></app-nav>

    <div class="columns">

      <div class="column">
        <div class="app">
          <!-- <div class="app-toolbar">
            <img (click)="handlePortraitMode()" src="../assets/images/text_rotate_vertical_black_24dp.svg">
            <img (click)="handleLandscapeMode()" src="../assets/images/text_rotation_none_black_24dp.svg">
          </div> -->
          <router-outlet></router-outlet>
        </div>

        <div class="why-container" style="display: none;">
          <app-about></app-about>
        </div>

        <div class="release-history-container" style="display: none;">
          <app-release-history></app-release-history>
        </div>

        <div class="feedback-container" style="display: none;">
          <app-feedback></app-feedback>
        </div>

        <div class="videos-container" style="display: none;">
          <app-videos></app-videos>
        </div>

        <div class="terms-container" style="display: none;">
          <app-terms></app-terms>
        </div>
      </div>

    </div>

  </div>
</section>
