import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { COMPONENT_IDS } from '@src/app/constants/constants';
import { OrchestratorService } from '@src/app/services/orchestrator.service';

@Component({
  selector: 'app-add-barre-btn',
  templateUrl: './add-barre-btn.component.html',
  styleUrls: ['./add-barre-btn.component.scss']
})
export class AddBarreBtnComponent implements AfterViewInit {
  public addBarreContainerId: string = COMPONENT_IDS.addBarreBtnContainer;
  private numFrets: number;
  private height: number;
  private iconHomeContainer: any;
  private svg: any;

  constructor(private oService: OrchestratorService) { }

  ngAfterViewInit(): void {
    this.createIcon();
  }

  private createIcon(): void {
    this.iconHomeContainer = d3.select('#' + this.addBarreContainerId);
    this.svg = this.iconHomeContainer
      .append('svg')
        .attr('viewbox', '0 0 60 25')
        .attr('width', 60)
        .attr('height', 25)
        .attr('id', COMPONENT_IDS.addBarreBtn)
        .style('width', 63)
        .style('height', 50);

    let firstDot = this.svg
      .append('circle')
        .attr('cx', 15)
        .attr('cy', 25)
        .attr('r', 8)
        .style('stroke', '#000')
        .style('stroke-width', 2)
        .style('fill', '#000');

    let lastDot = this.svg
      .append('circle')
        .attr('cx', 48)
        .attr('cy', 25)
        .attr('r', 8)
        .style('stroke', '#000')
        .style('stroke-width', 2)
        .style('fill', '#000');

    // draw a line from the center of the first dot to the center
    // of the last dot on the same fret
    let x1 = 15;
    let y1 = 25;
    let x2 = 48;
    let y2 = 25;

    let barreG = this.svg
      .append('g')
        .attr('class', 'barre-area-g');

    barreG
      .append('line')
        .attr('x1', x1)
        .attr('y1', y1)
        .attr('x2', x2)
        .attr('y2', y2)
        .attr('class', 'barre-icon')
        .attr('stroke', 'black')
        .style('stroke-width', 8)
        .style('fill', '#000');
  }

  public handleClick(): void {
    let outerContainer = d3.select('#' + COMPONENT_IDS.outerContainer);

    this.oService.unsetSelectedTool();
    this.oService.unlockLegend();

    // add the icon to the selected tool container
    let svg = this.iconHomeContainer.select('svg').node();
    this.oService.setSelectedTool(svg);

    let currentlySelectedColorData = outerContainer.data();
    //console.log('currentlySelectedColorData:', currentlySelectedColorData[0]);
    let currentColor = null;
    let noteName = null;

    if(currentlySelectedColorData[0] !== undefined) {
      currentColor = currentlySelectedColorData[0].selectedColor;
      noteName = (currentColor === 'notename') ? currentlySelectedColorData[0].noteName : null;
    }

    let data = [{
      selectedColor: 'barre',
      previousColor: currentColor,
      notename: noteName
    }];

    return outerContainer.data(data);


  }

}
