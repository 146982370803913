import { ARTIST_NAMES, SONG_TITLES, TUNINGS  } from "./song-constants";
import { CHORDS } from './chords';

export const SONGS = [
  {
    songTitle: SONG_TITLES.DANI_CALIFORNIA,
    artist: ARTIST_NAMES.RHCP,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/GOG-NtZ2C:ig',
    construction: [
      {
        partName: 'intro',
        parts: [CHORDS.Am_1, CHORDS.G_1, CHORDS.Dm_1]
      },
      {
        partName: 'verse',
        parts: [CHORDS.Am_1, CHORDS.G_1, CHORDS.Dm_1]
      },
      {
        partName: 'chorus',
        parts: [CHORDS.F_1, CHORDS.C_2, CHORDS.D_2, CHORDS.G_3]
      }
    ]
  },
  {
    songTitle: SONG_TITLES.NOBODY,
    artist: ARTIST_NAMES.DOOBIE_BROTHERS,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/GOG-NtZ2C:ig',
    construction: [
      {
        partName: 'intro',
        parts: [CHORDS.D_SUS2_1, CHORDS.D9_SUS4_SLASH_C, CHORDS.G6_SLASH_B, CHORDS.Gm13_SLASH_Bb]
      },
      {
        partName: 'verse',
        parts: [CHORDS.D_1, CHORDS.D9_SUS4_SLASH_C, CHORDS.G6_SLASH_B, CHORDS.EM11_SLASH_G]
      },
      {
        partName: 'gonna run the risk...',
        parts: [CHORDS.D9_SUS4_SLASH_C, CHORDS.C5]
      },
      {
        partName: 'chorus is same as verse with these added chords',
        parts: [CHORDS.C6SUS4, CHORDS.D6SUS4]
      }
    ]
  },
  {
    songTitle: SONG_TITLES.MY_FRIENDS,
    artist: ARTIST_NAMES.RHCP,
    tuning: TUNINGS.DROPD,
    youtubeLink: 'https://youtu.be/GOG-NtZ2C:ig',
    construction: [
      {

      },
      {

      },
      {

      }
    ]
  },
  {
    songTitle: SONG_TITLES.THE_WORLD_I_KNOW,
    artist: ARTIST_NAMES.COLLECTIVE_SOUL,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/0Z6zcNrtiYU',
    construction: [
      {
        partName: 'intro',
        parts: [CHORDS.Bm_1, CHORDS.FSHARP_1, CHORDS.G_2, CHORDS.A_ADD4_1]
      },
      {
        partName: 'verse',
        parts: [CHORDS.Bm_1, CHORDS.FSHARP_1, CHORDS.G_2, CHORDS.A_ADD4_1]
      },
      {
        partName: 'prechorus',
        parts: [CHORDS.A_1, CHORDS.A_SUS2_1, CHORDS.A_1, CHORDS.A_SUS2_1, CHORDS.G_ADD11_1]
      },
      {
        partName: 'chorus',
        parts: [CHORDS.D_1, CHORDS.A_1, CHORDS.G_1, CHORDS.D_1, CHORDS.D_SUS4_1, CHORDS.D_1, CHORDS.D_SUS2_1, CHORDS.D_1, CHORDS.D_1, CHORDS.A_1, CHORDS.C_1, CHORDS.G_1, CHORDS.D_1, CHORDS.D_SUS4_1, CHORDS.D_1, CHORDS.D_SUS2_1, CHORDS.D_1]
      },
      {
        partName: 'outro',
        parts: [CHORDS.D_1, CHORDS.FSHARP_2, CHORDS.G_1, CHORDS.A_1]
      }]
  },
  {
    songTitle: SONG_TITLES.MY_FRIENDS,
    artist: ARTIST_NAMES.RHCP,
    tuning: TUNINGS.DROPD,
    youtubeLink: 'https://youtu.be/N5lcA8l2Wyc',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.PLUSH,
    artist: ARTIST_NAMES.STP,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/mvPQ3AHZm1M',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.MEATPLOW,
    artist: ARTIST_NAMES.STP,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/NAni2dxewGI',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.RIGHT_TURN,
    artist: ARTIST_NAMES.AIC,
    tuning: TUNINGS.FLAT,
    youtubeLink: 'https://youtu.be/mcWL3iN8KpU',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.JACK_AND_DIANE,
    artist: ARTIST_NAMES.JOHN_COUGAR,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/_aw6RYTKSc0',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.YOU_WRECK_ME,
    artist: ARTIST_NAMES.TOM_PETTY,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/bOgAvugbNhw',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.YOUR_BODY_IS_A_WONDERLAND,
    artist: ARTIST_NAMES.JOHN_MAYER,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/rVQQNbDir9k',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.MR_JONES,
    artist: ARTIST_NAMES.COUNTING_CROWS,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/38l5TE_Q_Xg',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.WAITING_SAVE_A_LIFE,
    artist: ARTIST_NAMES.CRASH_MOTIVE,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/qQZBFxXBEg8',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.AMY,
    artist: ARTIST_NAMES.CRASH_MOTIVE,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/gWSDs0M2d1U',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.DUST_IN_THE_WIND,
    artist: ARTIST_NAMES.KANSAS,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/xUW4WxEkiH0',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.HOME,
    artist: ARTIST_NAMES.DAUGHTRY,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/hYldjAv6EXA',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.SHUT_YOUR_EYES,
    artist: ARTIST_NAMES.SNOW_PATROL,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/vMahwukp5vk',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  },
  {
    songTitle: SONG_TITLES.WANTED_DEAD_OR_ALIVE,
    artist: ARTIST_NAMES.BON_JOVI,
    tuning: TUNINGS.STANDARD,
    youtubeLink: 'https://youtu.be/lykOttorwiU',
    construction: {
      intro: [],
      verse: [],
      prechorus: [],
      chorus: [],
      bridge: [],
      outro: []
    }
  }
]
