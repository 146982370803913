<a class="item" (click)="handleEditLabels()">
  <div class="lock-and-btn">
    <!-- <img style="visibility: hidden;" class="edit-labels-btn lockable" src="../../../assets/images/lock-solid-red.svg" width="10" height="10"> -->
    <img src="../assets/images/pencil-solid.svg" width="24" height="24" style="width: 63px; height: 50px;">
  </div>
</a>

<div class="modal" id="edit-labels-modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Edit Labels</p>
      <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
      <!-- Content ... -->
      <div class="box">
        <div class="field">
          <label class="label">Label</label>
          <!-- <div class="control icon-row">
            <img src="../assets/images/arrow_up.png" width="12" height="12" style="width: 25px; height: 25px;">
            <img src="../assets/images/arrow_down.png" width="12" height="12" style="width: 25px; height: 25px;">
            <img src="../assets/images/arrow_back.png" width="12" height="12" style="width: 25px; height: 25px;">
            <img src="../assets/images/arrow_forward.png" width="12" height="12" style="width: 25px; height: 25px;">
          </div> -->
          <div class="control">
            <input class="input" type="text" placeholder="Name" id="nf-label">
          </div>

        </div>
        <div class="field">
          <label class="label">Sub-Label</label>
          <!-- <div class="control icon-row">
            <img src="../assets/images/arrow_up.png" width="12" height="12" style="width: 25px; height: 25px;">
            <img src="../assets/images/arrow_down.png" width="12" height="12" style="width: 25px; height: 25px;">
            <img src="../assets/images/arrow_back.png" width="12" height="12" style="width: 25px; height: 25px;">
            <img src="../assets/images/arrow_forward.png" width="12" height="12" style="width: 25px; height: 25px;">
          </div> -->
          <div class="control">
            <input class="input" type="text" placeholder="Name" id="nf-sublabel">
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" (click)="handleSubmit()">Save changes</button>
      <button class="button">Cancel</button>
    </footer>
  </div>
</div>
