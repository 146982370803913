<div class="song">{{songTitle}}</div>
<div>by <span class="artist">{{artist}}</span></div>
<div class="tuning-container">Tuning: <span class="tuning">{{tuning}}</span></div>
<div class="diagrams-by">diagrams by Mike@nakedfrets.com</div>


<section class="section part-section" *ngFor="let parts of construction">
  <div class="part-name">{{parts.partName}}</div>
  <div *ngFor="let part of parts.parts">
    <img src="../../../assets/images/{{part.imageFolder}}/{{part.imageName}}">
  </div>
</section>
