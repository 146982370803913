<section>
  <div class="container">
    <div class="tile is-ancestor">
      <div class="column about-container">
        <h1>About This Tool</h1>
        <div class="about" id="about">
          <p>NakedFrets is a free diagramming tool that lets you create your own guitar chord and scale diagrams.  You can export to .png files or screencap to your heart's content.
          </p>
          <div>Ways you might use this app...</div>
          <ul>
            <li><span class="heavy">Memorization Aide</span> - a natural side-effect of creating diagrams is that it helps you remember where things are on the fretboard.</li>
            <li><span class="heavy">Practice Aide</span> - when I head to my guitar lesson, I make sure to take my phone or an ipad with me because if I have the time,
              I'll create diagrams for the chords, scales, or practice patterns right there.  If I don't want to waste any precious minutes of
              face-to-face instructor time creating diagrams, I'll also take my normal practice book, let the instructor write down what he wants,
              then when I get home, re-create the diagrams so I have them on my phone for practice time.
            </li>
            <li><span class="heavy">Conversation Aide</span> - I belong to several guitar-learning Facebook groups and it's so much easier creating a diagram to "talk to" when
              asking questions.  It's perfect for asking questions like: "What chord is this" or showing someone the where the notes reside on the neck for a particular solo.
            </li>
            <li><span class="heavy">Instruction</span> - maybe you're an instructor.  Why not share your screen and use it as a real-time teaching tool?  Or create diagrams for different lesson plans that you want your students to work on?</li>
          </ul>
          <p>How you want to use the tool is up to you.  I just ask that if you find it useful, consider <a href="https://www.buymeacoffee.com/MiArDe" target="_blank">donating through buy-me-a-coffee</a> or sending it to NakedFrets.com P.O. Box 104 Bensalem, PA 19020.  It'll help re-coup some of the costs
            associated with providing a free, hosted application.  One last thing...  keep coming back as I'll be releasing updates as frequently as life enables me to.
            I have some cool things I'm working on to make this a killer tool!
          </p>

          <p>So start dressing up those frets!  Or leave them naked!  Either way, <span class="heavy">rock on with your bad self!</span></p>
          <span class="signature">~Mike Dean, creator of NakedFrets.com</span>
        </div>
      </div>
    </div>
  </div>
</section>
