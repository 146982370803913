

<section>
  <div class="container main">
    <input type="hidden" name="fretboardModified" id="fretboardModified" value="false">
    <img id="outputImg"/>
    <div id="nakedFrets_2"></div>
    <div class="right-btn-row">
      <div class="selected-tool-container">
        <div id="iconTarget" style="width: 50px; height: 50px;"></div>
        <img src="../assets/images/ban-solid.svg" id="noToolSelected" width="24" height="24" style="width: 50px; height: 50px;">
      </div>
      <div class="always-tools">
        <h3 class="toolbar-col-header">Labels</h3>
        <div><app-label-size-pos-btn></app-label-size-pos-btn></div>
        <div>
          <a class="" (click)="turnOnFretNumbers()">
            <img src="../assets/images/numbers-on.svg" width="24" height="24">
          </a>
          <a class="" (click)="turnOffFretNumbers()">
            <img src="../assets/images/numbers-off.svg" width="24" height="24">
          </a>
        </div>
      </div>
      <div class="icon-container">
        <h3 class="toolbar-col-header">TOOLS</h3>
        <div><app-help-btn></app-help-btn></div>
        <div><app-download-btn></app-download-btn></div>
        <div><app-clear-all-btn></app-clear-all-btn></div>
        <div><app-remove-fret-btn></app-remove-fret-btn></div>
        <div><app-add-fret-btn></app-add-fret-btn></div>
        <div><app-edit-labels-btn></app-edit-labels-btn></div>
        <div><app-eraser-btn></app-eraser-btn></div>
        <div><app-add-barre-btn></app-add-barre-btn></div>
        <h3 class="toolbar-col-header">Note Names</h3>
        <div><app-note-names></app-note-names></div>
        <h3 class="toolbar-col-header">ROOT</h3>
        <div><app-root-finger-btn></app-root-finger-btn></div>
        <h3 class="toolbar-col-header">INTERVALS</h3>
        <div><app-interval-btn></app-interval-btn></div>
        <h3 class="toolbar-col-header">FINGERS</h3>
        <div><app-finger-number-btn></app-finger-number-btn></div>
      </div>

    </div>
  </div>
</section>
