export const DOWNLOAD_TOOLTIP = {
  name: "download",
  tooltip: "The \"Download\" button downloads an image of your diagram in .png format, suitable for upload on most sites accepting photos.\n\nBehavior varies based on device/browser but you should at least be presented with an image of the fretboard only (no buttons, etc).  On iOS 15/iPhone 12, for example you can hold your finger down on the image until an options screen appears letting you choose to save as a photo or share it."
};

export const CLEAR_TOOLTIP = {
  name: "clearing",
  tooltip: "The \"Clear All\" button clears all dots and barres from the fretboard and removes any muted/open string designations."
};

export const REMOVE_FRETS_TOOLTIP = {
  name: "removingFrets",
  tooltip: "The \"Remove One Fret\" button removes one fret from the bottom of the fretboard."
};

export const ADD_FRETS_TOOLTIP = {
  name: "addingFrets",
  tooltip: "The \"Add One Fret\" button adds one fret to the bottom of the fretboard."
};

export const EDIT_LABELS_TOOLTIP = {
  name: "editingLabels",
  tooltip: "The \"Edit Labels\" allows you to edit the diagram's label and sublabel."
};

export const ERASER_TOOLTIP = {
  name: "erasing",
  tooltip: "The \"Eraser\" button allows you to clear one element at a time from the fretboard by clicking on it.  When you click on the eraser, it will turn red to indicate it is \"on\" and also display a Lock icon.  It will stay \"on\" until it is clicked again.  While the eraser is turned on, you cannot use the finger number or \"add a barre\" feature or place more dots on the fretboard."
};

export const EDIT_BARRES_TOOLTIP = {
  name: "addingBarres",
  tooltip: "The \"Add Barre\" button allows you to create the \"barre\" for barre chords - it connects the first and last dot on the same fret with a bar.  To use it, there must be at least two dots on the same fret.  Click on \"Add a barre\" and then click on one of the two dots (it doesn't matter which).  The barre will then be drawn."
};

export const CHANGE_DOT_COLOR_TOOLTIP = {
  name: "changingDotColors",
  tooltip: "The \"Change Dot Color\" buttons allow you to select a different dot color.  Useful for showing the root or related patterns, for example."
};

export const MUTE_STRINGS_TOOLTIP = {
  name: "mutingStrings",
  tooltip: "The \"Muted/Open String\" buttons add the corresponding icon above the string/nut.  To use it, click on the Mute (\"X\") or Open (\"O\") icon and then click the area right above the string on which you wish to place it."
};

export const FINGER_NUMBER_TOOLTIP = {
  name: "fingerNumbers",
  tooltip: "The \"Finger Number\" buttons allow you to assign a finger to a dot placed on the fretboard."
};

export const HELP_TOOLTIP = {
  name: "help",
  tooltip: "The \"Help\" button opens the help section so you can see what each button does."
};
