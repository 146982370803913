import * as d3 from '@node_modules/d3/dist/d3';
import { v4 as uuidv4 } from 'uuid/dist';
import { IMargins, INakedFret } from '@models/nf-interfaces';
import * as tooltips from '../constants/tooltips';

export class Toolbar {
  private config: INakedFret;

  // container references
  private outerContainer: any;
  private fretboardColumnContainer: any;
  private legendColumnContainer: any;
  private toolbarContainer: any;
  private fretboardContainer: any;
  private labelContainer: any;
  private sublabelContainer: any;
  private legendContainer: any;
  private fieldFormContainer: any;

  private svg: any; // the toolbar svg
  private width: number;
  private height: number;
  private filledDotRadius: number;
  private margins: IMargins;
  private fretboardNumberWidth: number;
  private hostId: string; // the main uuid we're appending to all IDs
  private svgId: string; //  this toolbar's <svg>
  private defs: any; // this toolbar's defs
  private dotColorSelectionRectWidth: number;
  private dotColorSelectionRectHeight: number;
  private dotColorChoices: string[];
  private selectedDotColor: string;

  constructor(config: INakedFret) {
    this.config = config;
    this.parseConfig();
    this.buildChart();
  }

  private buildChart(): void {
    this.setupSvg();
    this.setupDefs();
    this.drawChart();
  }

  parseConfig(): void {
    this.outerContainer = d3.select('#' + this.config.ids.outerContainerId);
    this.fretboardColumnContainer = d3.select('#' + this.config.ids.fretboardColumnContainerId);
    this.legendColumnContainer = d3.select('#' + this.config.ids.legendColumnContainerId);
    this.toolbarContainer = d3.select('#' + this.config.ids.toolbarContainerId);
    this.fretboardContainer = d3.select('#' + this.config.ids.fretboardContainerId);
    this.labelContainer = d3.select('#' + this.config.ids.labelContainerId);
    this.sublabelContainer = d3.select('#' + this.config.ids.sublabelContainerId);
    this.legendContainer = d3.select('#' + this.config.ids.legendContainerId);
    this.fieldFormContainer = d3.select('#' + this.config.ids.fieldFormContainerId);
    this.filledDotRadius = this.config.filledDotRadius;
    this.fretboardNumberWidth = this.config.fretboardNumberWidth;
    this.hostId = this.config.hostId;
    this.svgId = 'toolbar-svg-' + this.hostId;
    this.dotColorSelectionRectHeight = this.config.dotColorSelectionRectHeight;
    this.dotColorSelectionRectWidth = this.config.dotColorSelectionRectWidth;
    this.dotColorChoices = this.config.dotColorChoices;
    this.margins = this.config.margins;

    this.calculateWidth();
    this.calculateHeight();
  }

  private calculateWidth(): void {
    this.width = ((this.config.numberStrings - 1) * this.config.pxBetweenStrings);
  }

  private calculateHeight(): void {
    this.height = this.config.toolbarHeight;
  }

  private setupSvg(): void {
    this.svg = this.toolbarContainer
      .append('svg')
        .attr('viewbox', '0 0 ' + this.width + ' ' + this.height) // 30 is the current font size i'm using
        .attr('width', this.width + this.margins.left + this.margins.right + this.fretboardNumberWidth) // adding 20 for the fret numbers
        .attr('height', this.height)
        .attr('id', this.svgId)
        .style('background-color', '#fff');
  }

  private setupDefs(): void {
    this.defs = this.svg
      .append('defs');

    this.defs
      .append('g')
        .attr('id', 'dot-color-selection-rect-g-' + this.hostId)
        .append('rect')
          .attr('width', this.dotColorSelectionRectWidth)
          .attr('height', this.dotColorSelectionRectHeight)
          .attr('id', 'dot-color-selection-rect-' + this.hostId)
          .style('stroke', '#000')
          .style('fill', '#ffffff')
          .style('fill-opacity', '0');

    this.defs
      .append('g')
        .attr('id', 'clickable-icon-area-g-' + this.hostId)
        .attr('data-toolbarSelector', '#' + this.svgId)
        .style('cursor', 'pointer')
      .append('rect')
        .attr('width', this.dotColorSelectionRectWidth)
        .attr('height', this.dotColorSelectionRectHeight)
        .style('stroke', 'none')
        .style('fill', '#ff0000')
        .style('fill-opacity', '0')
        .attr('class', 'clickable-icon-area');
  }

  private drawMutedStringClickableAreas(): void {
    let xPos = 0;
    for(let x = 0; x < 6; x++) {
      this.svg
        .append('use')
          .attr('href', '#clickableStringArea')
          .attr('x', xPos) // the 2 is to pull the clickable area to the left more
          .attr('y', -10);
      xPos += 24.5;
    }
  }

  public setSelectedTool(svg: any): void {
    // add the icon to the selected tool container

    let target = d3.select('#iconTarget').node();
    target.insertBefore(svg.cloneNode(true), null);

    // hide the no tool selected icon
    d3.select('#noToolSelected').style('display', 'none');

    // show the iconTarget
    d3.select('#iconTarget').style('display', 'flex');
  }

  public createCircleIcon(svg: any): any {
    console.log('svg:', svg);
    let clone = svg.cloneNode(true);
    let newSvg = d3.select(clone);
    // our buttons are 70 x 70 so we create a clone anyway, we just resize it
    // to fit our selected tool icon holder at 50 x 50
    newSvg
      .attr('viewbox', '0 0 50 50')
      .attr('width', 50)
      .attr('height', 50)
      .attr('class', 'selected-dot-color-choice');

    let newCircle = newSvg.select('circle');
    newCircle
      .attr('cx', 25)
      .attr('cy', 25)
      .attr('r', 24);

    return newSvg;
  }

  public unsetSelectedTool(): void {
    // remove the icon
    d3.select('#iconTarget').selectAll('svg').remove();
    d3.select('#iconTarget').selectAll('.selected-tool').remove();
    d3.select('#iconTarget').style('display', 'none');

    // show the no tool selected icon again
    d3.select('#noToolSelected').style('display', 'flex');
  }

  private drawChart(): void {
    let self = this;
    // let prevStart = this.margins.left + 12.5;
    let prevStart = this.margins.left;

    for (let x = 0; x < this.dotColorChoices.length; x++) {
      this.svg
        .append('circle')
          .attr('cx', prevStart)
          .attr('cy', (this.height/2)) // subtracting the radius
          .attr('r', this.filledDotRadius)
          .attr('class', 'dot-color-choice-' + x)
          .style('stroke', '#000')
          .style('fill', this.dotColorChoices[x])
        .append('title')
          .text('Use this color for dots');

      let data = [{
        color: this.dotColorChoices[x],
        x: prevStart - 10,
        y: 2
      }];

      this.svg
        .append('use')
          .attr('href', '#clickable-icon-area-g-' + this.hostId)
          .attr('x', prevStart - 15)
          .attr('y', 2)
          .style('cursor', 'pointer')
          .data(data)
          .on('click', function(event, d) {
            self.unsetSelectedTool();
            self.selectedDotColor = d.color;
            let data = [{
              selectedColor: d.color
            }];

            // set the selected color as a data attribute on the host container since the fretboard
            // object will have access to it.  The host container is the outer container that houses the
            // fretboard, toolbar, etc. instances.  Each instance shares the same host so has access to the same
            // data.
            // this.outerContainer
            //   .attr('data-selectedColor', this.selectedDotColor);
            self.outerContainer
              .data(data);

            let circleX = d.x;
            let circleY = d.y;
            let selectedDot = self.svg.select('.dot-color-choice-' + x);
            let colorChoiceSvg = d3.select(selectedDot.node().parentNode);

            let target = d3.select('#iconTarget').node();
            let circleSvg = d3.create('svg');
            let clonedCircle = selectedDot.node().cloneNode(true);
            let cloneSelection = d3.select(clonedCircle);
            // our buttons are 70 x 70 so we create a clone anyway, we just resize it
            // to fit our selected tool icon holder at 50 x 50
            circleSvg
              .attr('viewbox', '0 0 50 50')
              .attr('width', 50)
              .attr('height', 50);
            circleSvg
              .append('circle')
                .attr('cx', 25)
                .attr('cy', 25)
                .attr('r', 24)
                .attr('class', 'selected-dot-color-choice')
                .style('stroke', '#000')
                .style('fill', self.dotColorChoices[x]);

            self.setSelectedTool(circleSvg.node());

            // get rid of any selection rectangles out there
            colorChoiceSvg
              .select('.dot-color-selection-rect-g')
              .remove();

            // add the new selection rect
            // colorChoiceSvg
            //   .append('use')
            //     .attr('href', '#dot-color-selection-rect-g-' + self.hostId)
            //     .attr('width', self.dotColorSelectionRectWidth)
            //     .attr('height', self.dotColorSelectionRectHeight)
            //     .attr('class', 'dot-color-selection-rect-g')
            //     .attr('x', circleX - 5)
            //     .attr('y', circleY)
            //     .style('stroke', '#000')
            //     .style('fill', '#ffffff')
            //     .style('fill-opacity', '100%');
          })
          .append('title').attr('class', 'tooltip').text(tooltips.CHANGE_DOT_COLOR_TOOLTIP.tooltip);

      prevStart += 50; // this adjusts the space between dots on the toolbar
    }

    prevStart += 10;

    // create icons for muted and open strings
    let mutedString = this.svg
      .append('circle')
        .attr('cx', prevStart - 10)
        .attr('cy', (this.height/2))
        .attr('r', this.filledDotRadius)
        .attr('class', 'dot-color-choice-b')
        .style('stroke', '#000')
        .style('fill', '#fff');

    this.svg.append('text')
      .text('X')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', prevStart - 17)
        .attr('y', 25)
        .style('font-size', '24px');

    let mutedData = [{
      selectedColor: 'muted',
      previousColor: this.dotColorChoices[0],
      hostId: self.hostId
    }];
    this.svg
      .append('use')
        .attr('href', '#clickable-icon-area-g-' + this.hostId)
        .attr('x', prevStart - 25)
        .attr('y', 2)
        .style('cursor', 'pointer')
        .data(mutedData)
        .on('click', function(event, d) {
          self.unsetSelectedTool();

          // add a muted string icon above the string clicked
          let data = [{
            selectedColor: d.selectedColor
          }];

          // set the selected color as a data attribute on the host container since the fretboard
          // object will have access to it.  The host container is the outer container that houses the
          // fretboard, toolbar, etc. instances.  Each instance shares the same host so has access to the same
          // data.
          self.outerContainer
            .data(data);

          self.selectedDotColor = d.color;

          let circleX = d.x;
          let circleY = d.y;
          let thisDot = d3.select(self).node();
          let thisDotSvg = d3.select(thisDot.parentNode);

          let target = d3.select('#iconTarget').node();
          let muteCircleSvg = d3.create('svg');
          // let clonedCircle = thisDot.node().cloneNode(true);
          // let cloneSelection = d3.select(clonedCircle);
          muteCircleSvg
              .attr('viewbox', '0 0 50 50')
              .attr('width', 50)
              .attr('height', 50);

          muteCircleSvg
            .append('circle')
              .attr('cx', 25)
              .attr('cy', 25)
              .attr('r', 24)
              .attr('class', 'muted-string-selection')
              .style('stroke', '#000')
              .style('fill', '#fff');

          muteCircleSvg
            .append('text')
              .text('X')
                .style('stroke', '#000')
                .style('fill', '#000')
                .attr('x', 16)
                .attr('y', 35)
                .style('font-size', '28px');

          self.setSelectedTool(muteCircleSvg.node());

          // get rid of any selection rectangles out there
          thisDotSvg
            .selectAll('.dot-muted-selection-rect-g')
            .remove();

          // add the new selection rect
          // thisDotSvg
          //   .append('use')
          //     .attr('href', '#dot-color-selection-rect-g-' + self.hostId)
          //     .attr('width', self.dotColorSelectionRectWidth)
          //     .attr('height', self.dotColorSelectionRectHeight)
          //     .attr('class', 'dot-muted-selection-rect-g')
          //     .attr('x', prevStart - 36.5)
          //     .attr('y', 2)
          //     .style('stroke', '#000')
          //     .style('fill', '#ffffff')
          //     .style('fill-opacity', '100%');
        })
        .append('title').attr('class', 'tooltip').text(tooltips.MUTE_STRINGS_TOOLTIP.tooltip);

    prevStart += 11.5;

    let openString = this.svg
      .append('circle')
        .attr('cx', prevStart + 22)
        .attr('cy', (this.height/2))
        .attr('r', this.filledDotRadius)
        .attr('class', 'dot-color-choice-b')
        .style('stroke', '#000')
        .style('fill', '#fff');

    this.svg.append('text')
      .text('O')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', prevStart + 13)
        .attr('y', 25)
        .style('font-size', '24px');

    let openData = [{
      selectedColor: 'open',
      previousColor: this.dotColorChoices[0],
      hostId: self.hostId
    }];
    this.svg
      .append('use')
        .attr('href', '#clickable-icon-area-g-' + this.hostId)
        .attr('x', prevStart + 7)
        .attr('y', 2)
        .style('cursor', 'pointer')
        .data(openData)
        .on('click', function(event, d) {
          self.unsetSelectedTool();

          let data = [{
            selectedColor: d.selectedColor
          }];

          // set the selected color as a data attribute on the host container since the fretboard
          // object will have access to it.  The host container is the outer container that houses the
          // fretboard, toolbar, etc. instances.  Each instance shares the same host so has access to the same
          // data.
          self.outerContainer
            .data(data);

          let thisDot = d3.select(this).node();
          let thisDotSvg = d3.select(thisDot.parentNode);
          let openCircleSvg = d3.create('svg');

          openCircleSvg
              .attr('viewbox', '0 0 50 50')
              .attr('width', 50)
              .attr('height', 50);

          openCircleSvg
            .append('circle')
              .attr('cx', 25)
              .attr('cy', 25)
              .attr('r', 24)
              .attr('class', 'open-string-selection')
              .style('stroke', '#000')
              .style('fill', '#fff');

          openCircleSvg
            .append('text')
              .text('O')
                .style('stroke', '#000')
                .style('fill', '#000')
                .attr('x', 14)
                .attr('y', 35)
                .style('font-size', '28px');

          self.setSelectedTool(openCircleSvg.node());

          // get rid of any selection rectangles out there
          thisDotSvg
            .selectAll('.dot-open-selection-rect-g')
            .remove();

          // add the new selection rect
          // thisDotSvg
          //   .append('use')
          //     .attr('href', '#dot-color-selection-rect-g-' + self.hostId)
          //     .attr('width', self.dotColorSelectionRectWidth)
          //     .attr('height', self.dotColorSelectionRectHeight)
          //     .attr('class', 'dot-open-selection-rect-g')
          //     .attr('x', prevStart + 7)
          //     .attr('y', 2)
          //     .style('stroke', '#000')
          //     .style('fill', '#ffffff')
          //     .style('fill-opacity', '100%');
        })
        .append('title').attr('class', 'tooltip').text(tooltips.MUTE_STRINGS_TOOLTIP.tooltip);

  }
}
