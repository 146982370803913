import { Injectable } from '@angular/core';
import { IOrchestratorObj } from '../models/nf-interfaces';
import * as d3 from '@node_modules/d3/dist/d3';

@Injectable({
  providedIn: 'root'
})
export class OrchestratorService {
  private orchestratorObj: IOrchestratorObj = {
    toolbar: null,
    mutebar: null,
    fretboard: null,
    labels: null,
    config: null
  };
  private legendLocked: boolean = false;

  constructor() { }

  public setObject(obj: IOrchestratorObj): void {
    this.orchestratorObj = obj;
  }

  public getObject(objectKey: string): any {
    return this.orchestratorObj[objectKey];
  }

  public lockLegend(): void {
    this.legendLocked = true;
  }

  public unlockLegend(): void {
    this.legendLocked = false;
  }

  public isLegendLocked(): boolean {
    return this.legendLocked;
  }

  public setSelectedTool(svg: any): void {
    // add the icon to the selected tool container
    // let svg = this.iconHomeContainer.select('svg').node();

    let target = d3.select('#iconTarget').node();
    target.insertBefore(svg.cloneNode(true), null);

    // hide the no tool selected icon
    d3.select('#noToolSelected').style('display', 'none');

    // show the iconTarget
    d3.select('#iconTarget').style('display', 'flex');
  }

  public unsetSelectedTool(): void {
    // remove the icon
    d3.select('#iconTarget').selectAll('svg').remove();
    d3.select('#iconTarget').selectAll('.selected-tool').remove();
    d3.select('#iconTarget').style('display', 'none');

    // show the no tool selected icon again
    d3.select('#noToolSelected').style('display', 'flex');
  }

  public createCircleIcon(svg: any, iconText: string, textX: number, textY: number): any {
    let clone = svg.cloneNode(true);
    let newSvg = d3.select(clone);
    // our buttons are 70 x 70 so we create a clone anyway, we just resize it
    // to fit our selected tool icon holder at 50 x 50
    newSvg
      .attr('viewbox', '0 0 50 50')
      .attr('width', 50)
      .attr('height', 50);

    let newCircle = newSvg.select('circle');
    newCircle
      .attr('cx', 25)
      .attr('cy', 25)
      .attr('r', 24);

    let newText = newSvg.select('text').select('tspan');
    newText
      .attr('x', textX)
      .attr('y', textY)
      .text(iconText);

    return newSvg;
  }
}
