import { COMPILER_OPTIONS, Component, OnInit } from '@angular/core';
import { COMPONENT_IDS } from '@src/app/constants/constants';
import { ModalService } from '@src/app/services/modal.service';
import * as d3 from '@node_modules/d3/dist/d3';
import { NAKED_FRETS } from '@src/app/models/nf-interfaces';

@Component({
  selector: 'app-label-size-pos-btn',
  templateUrl: './label-size-pos-btn.component.html',
  styleUrls: ['./label-size-pos-btn.component.scss']
})
export class LabelSizePosBtnComponent implements OnInit {

  private labelX = 0;
  private labelY = 0;
  private sublabelX = 0;
  private sublabelY = 0;
  public sublabelMinY = 400;
  public sublabelMaxY = 530;


  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  public openLabelFormat(): void {
    let el = document.getElementById("labelToolbar");

    if(el.style.display === 'block') {
      el.style.display = 'none';
    } else {
      el.style.display = 'block';
    }
  }

  public closeLabelFormat(): void {
    let el = document.getElementById("labelToolbar");
    el.style.display = 'none';
  }

  public handleSubmit(): void {
    let config = NAKED_FRETS[0];

    let label = d3.select('#nf-label').property('value');
    let sublabel = d3.select('#nf-sublabel').property('value');

    // const newChartLabel = d3.select('#selected-chart-label').node().value;
    // const newChartSublabel = d3.select('#selected-chart-sublabel').node().value;
    // const fbWidth = ((config.numberStrings - 1) * config.pxBetweenStrings);

    // figure out some font sizes for different lengths
    let sizeLengths = {
      1: 100,
      2: 100,
      3: 100,
      4: 80,
      5: 70,
      6: 60,
      7: 60
    }

    // let newSize = sizeLengths[label.length];

    d3.select('#labelTSpan')
      .text(label);

    d3.select('#sublabelTSpan')
      .text(sublabel);

    d3.select('#field-container').remove();
  }

  public labelSizeIncrease(): void {
    let labelParent = document.getElementById('labelTSpan').parentElement;
    let labelFontSize = parseInt(labelParent.style.fontSize);

    labelParent.style.fontSize = labelFontSize + 5 + 'px';
  }

  public labelSizeDecrease(): void {
    let labelParent = document.getElementById('labelTSpan').parentElement;
    let labelFontSize = parseInt(labelParent.style.fontSize);

    labelParent.style.fontSize = labelFontSize - 5 + 'px';
  }

  public sublabelSizeIncrease(): void {
    let sublabelParent = document.getElementById('sublabelTSpan').parentElement;
    let labelFontSize = parseInt(sublabelParent.style.fontSize);

    sublabelParent.style.fontSize = labelFontSize + 5 + 'px';
  }

  public sublabelSizeDecrease(): void {
    let sublabelParent = document.getElementById('sublabelTSpan').parentElement;
    let labelFontSize = parseInt(sublabelParent.style.fontSize);

    sublabelParent.style.fontSize = labelFontSize - 5 + 'px';
  }

  public setLabelX(): void {
    // labelParent is the text element containing the label's span
    let labelParent = document.getElementById('labelTSpan').parentElement;
    // X position of the text element
    this.labelX = parseInt(labelParent.getAttribute('x'));
    // value of the slider which is a range of 22 - 250
    let sliderX = document.getElementById("labelX");
    // value as an integer
    let sliderXValue = parseInt((<HTMLInputElement>sliderX).value);

    labelParent.setAttribute('x', sliderXValue.toString());
  }

  public setLabelY(): void {
    // labelParent is the text element containing the label's span
    let labelParent = document.getElementById('labelTSpan').parentElement;
    // Y position of the text element
    this.labelY = parseInt(labelParent.getAttribute('y'));
    // value of the slider which is a range of 443 - ?
    let sliderY = document.getElementById("labelY");
    // value as an integer
    let sliderYValue = parseInt((<HTMLInputElement>sliderY).value);

    labelParent.setAttribute('y', sliderYValue.toString());
  }

  public setSublabelX(): void {
    // labelParent is the text element containing the label's span
    let sublabelParent = document.getElementById('sublabelTSpan').parentElement;
    // X position of the text element
    this.sublabelX = parseInt(sublabelParent.getAttribute('x'));
    // value of the slider which is a range of 22 - 250
    let sliderX = document.getElementById("sublabelX");
    // value as an integer
    let sliderXValue = parseInt((<HTMLInputElement>sliderX).value);

    sublabelParent.setAttribute('x', sliderXValue.toString());
  }

  public setSublabelY(): void {
    // labelParent is the text element containing the label's span
    let sublabelParent = document.getElementById('sublabelTSpan').parentElement;
    // Y position of the text element
    this.sublabelY = parseInt(sublabelParent.getAttribute('y'));
    // value of the slider
    let sliderY = document.getElementById("sublabelY");
    // value as an integer
    let sliderYValue = parseInt((<HTMLInputElement>sliderY).value);

    this.sublabelMinY = this.sublabelY - 75;
    this.sublabelMaxY = this.sublabelY + 75;

    sublabelParent.setAttribute('y', sliderYValue.toString());
  }

  public labelUp(): void {
    let labelParent = document.getElementById('labelTSpan').parentElement;
    let labelY = parseInt(labelParent.getAttribute('y'));

    labelParent.setAttribute('y', labelY - 5 + 'px');
  }

  public labelDown(): void {
    let labelParent = document.getElementById('labelTSpan').parentElement;
    let labelY = parseInt(labelParent.getAttribute('y'));

    labelParent.setAttribute('y', labelY + 5 + 'px');
  }

  public labelLeft(): void {
    let labelParent = document.getElementById('labelTSpan').parentElement;
    let labelX = parseInt(labelParent.getAttribute('x'));

    labelParent.setAttribute('x', labelX - 5 + 'px');
  }

  public labelRight(): void {
    let labelParent = document.getElementById('labelTSpan').parentElement;
    let labelX = parseInt(labelParent.getAttribute('x'));

    labelParent.setAttribute('x', labelX + 5 + 'px');
  }

  public sublabelUp(): void {
    let sublabelParent = document.getElementById('sublabelTSpan').parentElement;
    let labelY = parseInt(sublabelParent.getAttribute('y'));

    sublabelParent.setAttribute('y', labelY - 5 + 'px');
  }

  public sublabelDown(): void {
    let sublabelParent = document.getElementById('sublabelTSpan').parentElement;
    let labelY = parseInt(sublabelParent.getAttribute('y'));

    sublabelParent.setAttribute('y', labelY + 5 + 'px');
  }

  public sublabelLeft(): void {
    let sublabelParent = document.getElementById('sublabelTSpan').parentElement;
    let labelX = parseInt(sublabelParent.getAttribute('x'));

    sublabelParent.setAttribute('x', labelX - 5 + 'px');
  }

  public sublabelRight(): void {
    let sublabelParent = document.getElementById('sublabelTSpan').parentElement;
    let labelX = parseInt(sublabelParent.getAttribute('x'));

    sublabelParent.setAttribute('x', labelX + 5 + 'px');
  }

  public closeInstructions(): void {
    d3.select('.instruct').style('visibility', 'hidden');
  }

}
