import { COMPILER_OPTIONS, Component, OnInit } from '@angular/core';
import { COMPONENT_IDS } from '@src/app/constants/constants';
import { ModalService } from '@src/app/services/modal.service';
import * as d3 from '@node_modules/d3/dist/d3';
import { NAKED_FRETS } from '@src/app/models/nf-interfaces';

@Component({
  selector: 'app-edit-labels-btn',
  templateUrl: './edit-labels-btn.component.html',
  styleUrls: ['./edit-labels-btn.component.scss']
})
export class EditLabelsBtnComponent implements OnInit {

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  public handleEditLabels(): void {
    let el = document.getElementById("edit-labels-modal");

    this.modalService.openModal(el);
  }

  public handleSubmit(): void {
    let config = NAKED_FRETS[0];

    let label = d3.select('#nf-label').property('value');
    let sublabel = d3.select('#nf-sublabel').property('value');

    // const newChartLabel = d3.select('#selected-chart-label').node().value;
    // const newChartSublabel = d3.select('#selected-chart-sublabel').node().value;
    // const fbWidth = ((config.numberStrings - 1) * config.pxBetweenStrings);

    // figure out some font sizes for different lengths
    let sizeLengths = {
      1: 100,
      2: 100,
      3: 100,
      4: 80,
      5: 70,
      6: 60,
      7: 60
    }

    // let newSize = sizeLengths[label.length];

    d3.select('#labelTSpan')
      .text(label);

    d3.select('#sublabelTSpan')
      .text(sublabel);

    d3.select('#field-container').remove();
  }

}
