import { Component, OnInit } from '@angular/core';
import { releases } from '../constants/releases';


@Component({
  selector: 'app-release-history',
  templateUrl: './release-history.component.html',
  styleUrls: ['./release-history.component.scss']
})
export class ReleaseHistoryComponent implements OnInit {
  public releases: any[] = releases;

  constructor() {  }

  ngOnInit(): void {
  }

}
