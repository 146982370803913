import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { COMPONENT_IDS, FILLED_DOT_TEXT } from '@src/app/constants/constants';
import { OrchestratorService } from '@src/app/services/orchestrator.service';

@Component({
  selector: 'app-root-finger-btn',
  templateUrl: './root-finger-btn.component.html',
  styleUrls: ['./root-finger-btn.component.scss']
})
export class RootFingerBtnComponent implements AfterViewInit {
  public rootBtnContainerId: string = COMPONENT_IDS.rootFingerBtnContainer;
  constructor(private oService: OrchestratorService) { }

  ngAfterViewInit(): void {
    this.createIcons();
  }

  private createIcons(): void {
    let self = this;

    let container = d3.select('#' + this.rootBtnContainerId);
    let svg = container
      .append('svg')
        .attr('viewbox', '0 0 70 70')
        .attr('width', 70)
        .attr('height', 70);

    let iconStartY = 0;

    let rootDot = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', iconStartY + 30 )
        .attr('r', 25)
        .attr('class', 'dot-root')
        .style('stroke', '#000')
        .style('fill', '#000')
        .style('fill-opacity', '1');

    let text = FILLED_DOT_TEXT.root;
    svg.append('text')
      .append('tspan')
      .text(text)
        .attr('class', 'filled-dot-root-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 19)
        .attr('y', iconStartY + 42)
        .style('font-size', '36px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleFingerIconClick(this);
        });
  }

  private handleFingerIconClick(thisRef: any): void {
    let outerContainer = d3.select('#' + COMPONENT_IDS.outerContainer);

    this.oService.unsetSelectedTool();
    this.oService.unlockLegend();
    this.createSelectedToolIcon();
      let data = [{
        selectedColor: 'root',
        previousColor: null
      }];

    return outerContainer.data(data);
  }

  private createSelectedToolIcon(): void {
    // add the icon to the selected tool container
    let iconHomeContainer = d3.select('#' + this.rootBtnContainerId);
    let svg = iconHomeContainer.select('svg').node();

    let newSvg = this.oService.createCircleIcon(svg, 'R', 12.5, 38);

    this.oService.setSelectedTool(newSvg.node());
  }

}

