import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid/dist';
import * as d3 from '@node_modules/d3/dist/d3';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }

  public async getJson(URL: string) {
    let response = await fetch(URL);
    let json = await response.json();

    return json;
  }

  // public addIds(listWithIds: any[]): any {
  //   for(let x = 0; x < listWithIds.length; x++) {
  //     listWithIds[x].ID = uuidv4();
  //   }

  //   return listWithIds;
  // }

  public attachHandlers(): void {
    let self = this;

    // Add a click event on buttons to open a specific modal
    (document.querySelectorAll('.js-modal-trigger') || []).forEach(($trigger) => {
      const modal = $trigger.dataset.target;
      const $target = document.getElementById(modal);

      $trigger.addEventListener('click', () => {
        this.openModal($target);
      });
    });

    // Add a click event on various child elements to close the parent modal
    (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot, .cancel-button') || []).forEach(($close) => {
      const $target = $close.closest('.modal');

      $close.addEventListener('click', () => {
        this.closeModal($target);
      });
    });

    // manually removing the is-active class
    d3.select('button.cancel-btn').on('click', function() {
      self.closeAllModals();
        // document.getElementById('request-song-modal').classList.remove('is-active');
        // document.getElementById('request-genre-modal').classList.remove('is-active');
    });

    // Add a keyboard event to close all modals
    document.addEventListener('keydown', (event) => {
      const e:any = event || window.event;

      if (e.keyCode === 27) { // Escape key
        this.closeAllModals();
      }
    });
  }

  // "opens" the modal by adding the is-active class to display it
  public openModal($el) {
    $el.classList.add('is-active');
  }

  // remove the is-active class to hide it
  public closeModal($el) {
    $el.classList.remove('is-active');
  }

  // cancels the request-genre modal
  public handleCancel(): void {
    this.closeModal(document.getElementById('feedback-submit-modal'));
  }

  // adds the is-active class to our modal to make it visible
  public openSelectSongWarningModal($el): void {
    $el.classList.add('is-active');
  }

  // removes the is-active class from our modal to hide it
  public closeSelectSongWarningModal($el): void {
    $el.classList.remove('is-active');
  }

  // closes all modals
  public closeAllModals(): void {
    (document.querySelectorAll('.modal') || []).forEach(($modal) => {
      this.closeModal($modal);
    });
  }
}
