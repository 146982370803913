import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { COMPONENT_IDS, INTERVALS } from '@src/app/constants/constants';
import { OrchestratorService } from '@src/app/services/orchestrator.service';
import { text } from 'd3';

@Component({
  selector: 'app-interval-btn',
  templateUrl: './interval-btn.component.html',
  styleUrls: ['./interval-btn.component.scss']
})
export class IntervalBtnComponent implements AfterViewInit {
  public intervalBtnContainerId: string = COMPONENT_IDS.intervalBtnContainer;

  constructor(private oService: OrchestratorService) { }

  ngAfterViewInit(): void {
    this.createIcons();
  }

  private createIcons(): void {
    let self = this;

    let container = d3.select('#' + this.intervalBtnContainerId);
    let svg = container
      .append('svg')
        .attr('viewbox', '0 0 70 1360')
        .attr('width', 70)
        .attr('height', 1360)
        .attr('id', 'nf-interval-svg');

    // draw interval icons
    let perfectUnison = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 30)
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.perfectUnison)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectUnison, this, 8, 36);
        });

    let perfectUnisonText = INTERVALS.perfectUnison;
    svg.append('text')
      .append('tspan')
      .text(perfectUnisonText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 15)
        .attr('y', 42)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectUnison, this, 8, 36);
        });

    let perfectUnisonRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 85)
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.perfectUnisonRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectUnisonRoman, this, 20.5, 36.5);
        });

      let perfectUnisonRomanText = INTERVALS.perfectUnisonRoman;
      svg.append('text')
        .append('tspan')
        .text(perfectUnisonRomanText)
          .attr('class', 'filled-dot-interval-icon-text')
          .style('stroke', 'none')
          .style('fill', '#fff')
          .attr('x', 28)
          .attr('y', 95)
          .style('font-size', '30px')
          .style('font-weight', 'bold')
          .style('cursor', 'pointer')
          .on('click', function(event) {
            self.handleIntervalIconClick(INTERVALS.perfectUnisonRoman, this, 20.5, 36.5);
          });

    let minorSecond = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 140 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.minorSecond)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSecond, this, 4, 34);
        });

    let minorSecondText = INTERVALS.minorSecond;
      svg.append('text')
        .append('tspan')
        .text(minorSecondText)
          .attr('class', 'filled-dot-interval-icon-text')
          .style('stroke', 'none')
          .style('fill', '#fff')
          .attr('x', 11)
          .attr('y', 148)
          .style('font-size', '30px')
          .style('font-weight', 'bold')
          .style('cursor', 'pointer')
          .on('click', function(event) {
            self.handleIntervalIconClick(INTERVALS.minorSecond, this, 4, 34);
          });

    let minorSecondRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 195 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.minorSecondRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSecondRoman, this, 17.5, 36.5);
        });

    let minorSecondRomanText = INTERVALS.minorSecondRoman;
      svg.append('text')
        .append('tspan')
        .text(minorSecondRomanText)
          .attr('class', 'filled-dot-interval-icon-text')
          .style('stroke', 'none')
          .style('fill', '#fff')
          .attr('x', 25)
          .attr('y', 205)
          .style('font-size', '30px')
          .style('font-weight', 'bold')
          .style('cursor', 'pointer')
          .on('click', function(event) {
            self.handleIntervalIconClick(INTERVALS.minorSecondRoman, this, 17.5, 36.5);
          });

    let majorSecond = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 250 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.majorSecond)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSecond, this, 4, 35);
        });

    let majorSecondText = INTERVALS.majorSecond;
      svg.append('text')
        .append('tspan')
        .text(majorSecondText)
          .attr('class', 'filled-dot-interval-icon-text')
          .style('stroke', 'none')
          .style('fill', '#fff')
          .attr('x', 11)
          .attr('y', 260)
          .style('font-size', '30px')
          .style('font-weight', 'bold')
          .style('cursor', 'pointer')
          .on('click', function(event) {
            self.handleIntervalIconClick(INTERVALS.majorSecond, this, 4, 35);
          });

    let majorSecondRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 305 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.majorSecondRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSecondRoman, this, 16.5, 36);
        });

    let majorSecondRomanText = INTERVALS.majorSecondRoman;
      svg.append('text')
        .append('tspan')
        .text(majorSecondRomanText)
          .attr('class', 'filled-dot-interval-icon-text')
          .style('stroke', 'none')
          .style('fill', '#fff')
          .attr('x', 24)
          .attr('y', 314)
          .style('font-size', '30px')
          .style('font-weight', 'bold')
          .style('cursor', 'pointer')
          .on('click', function(event) {
            self.handleIntervalIconClick(INTERVALS.majorSecondRoman, this, 16.5, 36);
          });

    let minorThird = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 360 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.minorThird)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorThird, this, 4, 34);
        });

    let minorThirdText = INTERVALS.minorThird;
    svg.append('text')
      .append('tspan')
      .text(minorThirdText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 11)
        .attr('y', 368)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorThird, this, 4, 34);
        });

    let minorThirdRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 415 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.minorThirdRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorThirdRoman, this, 13.5, 36);
        });

    let minorThirdRomanText = INTERVALS.minorThirdRoman;
    svg.append('text')
      .append('tspan')
      .text(minorThirdRomanText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 20)
        .attr('y', 424)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorThirdRoman, this, 13.5, 36);
        });

    let majorThird = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 470 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.majorThird)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorThird, this, 4, 36);
        });

    let majorThirdText = INTERVALS.majorThird;
    svg.append('text')
      .append('tspan')
      .text(majorThirdText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 11)
        .attr('y', 480)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorThird, this, 4, 36);
        });

    let majorThirdRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 525 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.majorThirdRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorThirdRoman, this, 12, 36);
        });

    let majorThirdRomanText = INTERVALS.majorThirdRoman;
    svg.append('text')
      .append('tspan')
      .text(majorThirdRomanText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 19)
        .attr('y', 535)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorThirdRoman, this, 12, 36);
        });

    let perfectFourth = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 580 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.perfectFourth)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectFourth, this, 7.5, 36);
        });

    let perfectFourthText = INTERVALS.perfectFourth;
    svg.append('text')
      .append('tspan')
      .text(perfectFourthText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 13)
        .attr('y', 590)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectFourth, this, 7.5, 36);
        });

    let perfectFourthRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 635 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.perfectFourthRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectFourthRoman, this, 11.5, 36);
        });

    let perfectFourthRomanText = INTERVALS.perfectFourthRoman;
    svg.append('text')
      .append('tspan')
      .text(perfectFourthRomanText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 20)
        .attr('y', 645)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectFourthRoman, this, 11.5, 36);
        });

    let perfectFifth = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 690 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.perfectFifth)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectFifth, this, 7.5, 36);
        });

    let perfectFifthText = INTERVALS.perfectFifth;
    svg.append('text')
      .append('tspan')
      .text(perfectFifthText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 13)
        .attr('y', 700)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectFifth, this, 7.5, 36);
        });

    let perfectFifthRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 745 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.perfectFifthRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectFifthRoman, this, 15.5, 37);
        });

    let perfectFifthRomanText = INTERVALS.perfectFifthRoman;
    svg.append('text')
      .append('tspan')
      .text(perfectFifthRomanText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 23)
        .attr('y', 755)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectFifthRoman, this, 15.5, 37);
        });

    let minorSixth = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 800 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.minorSixth)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSixth, this, 3, 34);
        });

    let minorSixthText = INTERVALS.minorSixth;
    svg.append('text')
      .append('tspan')
      .text(minorSixthText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 8)
        .attr('y', 808)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSixth, this, 3, 34);
        });

    let minorSixthRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 855 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.minorSixthRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSixthRoman, this, 13.5, 35);
        });

    let minorSixthRomanText = INTERVALS.minorSixthRoman;
    svg.append('text')
      .append('tspan')
      .text(minorSixthRomanText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 20)
        .attr('y', 862)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSixthRoman, this, 13.5, 35);
        });

    let majorSixth = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 910 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.majorSixth)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSixth, this, 4, 35);
        });

    let majorSixthText = INTERVALS.majorSixth;
    svg.append('text')
      .append('tspan')
      .text(majorSixthText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 8)
        .attr('y', 921)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSixth, this, 4, 35);
        });

    let majorSixthRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 965 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.majorSixthRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSixthRoman, this, 11.5, 36);
        });

    let majorSixthRomanText = INTERVALS.majorSixthRoman;
    svg.append('text')
      .append('tspan')
      .text(majorSixthRomanText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 18)
        .attr('y', 975)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSixthRoman, this, 11.5, 36);
        });

    let minorSeventh = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 1020 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.minorSeventh)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSeventh, this, 3.5, 34);
        });

    let minorSeventhText = INTERVALS.minorSeventh;
    svg.append('text')
      .append('tspan')
      .text(minorSeventhText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 11)
        .attr('y', 1030)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSeventh, this, 3.5, 34);
        });

    let minorSeventhRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 1075 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.minorSeventhRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSeventhRoman, this, 9, 35);
        });

    let minorSeventhRomanText = INTERVALS.minorSeventhRoman;
    svg.append('text')
      .append('tspan')
      .text(minorSeventhRomanText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 14)
        .attr('y', 1085)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.minorSeventhRoman, this, 9, 35);
        });

    let majorSeventh = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 1130 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.majorSeventh)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSeventh, this, 4, 36);
        });

    let majorSeventhText = INTERVALS.majorSeventh;
    svg.append('text')
      .append('tspan')
      .text(majorSeventhText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 9)
        .attr('y', 1142)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSeventh, this, 4, 36);
        });

    let majorSeventhRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 1185)
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.majorSeventhRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSeventhRoman, this, 7, 36);
        });

    let majorSeventhRomanText = INTERVALS.majorSeventhRoman;
    svg.append('text')
      .append('tspan')
      .text(majorSeventhRomanText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 12)
        .attr('y', 1195)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.majorSeventhRoman, this, 7, 36);
        });

    let perfectOctave = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 1240 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.perfectOctave)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectOctave, this, 7, 36);
        });

    let perfectOctaveText = INTERVALS.perfectOctave;
    svg.append('text')
      .append('tspan')
      .text(perfectOctaveText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 13)
        .attr('y', 1250)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectOctave, this, 7, 36);
        });

    let perfectOctaveRoman = svg
      .append('circle')
        .attr('cx', 32)
        .attr('cy', 1295 )
        .attr('r', 25)
        .attr('class', 'dot-interval-' + INTERVALS.perfectOctaveRoman)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectOctaveRoman, this, 3, 36);
        });

    let perfectOctaveRomanText = INTERVALS.perfectOctaveRoman;
    svg.append('text')
      .append('tspan')
      .text(perfectOctaveRomanText)
        .attr('class', 'filled-dot-interval-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        .attr('x', 10)
        .attr('y', 1305)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleIntervalIconClick(INTERVALS.perfectOctaveRoman, this, 3, 36);
        });
  }

  private handleIntervalIconClick(interval: string, thisRef: any, textX: number, textY: number): void {
    let outerContainer = d3.select('#' + COMPONENT_IDS.outerContainer);

    // only continue if the eraser is not left on
    this.oService.unsetSelectedTool();
    this.oService.unlockLegend();
    this.createSelectedToolIcon(interval, textX, textY);
    let data = [{
      selectedColor: 'interval',
      previousColor: null,
      interval: interval
    }];

    return outerContainer.data(data);
    // if(!this.oService.isLegendLocked()) {
    //   this.createSelectedToolIcon(interval, textX, textY);

    //   let data = [{
    //     selectedColor: 'interval',
    //     previousColor: null,
    //     interval: interval
    //   }];

    //   // // highlight the background of the corresponding finger dot legend icon
    //   // // d3.select(thisRef).style('stroke', '#ff0000').style('fill', '#ff0000').style('fill-opacity', '0.2');
    //   // d3.select('.dot-interval-' + interval)
    //   //   .style('fill', '#ff0000')
    //   //   .style('fill-opacity', '0.8');

    //   this.oService.lockLegend();

    //   // bind the data to the outer container
    //   return outerContainer.data(data);
    // } else {
    //   this.oService.unsetSelectedTool();

    //   // clear the data
    //   let data = [{
    //     selectedColor: null,
    //     previousColor: null
    //   }];

    //   this.oService.unlockLegend();

    //   // hide the lock icon
    //   d3.select('img.eraser-btn').style('visibility', 'hidden');

    //   //get rid of the left column border color
    //   d3.select('div.eraser').style('border-left', 'solid 3px #eee');

    //   // bind the data to the outer container
    //   return outerContainer.data(data);
    // }
  }

  private createSelectedToolIcon(interval: string, textX: number, textY: number): void {
    // add the icon to the selected tool container
    let iconHomeContainer = d3.select('#' + this.intervalBtnContainerId);
    let svg = iconHomeContainer.select('svg').node();
    let newSvg = this.oService.createCircleIcon(svg, interval, textX, textY);
    // let newSvg = this.oService.createCircleIcon(svg, interval, 12.5, 38);

    this.oService.setSelectedTool(newSvg.node());
  }

  public handleClick(): void {

  }
}
