import { Component, OnInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { COMPONENT_IDS } from '@src/app/constants/constants';
import { OrchestratorService } from '@src/app/services/orchestrator.service';

@Component({
  selector: 'app-remove-fret-btn',
  templateUrl: './remove-fret-btn.component.html',
  styleUrls: ['./remove-fret-btn.component.scss']
})
export class RemoveFretBtnComponent implements OnInit {
  private config;
  private fretboard;
  private outerContainer;
  private fretboardColumnContainer;
  private numFrets: number;
  private height: number;

  constructor(private oService: OrchestratorService) {

   }

  ngOnInit(): void {

  }

  public handleClick(): void {
    let config = this.oService.getObject('config');
    let fretboard = this.oService.getObject('fretboard');
    let fretboardColumnContainer = d3.select('#' + COMPONENT_IDS.fretboardColumnContainer);
    let outerContainer = d3.select('#' + COMPONENT_IDS.outerContainer);

    this.numFrets = config.numFrets;
    this.height = config.legendHeight;

    // we don't care if the legend is locked

    let newNumFrets = this.numFrets - 1;

    // always keep five frets visible
    if (newNumFrets >= 5) {
      fretboard.removeFret();
      this.numFrets = newNumFrets;
      // this.calculateHeight();
      let middleHeight = (this.height + config.labelHeight + config.sublabelHeight + 85) + 'px';
      outerContainer.style('height', middleHeight);
      d3.select('#nakedFrets_2').style('height', middleHeight);
      fretboardColumnContainer.style('height', (this.height + config.labelHeight + config.sublabelHeight + 75) + 'px');
      // this.legendContainer.style('height', (this.height + 16) + 'px');
    }
  }

}
