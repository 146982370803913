import { Component } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = '';
  spotlightModeOn: boolean = false;

  constructor() {
    this.addGAScript();
  }

  public handleAboutPage(): void {
    d3.select('.why-container').style('display', 'block');

    d3.select('.terms-container').style('display', 'none');
    d3.select('.videos-container').style('display', 'none');
    d3.select('.app').style('display', 'none');
    d3.select('.release-history-container').style('display', 'none');
  }

  public handlePresentationMode(): void {
    if(this.spotlightModeOn) {
      d3.select('.column-2').style('display', 'flex');
      d3.select('.row-container').style('width', '850px'); // needs to match .row-container
    } else {
      d3.select('.column-2').style('display', 'none');
      d3.select('.row-container').style('width', 'auto');
    }

    this.spotlightModeOn = !this.spotlightModeOn;
  }

  public handleHamburgerMenu(): void {
    let hamburgerMenuContainer = document.getElementById('links');

    if(hamburgerMenuContainer.style.display === 'flex') {
      hamburgerMenuContainer.style.display = 'none';
    } else {
      hamburgerMenuContainer.style.display = 'flex';
    }
  }



  public handlePortraitMode(): void {
    let fretboardAndLabels = d3.select('.fretboard-container').select('svg');
    fretboardAndLabels.attr('transform','rotate(-90)');
  }

  public handleLandscapeMode(): void {
    let fretboardContainer = d3.select('.fretboard-container');

    // moves logo section down
    let rowContainer = d3.select('.row-container')
      .style('flex-direction', 'column');

    // moves legend down
    let outerContainer = d3.select('.outer-container')
      // .style('width', '800px')
      .style('flex-direction', 'column');

    // add some data to the outer container to set landscape/portrait mode
    let portraitModeData = [{
      portraitMode: true
    }];
    outerContainer.data(portraitModeData);

    // rotate the fretboard
    let fretboardAndLabels = fretboardContainer.select('svg');
    fretboardAndLabels.attr('transform','translate(160, -120) rotate(-90)');
    fretboardAndLabels.select('.chart-labels').attr('transform', 'rotate(90 25 25)')

    // resize the outer container
    // outerContainer.style('height', '350px');

    // resize the fretboard column container
    let fretboardColumnContainer = d3.select('.fretboard-column-container');
    // fretboardColumnContainer.style('height', '350px');

    // // then the label
    // let labelContainer = fretboardContainer.select('.chart-title');
    // labelContainer.attr('transform', 'rotate(90 72.5 190)');

    // // then the sublabel
    // let sublabelContainer = fretboardContainer.select('.chart-sublabel');
    // sublabelContainer.attr('transform', 'translate(-30, 20) rotate(90 67.5 210)');
  }



  public handleReleaseHistoryPage(): void {
    d3.select('.release-history-container').style('display', 'block');

    d3.select('.app').style('display', 'none');
    d3.select('.why-container').style('display', 'none');
    d3.select('.videos-container').style('display', 'none');
    d3.select('.terms-container').style('display', 'none');
  }



  public addGAScript(): void {
    // let gtagImportScript = d3.select('head').append('script');
    // gtagImportScript.attr('async', true);
    // gtagImportScript.attr('src', 'https://www.googletagmanager.com/gtag/js?id=G-YW2DBX0RPT');

    let gtagInlineScript = d3.select('head').append('script');
    let script = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-YW2DBX0RPT');`;
    gtagInlineScript.html(script);

    // let gtagScript: HTMLScriptElement = document.createElement('script');
    // gtagScript.async = true;
    // gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-YW2DBX0RPT';
    // document.head.prepend(gtagScript);
  }
}
