export const releases = [
  {
    date: '12/18/2023',
    changes: [
      {
        title: 'Sticky Root, Interval, and Finger Buttons',
        description: 'The Root, Interval, and Finger Buttons are now STICKY - they stay ON until another toolbar icon is clicked.'
      }
    ]
  },
  {
    date: '12/16/2023',
    changes: [
      {
        title: 'Label Size and Position Button',
        description: 'Added an icon (an "A" with an arrow to the right underneath) that opens a modal with controls to change the font size of the label and sublabel and also the left/right and up/down position of each (within what I think is a reasonable range).'
      },
      {
        title: 'Fret Numbers On/Off Buttons',
        description: 'Added buttons to turn fret numbers on or off.'
      },
      {
        title: 'Added PO Box for NakedFrets.com',
        description: 'Just what the title says.'
      },
      {
        title: 'Modified the About page',
        description: 'Tried to make it a little less dorky.'
      }
    ]
  },
  {
    date: '07/16/2022',
    changes: [
      {
        title: 'New Buttons',
        description: 'Added roman numeral intervals and a new Add Barre icon.'
      },
      {
        title: 'Selected Tool',
        description: 'Added a Selected Tool icon container so that you know which tool is currently selected.  When you click on an icon, it will appear in the selected tool container and disappear once used.'
      },
      {
        title: 'Locking Buttons',
        description: 'When any interval, root, or finger icon is selected, the menu is locked.  This means that in order to use another tool, you need to de-select the current tool (by clicking on it again) or use it.'
      }
    ]
  },
  {
    date: '06/04/2022',
    changes: [
      {
        title: 'New Button Layout',
        description: 'Added new buttons for placing the interval on dots.  Added sections for the types of buttons.  Moved non-label buttons to the left of the fretboard.'
      },
      {
        title: 'Various Tweaks',
        description: 'Various other little tweaks/changes.  Need to re-do the help page to reflect the new layout but that will be next.'
      }
    ]
  },
  {
    date: '05/29/2022',
    changes: [
      {
        title: 'Layout Improvements',
        description: 'Cleaned up the layout, re-did some pages, added new styles.'
      },
      {
        title: 'Menu Replaced',
        description: 'Created a more consolidated menu, removed some fluff.'
      },
      {
        title: 'Feedback Form',
        description: 'Added a way to submit feedback, feature requests, or report a bug from the app.'
      },
      {
        title: 'Help Page Re-organized',
        description: 'Going along with the layout changes, tried to make it better for mobile users.'
      },
      {
        title: 'About Page',
        description: 'What I had before was corny so I replaced it.'
      },
      {
        title: 'Mute/Open String Bug',
        description: 'Some users are reporting a solid circle instead of the X or O on downloads.  I changed fonts to something safer and will see if it resolves.  If not, I will use an image instead of text.'
      }

    ]
  },
  {
    date: '05/01/2022',
    changes: [
      {
        title: 'Always On Hamburger Menu',
        description: 'The hamburger menu is now always showing.  It allows me to reclaim some real-estate by hiding the menu under the logo.  Added home button so it always shows.'
      },
      {
        title: 'Desktop Presentation Mode',
        description: 'For screen sizes > 768px, you will have an option for Presentation Mode.  It has its own menu icon and when clicked, will center the fretboard on the page and hide everything else.'
      }
    ]
  },
  {
    date: '05/01/2022',
    changes: [
      {
        title: 'Display on Mobile',
        description: 'Tweaked styles for different screen sizes.  It looked awful before so hopefully it is a little better now.'
      },
      {
        title: 'Main Menu Change',
        description: 'Changed up the menu... made it more compact for better display on mobile.'
      }
    ]
  },
  {
    date: '4/30/2022',
    changes: [
      {
        title: 'Hamburger Menu Changes',
        description: 'replaced logo with different icon to use the space a little better.  Not great but better.'
      }
    ]
  },
  {
    date: '4/28/2022',
    changes: [
      {
        title: 'More/Better Help',
        description: 'each button now has a tooltip that explains the button function and how to use.'
      },
      {
        title: 'Updated Videos',
        description: 'All videos were re-done since the UI changed a little.  I also added a video for the finger number icons.'
      },
      {
        title: 'Misc Tweaks',
        description: 'Fixed a bunch of little things that were annoying.'
      },
      {
        title: 'Add fret bug',
        description: 'Fixed a bug where the fretboard would overlay some html.  Fixed by adding a hamburger menu at the top left on mobile.'
      }
    ]
  },
  {
    date: '4/23/2022',
    changes: [
      {
        title: 'Eraser Stickiness',
        description: 'the eraser now stays "on" until it is clicked a second time.  The red background will appear when engaged.  When the eraser is engaged, you cannot use the finger number or "add barre" features until the eraser is turned off.  You also cannot place dots when the eraser is on.'
      },
      {
        title: 'Eraser Reach',
        description: 'You can now remove the mute/open strings with the eraser.'
      }
    ]
  }
]
