<section class="">
  <div class="container">
    <div class="video-layout-container">
      <section>
        <div class="container">
          <h1>How to Use</h1>
          <p>Start by clicking anywhere on the fretboard to add a dot.  The default color will be black
            but you can choose a different color using the color icons at the top of the fretboard.</p>
          <p class="heavy">If you find a bug, you can report it via email or post to our facebook group.</p>
        </div>
      </section>

      <section>
        <div class="video-list-container container columns">

          <!-- <section> -->
          <div class="column">
            <h3>Help Topics</h3>
            <div class="container video-list-item-container columns">
              <div class="column">
                <ul class="first-list">
                  <li (click)="handleSwapVideo('download-image-video')">Downloading Images</li>
                  <li (click)="handleSwapVideo('label-position-size-video')">Changing Label/Sublabel Size and Position</li>
                  <li (click)="handleSwapVideo('clear-all-video')">Clearing the fretboard</li>
                  <li (click)="handleSwapVideo('remove-fret-video')">Removing frets</li>
                  <li (click)="handleSwapVideo('add-fret-video')">Adding frets</li>
                  <li (click)="handleSwapVideo('edit-labels-video')">Editing Labels</li>
                  <li (click)="handleSwapVideo('intervals-video')">Showing Intervals</li>
                </ul>
              </div>

              <div class="column second-list">
                <ul>
                  <li (click)="handleSwapVideo('eraser-video')">Erasing elements</li>
                  <li (click)="handleSwapVideo('add-barre-video')">Adding barres</li>
                  <li (click)="handleSwapVideo('change-dot-color-video')">Changing dot colors</li>
                  <li (click)="handleSwapVideo('mute-open-strings-video')">Showing muted/open strings</li>
                  <li (click)="handleSwapVideo('finger-numbers-video')">Assigning fingers to dots</li>
                  <li (click)="handleSwapVideo('root-video')">Showing the root note</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- </section> -->

          <!-- <section> -->
            <div class="column">
              <div class="container all-videos">

                <!-- videos start -->
                <div id="download-image-video" class="video-container">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Download Your Diagram</p>
                    </div>
                    <div class="message-body">
                      <div>
                        <img id="download-image-video-anchor" src="../../assets/images/helpIcons/download.png" height="400" width="400">
                        <!-- <img id="download-image-video-anchor" src="../assets/images/video-images/download.png" width="332" height="46">
                        <video width="400" height="400" controls >
                          <source src="../assets/videos/Download.mp4" type="video/mp4">
                          Your browser does not support the video tag.
                        </video> -->
                      </div>
                    </div>
                    <p class="is-size-5 video-text">The "Download" button downloads an image of your diagram in .png format, suitable for upload on most sites accepting photos.</p>
                    <p class="is-size-5 video-text">Behavior varies based on device/browser but you should at least be presented with an image of the fretboard only (no buttons, etc).  On iOS 15/iPhone 12, for example,
                      you can hold your finger down on the image until an options screen appears letting you choose to save as a photo or share it.
                    </p>
                  </article>
                </div>

                <div id="label-position-size-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Label Size and Position Button</p>
                    </div>
                    <div class="message-body">
                      <div>
                        <img id="label-position-size-anchor" src="../../assets/images/helpIcons/labelPositionSize.png" height="120" width="94">
                        <!-- <img id="download-image-video-anchor" src="../assets/images/video-images/download.png" width="332" height="46">
                        <video width="400" height="400" controls >
                          <source src="../assets/videos/Download.mp4" type="video/mp4">
                          Your browser does not support the video tag.
                        </video> -->
                      </div>
                    </div>
                    <p class="is-size-5 video-text">The "Label Size and Position" button opens a modal window with controls to increase or decrease the label and sublabel font size and slider controls to move the label and sublabel up/down/left/right.</p>
                  </article>
                </div>

                <div id="clear-all-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Clearing the Fretboard</p>
                    </div>
                    <div class="message-body">
                      <img id="clear-all-video-anchor" src="../../assets/images/helpIcons/clearAll.png" height="400" width="400">
                      <!-- <img id="clear-all-video-anchor" src="../assets/images/video-images/clear-all.png" width="234" height="50">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/ClearAll.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Clear All" button clears all dots and barres from the fretboard and removes any muted/open string designations.</p>
                  </article>
                </div>

                <div id="remove-fret-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Removing Frets</p>
                    </div>
                    <div class="message-body">
                      <img id="remove-fret-video-anchor" src="../../assets/images/helpIcons/removeFret.png" height="400" width="400">
                      <!-- <img id="remove-fret-video-anchor" src="../assets/images/video-images/remove-one-fret.png" width="296" height="54">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/RemoveOneFret.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Remove One Fret" button removes one fret from the bottom of the fretboard.</p>
                  </article>
                </div>

                <div id="add-fret-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Adding Frets</p>
                    </div>
                    <div class="message-body">
                      <img id="add-fret-video-anchor" src="../../assets/images/helpIcons/addFret.png" height="400" width="400">
                      <!-- <img id="add-fret-video-anchor" src="../assets/images/video-images/add-one-fret.png" width="236" height="58">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/AddOneFret.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Add One Fret" button adds another fret to the bottom of the fretboard.</p>
                  </article>
                </div>

                <div id="edit-labels-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Editing Labels</p>
                    </div>
                    <div class="message-body">
                      <img id="edit-labels-video-anchor" src="../../assets/images/helpIcons/editLabels.png" height="400" width="400">
                      <!-- <img id="edit-labels-video-anchor" src="../assets/images/video-images/edit-labels.png" width="242" height="50">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/EditLabels.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Edit Labels" allows you to edit the diagram's label and sublabel.</p>
                  </article>
                </div>

                <div id="eraser-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Erasing</p>
                    </div>
                    <div class="message-body">
                      <img id="eraser-video-anchor" src="../../assets/images/helpIcons/eraser.png" height="400" width="400">
                      <!-- <img id="eraser-video-anchor" src="../assets/images/video-images/eraser.png" width="196" height="46">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/Eraser.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Eraser" button allows you to clear one element at a time from the fretboard by clicking on it.
                      When you click on the eraser, it will turn red to indicate it is "on" and also display a Lock icon.  It will stay "on" until it is
                      clicked again.  While the eraser is turned on, you cannot use the finger number or "add a barre" features
                      or place more dots on the fretboard.</p>
                  </article>
                </div>

                <div id="change-dot-color-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Changing Dot Colors</p>
                    </div>
                    <div class="message-body">
                      <img id="change-dot-color-video-anchor" src="../../assets/images/helpIcons/dotColors.png" height="400" width="400">
                      <!-- <img id="change-dot-color-video-anchor" src="../assets/images/video-images/change-dot-color.png" width="202" height="44">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/ChangeDotColor.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Change Dot Color" buttons allow you to select a different dot color.  Useful for showing the root or related patterns, for example.</p>
                  </article>
                </div>

                <div id="add-barre-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Adding Barres</p>
                    </div>
                    <div class="message-body">
                      <img id="add-barre-video-anchor" src="../../assets/images/helpIcons/addBarre2.png" height="400" width="400">
                      <!-- <img id="add-barre-video-anchor" src="../assets/images/video-images/add-barre.png" width="252" height="60">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/AddBarre.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Add Barre" button allows you to create the "barre" for barre chords - it connects the first and last dot on
                      the same fret with a bar.  To use it, there must be at least two dots on the same fret.  Click on "Add a barre" and then
                    click on one of the two dots (it doesn't matter which).  The barre will then be drawn.</p>
                  </article>
                </div>

                <div id="mute-open-strings-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Showing Muted/Open Strings</p>
                    </div>
                    <div class="message-body">
                      <img id="mute-open-strings-video-anchor" src="../../assets/images/helpIcons/muteOpenStrings.png" height="400" width="400">
                      <!-- <img id="mute-open-strings-video-anchor" src="../assets/images/video-images/mute-open-strings.png" width="106" height="46">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/MuteOpenStrings.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Muted/Open String" buttons add the corresponding icon above the string/nut.  To use them, click on the Mute ("X") or Open ("O") icon and then click the area right above the string on which you wish to place it.</p>
                  </article>
                </div>

                <div id="intervals-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Showing Intervals</p>
                    </div>
                    <div class="message-body">
                      <img id="intervals-video-anchor" src="../../assets/images/helpIcons/intervals.png" height="400" width="100">
                      <!-- <img id="finger-numbers-video-anchor" src="../assets/images/video-images/finger-numbers.png" width="278" height="74">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/FingerNumber.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Interval" buttons let you place the interval over your dot of choice.  To use it, ensure there's at least a dot on the fretboard, then click one of the interval icons, then click the dot on which you want to place the interval.</p>
                  </article>
                </div>

                <div id="finger-numbers-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Showing Finger Numbers</p>
                    </div>
                    <div class="message-body">
                      <img id="finger-numbers-video-anchor" src="../../assets/images/helpIcons/fingerNumbers.png" height="400" width="100" style="width:100px;">
                      <!-- <img id="finger-numbers-video-anchor" src="../assets/images/video-images/finger-numbers.png" width="278" height="74">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/FingerNumber.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Finger Number" buttons let you place a finger number over your dot of choice.  To use it, ensure there's at least a dot on the fretboard, then click the icon, then click the dot on which you want to place the number.</p>
                  </article>
                </div>

                <div id="root-video" class="video-container" style="display: none;">
                  <article class="message is-info">
                    <div class="message-header is-size-5">
                      <p>Showing the Root</p>
                    </div>
                    <div class="message-body">
                      <img id="root-video-anchor" src="../../assets/images/helpIcons/root.png" height="400" width="200">
                      <!-- <img id="finger-numbers-video-anchor" src="../assets/images/video-images/finger-numbers.png" width="278" height="74">
                      <video width="400" height="400" controls >
                        <source src="../assets/videos/FingerNumber.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                      </video> -->
                    </div>
                    <p class="is-size-5 video-text">The "Root" icon lets you place the symbol over your dot of choice.  To use it, ensure there's at least a dot on the fretboard, click the root icon, then click the dot on which you want to place it.</p>
                  </article>
                </div>

              </div>
            </div>
          <!-- </section> -->

        </div>
      </section>
    </div>
</div>
