import { Component, AfterViewInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { v4 as uuidv4 } from 'uuid/dist';
import { INakedFret, NAKED_FRETS } from '@src/app/models/nf-interfaces';

import { Toolbar } from '@src/app/models/toolbar';
import { Fretboard } from '@src/app/models/fretboard';
import { FretboardLabel } from '@src/app/models/fretboard-label';
import { FretboardSublabel } from '@src/app/models/fretboard-sublabel';
import { Legend } from '@src/app/models/legend';
import { FretboardStringMuteBar } from '../models/fretboard-string-mute-bar';
import { FretboardLabels } from '../models/fretboard-labels';
import { COMPONENT_IDS } from '../constants/constants';
import { OrchestratorService } from '../services/orchestrator.service';
import { ModalService } from '../services/modal.service';

interface FretChoice {
  value: number;
  viewValue: number;
}
@Component({
  selector: 'app-naked-fret',
  templateUrl: './naked-fret.component.html',
  styleUrls: ['./naked-fret.component.scss']
})
export class NakedFretComponent implements AfterViewInit {
  public pageTitle: string = 'Major Template Home';
  public fretNumChoices: FretChoice[] = [
    { value: 3, viewValue: 3 },
    { value: 4, viewValue: 4 },
    { value: 5, viewValue: 5 },
    { value: 6, viewValue: 6 },
    { value: 7, viewValue: 7 },
    { value: 8, viewValue: 8 },
    { value: 9, viewValue: 9 },
    { value: 10, viewValue: 10 },
    { value: 11, viewValue: 11 },
    { value: 12, viewValue: 12 },
    { value: 13, viewValue: 13 },
    { value: 14, viewValue: 14 },
    { value: 15, viewValue: 15 },
    { value: 16, viewValue: 16 },
    { value: 17, viewValue: 17 },
    { value: 18, viewValue: 18 },
    { value: 19, viewValue: 19 },
    { value: 20, viewValue: 20 },
    { value: 21, viewValue: 21 },
    { value: 22, viewValue: 22 },
    { value: 23, viewValue: 23 },
    { value: 24, viewValue: 24 }
  ];
  public selectedNumFrets: number = 5;
  public selectedChartLabel: string = '';
  public selectedChartSublabel: string = '';
  private xStep = 5;
  private yStep = 5;

  private configCnt = 0;
  private assemblyContainerCreated: boolean = false;
  private currentComponentWidth: number = 0;
  private currentComponentHeight: number = 0;
  private MUTEBAR_HEIGHT: number = 20;

  // NEW BELOW
  // containers
  private outerContainer: any;
  private nakedFretsContainer: any;
  private fretboardColumnContainer: any;
  private toolbarContainer: any;
  private mutebarContainer: any;
  private fretboardContainer: any;
  private labelContainer: any;
  private sublabelContainer: any;
  private legendColumnContainer: any;
  private legendContainer: any;
  private fieldFormContainer: any;

  // IDs
  private outerContainerId: string;
  private fretboardColumnContainerId: string;
  private toolbarContainerId: string;
  private mutebarContainerId: string;
  private fretboardContainerId: string;
  private labelContainerId: string;
  private sublabelContainerId: string;
  private legendColumnContainerId: string;
  private legendContainerId: string;
  private fieldFormContainerId: string;

  private uuid: string;
  private config: INakedFret;


  constructor(private oService: OrchestratorService, private modalService: ModalService) {

  }

  private fadeOutInstructions(): void {
    let t = d3.transition()
      .duration(5000)
      .ease(d3.easeLinear);

    setTimeout(() => {
      d3.selectAll('.instruct').transition(t)
        .style('display', 'none');
    }, 5000);


    // d3.select('.instruct').transition(t)
    //   .style('display', 'none');
  }

  public turnOffFretNumbers(): void {
    d3.selectAll('.fret-num')
      .style('display', 'none');
  }

  public turnOnFretNumbers(): void {
    d3.selectAll('.fret-num')
      .style('display', 'block');
  }

  ngAfterViewInit(): void {
    this.modalService.attachHandlers();

    this.handleCreate();
    // this.fadeOutInstructions();
  }

  private configureContainers(): void {
    this.config = NAKED_FRETS[0];
    this.uuid = uuidv4();
    this.config.hostId = this.uuid;

    // assign IDs
    // this.outerContainerId = 'outer-container-' + this.uuid;
    // this.fretboardColumnContainerId = 'fretboard-column-container-' + this.uuid;
    // this.toolbarContainerId = 'toolbar-container-' + this.uuid;
    // this.mutebarContainerId = 'mutebar-container-' + this.uuid;
    // this.fretboardContainerId = 'fretboard-container-' + this.uuid;
    // this.labelContainerId = 'fretboard-label-container-' + this.uuid;
    // this.sublabelContainerId = 'fretboard-sublabel-container-' + this.uuid;
    // this.legendColumnContainerId = 'legend-column-container-' + this.uuid;
    // this.legendContainerId = 'legend-container-' + this.uuid;
    // this.fieldFormContainerId = 'field-form-container-' + this.uuid;
    this.outerContainerId = COMPONENT_IDS.outerContainer;
    this.fretboardColumnContainerId = COMPONENT_IDS.fretboardColumnContainer;
    this.toolbarContainerId = COMPONENT_IDS.toolbarContainer;
    this.mutebarContainerId = COMPONENT_IDS.mutebarContainer;
    this.fretboardContainerId = COMPONENT_IDS.fretboardContainer;
    this.labelContainerId = COMPONENT_IDS.labelContainer;
    this.sublabelContainerId = COMPONENT_IDS.sublabelContainer;
    this.legendColumnContainerId = COMPONENT_IDS.legendColumnContainer;
    this.legendContainerId = COMPONENT_IDS.legendContainer;
    this.fieldFormContainerId = COMPONENT_IDS.fieldFormContainer;

    this.config.ids.outerContainerId = this.outerContainerId;
    this.config.ids.fretboardColumnContainerId = this.fretboardColumnContainerId;
    this.config.ids.toolbarContainerId = this.toolbarContainerId;
    this.config.ids.mutebarContainerId = this.mutebarContainerId;
    this.config.ids.fretboardContainerId = this.fretboardContainerId;
    this.config.ids.labelContainerId = this.labelContainerId;
    this.config.ids.sublabelContainerId = this.sublabelContainerId;
    this.config.ids.legendColumnContainerId = this.legendColumnContainerId;
    this.config.ids.legendContainerId = this.legendContainerId;
    this.config.ids.fieldFormContainerId = this.fieldFormContainerId;

    // create the containers
    this.outerContainer = this.nakedFretsContainer.append('div').attr('id', this.outerContainerId);
    let outerContainerWidth = (this.config.pxBetweenStrings * this.config.numberStrings) + this.config.legendWidth + 30;
    let outerContainerHeight = this.config.margins.top + this.config.toolbarHeight + (this.config.pxBetweenFrets * (this.config.numFrets)) + this.config.labelHeight + this.config.sublabelHeight + this.config.margins.bottom + 85;
    this.outerContainer
      .attr('class', 'outer-container')
      .style('display', 'flex')
      .style('flex-direction', 'row')
      .style('justify-content', 'center')
      // .style('border', 'solid 1px black')
      .style('height', outerContainerHeight + 'px')
      .style('background-color', '#fff');

    d3.select('#nakedFrets_2').style('height', outerContainerHeight + 'px');

    let fretboardColumnContainerWidth = this.config.margins.left + (this.config.pxBetweenStrings * (this.config.numberStrings - 1)) + this.config.fretboardNumberWidth;
    let fretboardColumnContainerHeight = this.config.margins.top + this.MUTEBAR_HEIGHT + this.config.toolbarHeight + (this.config.pxBetweenFrets * (this.config.numFrets)) + this.config.labelHeight + this.config.sublabelHeight + this.MUTEBAR_HEIGHT;
    this.fretboardColumnContainer = this.outerContainer.append('div').attr('id', this.fretboardColumnContainerId);
    this.fretboardColumnContainer
      .attr('class', 'fretboard-column-container')
      .style('display', 'flex')
      .style('flex-direction', 'column')
      .style('align-items', 'flex-start')
      // .style('border', 'solid 1px red')
      .style('width', fretboardColumnContainerWidth)
      .style('height', fretboardColumnContainerHeight)
      .style('padding', '3px')
      .style('background-color', '#fff');

    let toolbarContainerWidth = fretboardColumnContainerWidth;
    let toolbarContainerHeight = this.config.toolbarHeight;

    this.toolbarContainer = this.fretboardColumnContainer
      .append('div')
      .attr('id', this.toolbarContainerId);

    this.toolbarContainer
      .attr('class', 'toolbar-container')
      // .style('border', 'solid 1px green')
      .style('width', toolbarContainerWidth)
      .style('height', toolbarContainerHeight);

    let mutebarContainerWidth = fretboardColumnContainerWidth;
    let mutebarContainerHeight = this.config.muteBarHeight;

    this.mutebarContainer = this.fretboardColumnContainer
      .append('div')
      .attr('id', this.mutebarContainerId)
      .style('transform', 'translate(0px,20px');

    this.mutebarContainer
      .attr('class', 'mutebar-container')
      .style('width', mutebarContainerWidth)
      .style('height', mutebarContainerHeight + 'px');

    let fretboardContainerWidth = fretboardColumnContainerWidth;
    let fretboardContainerHeight = fretboardColumnContainerHeight;

    this.fretboardContainer = this.fretboardColumnContainer
      .append('div')
      .attr('id', this.fretboardContainerId);

    this.fretboardContainer
      .attr('class', 'fretboard-container')
      .style('height', fretboardContainerHeight)
      .style('width', fretboardContainerWidth + 10);

    let legendColumnWidth = this.config.legendWidth;
    let legendColumnHeight = this.config.margins.top + this.config.toolbarHeight + ((this.config.numFrets) * this.config.pxBetweenFrets) + 5;
    this.legendColumnContainer = this.outerContainer.append('div').attr('id', this.legendColumnContainerId);
    this.legendColumnContainer
      .attr('class', 'legend-column-container')
      .style('display', 'flex')
      .style('flex-direction', 'column')
      // .style('border', 'solid 1px red')
      .style('width', legendColumnWidth)
      .style('height', legendColumnHeight);

    let legendWidth = legendColumnWidth;
    let legendHeight = legendColumnHeight;
    this.legendContainer = this.legendColumnContainer.append('div').attr('id', this.legendContainerId);
    this.legendContainer
      .attr('class', 'legend-container')
      // .style('border', 'solid 1px green')
      .style('width', legendWidth)
      .style('height', legendHeight);

    let fieldFormContainerWidth = legendWidth;
    let fieldFormContainerHeight = this.config.labelFormHeight;
    this.fieldFormContainer = this.legendColumnContainer.append('div').attr('id', this.fieldFormContainerId);
    this.fieldFormContainer
      .attr('class', 'field-form-container')
      // .style('border', 'solid 1px red')
      .style('text-align', 'center');

    // set the naked frets container height accordingly
    let nakedFretsHeight = this.config.margins.top + this.config.toolbarHeight + (this.config.pxBetweenFrets * (this.config.numFrets)) + this.config.labelHeight + this.config.sublabelHeight + this.config.margins.bottom + 85;
    d3.select('#nakedFrets_2')
      .style('height', nakedFretsHeight + 'px');
  }

  public handleEditLabels(): void {
    let el = document.getElementById("edit-labels-modal");
    this.modalService.openModal(el);
  }

  public handleCreate(): void {
    let fc = d3.select('#' + COMPONENT_IDS.fretboardContainer);

    if(fc.empty()) {
      // grab the main, outer container
      this.nakedFretsContainer = d3.select('#nakedFrets_2');

      this.configureContainers();

      // create our objects
      let toolbar = new Toolbar(this.config);
      let mutebar = new FretboardStringMuteBar(this.config);
      let fretboard = new Fretboard(this.config, this.oService);
      let labels = new FretboardLabels(this.config);
      // let legend = new Legend(this.config, fretboard);

      //new: let the orchestrator keep hold of them so other components can
      // pull in the instance of the service and have access to the objects.
      this.oService.setObject({
        toolbar: toolbar,
        mutebar: mutebar,
        fretboard: fretboard,
        labels: labels,
        config: this.config
      });
    }
  }
}
