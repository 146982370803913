<nav class="navbar has-shadow" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">

    <a class="navbar-item" [routerLink]="['']">
      <div class="site-name">
        <span class="first-word">NakedFrets</span><span class="second-word">.com</span>
      </div>
    </a>

    <a
      role="button"
      class="navbar-burger ml-0 mt-2"
      (click)="handleHamburger('#app-navbar')"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="app-navbar" class="navbar-menu">
    <div class="navbar-start">
      <a href="" class="navbar-item" (click)="handleHomePage()">
        <img src="../assets/images/home_black_24dp.svg" width="24" height="24"> Home
      </a>

      <a class="navbar-item" [routerLink]="['/about']">
        <img src="../assets/images/user-ninja-solid.svg" width="24" height="24"> About
      </a>

      <!-- <a class="navbar-item" [routerLink]="['/songs']">
        <img src="../assets/images/music_note_black_24dp.svg" width="24" height="24"> Songs
      </a> -->

      <a class="navbar-item" [routerLink]="['/feedback']">
        <img src="../assets/images/paper-plane-solid.svg" width="24" height="24"> Feedback
      </a>

      <a class="navbar-item" [routerLink]="['/help']">
        <img src="../assets/images/circle-question-solid.svg" width="24" height="24"> Help
      </a>

    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="">
          <a href="https://www.facebook.com/groups/nakedfrets/"  target="_blank" style="margin-right: 20px;">
            <img src="../../assets/images/f_logo_RGB-Blue_58.png" width="40" height="40" title="Find us on Facebook!" style="max-height: none;">
          </a>

          <a href="https://www.buymeacoffee.com/MiArDe" target="_blank">
            <img src="../../assets/images/bmc-logo.svg" width="26" height="26" style="max-height: none;" title="Consider donating...">
          </a>
        </div>
      </div>
    </div>

    <div>
      <app-footer></app-footer>
    </div>

  </div>



</nav>

