import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements OnInit {
  @Input()
  set pageTitle(pageTitle: string) {
    this.title = pageTitle;
  }
  get pageTitle(): string {
    return this.title;
  }
  public title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
