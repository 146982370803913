import { IFretboardLabelsConfig, IMargins, INakedFret } from "./nf-interfaces";
import * as d3 from '@node_modules/d3/dist/d3';
import { text } from "d3";

export class FretboardLabels {
  private config: INakedFret;

  // container references
  private outerContainer: any;
  private fretboardColumnContainer: any;
  private legendColumnContainer: any;
  private toolbarContainer: any;
  private fretboardContainer: any;
  private labelContainer: any;
  private sublabelContainer: any;
  private legendContainer: any;
  private fieldFormContainer: any;

  private svg: any; // the toolbar svg
  private width: number;
  private height: number;
  private margins: IMargins;

  private hostId: string; // the main uuid we're appending to all IDs
  private svgId: string; //  this toolbar's <svg>
  private defs: any; // this toolbar's defs
  private labelFontSizeInPx: number;
  private sublabelFontSizeInPx: number;
  private fontFamily: string;
  private fretboardNumberWidth: number;
  private MUTEBAR_HEIGHT: number = 20;

  private label: string;
  private labelNode: any = null;
  private sublabel: string;
  private sublabelNode: any = null;



  constructor(config: INakedFret) {
    this.config = config;
    this.parseConfig();
    this.buildChart();
  }

  private buildChart(): void {
    // this.setupSvg();
    // this.setupDefs();
    this.drawChart();
  }

  private parseConfig(): void {
    this.outerContainer = d3.select('#' + this.config.ids.outerContainerId);
    this.fretboardColumnContainer = d3.select('#' + this.config.ids.fretboardColumnContainerId);
    this.legendColumnContainer = d3.select('#' + this.config.ids.legendColumnContainerId);
    this.toolbarContainer = d3.select('#' + this.config.ids.toolbarContainerId);
    this.fretboardContainer = d3.select('#' + this.config.ids.fretboardContainerId);
    this.labelContainer = d3.select('#' + this.config.ids.labelContainerId);
    this.sublabelContainer = d3.select('#' + this.config.ids.sublabelContainerId);
    this.legendContainer = d3.select('#' + this.config.ids.legendContainerId);
    this.fieldFormContainer = d3.select('#' + this.config.ids.fieldFormContainerId);

    this.hostId = this.config.hostId;
    this.svgId = 'label-svg-' + this.hostId;
    this.margins = this.config.margins;
    this.labelFontSizeInPx = this.config.labelFontSize;
    this.sublabelFontSizeInPx = this.config.sublabelFontSize;
    this.fontFamily = this.config.labelFont;
    this.label = this.config.labelDefault;
    this.labelNode = this.config.labelNode;
    this.sublabel = this.config.sublabelDefault;
    this.sublabelNode = this.config.sublabelNode;
    this.fretboardNumberWidth = this.config.fretboardNumberWidth;

    this.calculateWidth();
    this.calculateHeight();
  }

  private calculateWidth(): void {
    // this.width = this.config.labelWidth;
    // this.width = parseInt(d3.select('#fretboard-svg-' + this.hostId).attr('width'));
    this.width = this.margins.left + ((this.config.pxBetweenStrings * this.config.numberStrings));
  }

  private calculateHeight(): void {
    this.height = this.config.labelHeight;
  }

  private setupSvg(): void {
    // this.svg = this.labelContainer
    this.svg = this.fretboardContainer
      .append('svg')
        .attr('viewbox', '0 0 ' + this.width + ' ' + this.height + 30) // 30 is the current font size i'm using
        .attr('width', this.width) // adding 20 for the fret numbers
        .attr('height', this.height + 30)
        .attr('id', this.svgId)
        .style('background-color', '#fff');
  }

  private setupDefs(): void {
    this.defs = this.svg
      .append('defs');
  }

  private updateLabel(newLabel: string): void {
    this.label = newLabel;

    //remove the old one
    d3.select('#chart-title' + this.hostId).remove();

    //draw a new one
    this.drawChart();
  }

  private drawChart(): void {
    // math is hard
    let widthMinusNumbers = this.width; // margin left + fretboard width (numStrings-1 * pxBetween strings
    // let result = (widthMinusNumbers/2);
    let textWidthGuess = this.label.length * 25;
    let subtextWidthGuess = this.sublabel.length * 10;
    let result = this.margins.left + (parseInt(this.fretboardContainer.select('svg').attr('width'))/2) - textWidthGuess;
    let fretboardHeight = parseInt(this.fretboardContainer.select('svg').attr('height')) - 37; //was 40. 25 is half the text height.

    let g = this.fretboardContainer
      .select('svg')
      .append('g')
        .attr('id', 'chart-labels-' + this.hostId)
        .attr('class', 'chart-labels')
        .attr('height', this.height + 30);

      if(this.labelNode === undefined) {
        let defaultLabel = g
          .append('text')
            // .attr('x', result - (textWidthGuess/2))
            .attr('x', 22)
            .attr('y', fretboardHeight - 40)
            .style('font-size', this.labelFontSizeInPx + 'px')
            .style('font-family', this.fontFamily)
            .style('text-anchor', 'start')
            .append('tspan')
              .attr('id', 'labelTSpan')
              .text(this.label);
      } else {
        // pull the existing x/y from the node we stuffed in config.labelNode
        let newX = parseInt(d3.select(this.labelNode).attr('x'));
        let newY = parseInt(d3.select(this.labelNode).attr('y'));
        // console.log('newY:', newY, ' fretboardHeight:', fretboardHeight);
        let newStyle = d3.select(this.labelNode).attr('style');

        let existingLabel = g
          .append('text')
            // .attr('x', result - (textWidthGuess/2))
            .attr('x', newX)
            .attr('y', fretboardHeight - 40)
            .attr('style', newStyle)
            // .style('font-size', this.labelFontSizeInPx + 'px')
            // .style('font-family', this.fontFamily)
            // .style('text-anchor', 'start')
            .append('tspan')
              .attr('id', 'labelTSpan')
              .text(this.label);
      }

      if(this.sublabelNode === undefined) {
        let sublabel = g
          .append('text')
            // .attr('x', result - (subtextWidthGuess/2))
            .attr('x', 25)
            .attr('y', (fretboardHeight - 40) + (this.labelFontSizeInPx/2))
            .style('font-size', this.sublabelFontSizeInPx + 'px')
            .style('font-family', this.fontFamily)
            .style('text-anchor', 'start')
            .append('tspan')
              .attr('id', 'sublabelTSpan')
              .text(this.sublabel);
      } else {
        // pull the x/y from the node we stuffed in config.sublabelNode
        let newX = parseInt(d3.select(this.sublabelNode).attr('x'));
        let newY = parseInt(d3.select(this.sublabelNode).attr('y'));
        let newStyle = d3.select(this.sublabelNode).attr('style');
        let existingSublabel = g
          .append('text')
            // .attr('x', result - (subtextWidthGuess/2))
            .attr('x', newX)
            .attr('y', (fretboardHeight - 40) + (this.labelFontSizeInPx/2))
            .attr('style', newStyle)
            // .style('font-size', this.sublabelFontSizeInPx + 'px')
            // .style('font-family', this.fontFamily)
            // .style('text-anchor', 'start')
            .append('tspan')
              .attr('id', 'sublabelTSpan')
              .text(this.sublabel);
      }

  }

}
