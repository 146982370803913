import { Component, OnInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { COMPONENT_IDS } from '@src/app/constants/constants';
import { ReleaseHistoryComponent } from '@src/app/release-history/release-history.component';
import { OrchestratorService } from '@src/app/services/orchestrator.service';

@Component({
  selector: 'app-eraser-btn',
  templateUrl: './eraser-btn.component.html',
  styleUrls: ['./eraser-btn.component.scss']
})
export class EraserBtnComponent implements OnInit {

  constructor(private oService: OrchestratorService) { }

  ngOnInit(): void {
  }

  public handleClick(): void {
    let outerContainer = d3.select('#' + COMPONENT_IDS.outerContainer);

    if(this.oService.isLegendLocked() === true) {
      // the eraser is turned on and they want to disable it

      this.oService.unsetSelectedTool();

      // clear the data
      let data = [{
        selectedColor: null,
        previousColor: null
      }];

      // d3.select(this).style('fill-opacity', '0');

      this.oService.unlockLegend();

      // hide the lock icon
      d3.select('img.eraser-btn').style('visibility', 'hidden');

      //get rid of the left column border color
      // d3.select('.left-btn-row').style('border-right', 'solid 2px #555');
      d3.select('div.eraser').style('border-left', 'solid 3px #eee');

      // bind the data to the outer container
      return outerContainer.data(data);

    } else {
      // turn on the eraser

      // add the icon to the selected tool container
      let img = d3.select('.eraser-tool').node();
      this.oService.setSelectedTool(img);

      let data = [{
        selectedColor: 'eraser',
        previousColor: null
      }];

      // highlight the background of the legend eraser item
      // d3.select('#' + COMPONENT_IDS.eraserBtn).style('stroke', '#ff0000').style('fill', '#ff0000').style('fill-opacity', '0.2');

      // turn on the lock icon
      // d3.select('img.eraser-btn').style('visibility', 'visible');

      //color the left bar
      // d3.select('div.eraser').style('border-left', 'solid 3px red');

      this.oService.lockLegend();

      // bind the data to the outer container
      return outerContainer.data(data);
    }
  }

}
