import { Component, OnInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { COMPONENT_IDS } from '@src/app/constants/constants';

@Component({
  selector: 'app-clear-all-btn',
  templateUrl: './clear-all-btn.component.html',
  styleUrls: ['./clear-all-btn.component.scss']
})
export class ClearAllBtnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public handleClick(): void {

    // we don't care if the legend is locked to clear all

    // let dataset = d;
    // let svg = d3.select(dataset.fretboardSelector);
    let svg = d3.select('#' + COMPONENT_IDS.fretboardContainer);
    let muteBarSvg = d3.select('#' + COMPONENT_IDS.mutebarContainer).select('svg');

    svg.selectAll('.filled-dot').remove();
    svg.selectAll('.barre').remove();
    svg.selectAll('.filled-dot-finger-icon-text').remove();
    svg.selectAll("circle[class*='dot-finger-']").remove();
    svg.selectAll("circle[class*='dot-interval-']").remove();
    svg.selectAll('.filled-dot-interval-icon-text').remove();
    svg.selectAll('.filled-dot-root-icon-text').remove();
    svg.selectAll('.filled-dot-notename-icon-text').remove();
    muteBarSvg.selectAll('.mutebar-string').style('display', 'none');

  }

}
