import { NumberValue, RandomNumberGenerationSource } from "d3";
import { Fretboard } from "./fretboard";
import { FretboardLabels } from "./fretboard-labels";
import { FretboardStringMuteBar } from "./fretboard-string-mute-bar";
import { Toolbar } from "./toolbar";

export interface IFretboardConfig {
  hostId: string;
  chartTitle: string;
  chartSubtitle: string;
  chartTitleFont: string;
  chartTitleMarginTop: number; // set to font size?
  numFrets: number;
  numStrings: number;
  pxBetweenFrets: number;
  pxBetweenStrings: number;
  nutThickness: number;
  fretDotRadius: number;
  fretDotCx: number;
  fretDotCy: number;
  filledDotRadius: number;
  filledDotCx: number;
  filledDotCy: number;
  titleWidth: number;
  titleHeight: number;
  gutters: IGutter
}

export interface IOrchestratorObj {
  toolbar: Toolbar;
  mutebar: FretboardStringMuteBar;
  fretboard: Fretboard;
  labels: FretboardLabels;
  config: INakedFret;
}

export interface IGutter {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface IToolbarConfig {
  hostId: string;
  numFrets: number;
  numStrings: number;
  pxBetweenFrets: number;
  pxBetweenStrings: number;
  gutters: IGutter;
  toolbarWidth: number;
  toolbarHeight: number;
}

export interface IFretboardLabelsConfig {
  hostId: string;
  width: number;
  height: number;
  margins: IMargins;
  label: string;
  sublabel: string;
}

export interface ILegendConfig {
  hostId: string;
  width: number;
  height: number;
  margins: IMargins;
  numFrets: number;
}

export interface IMargins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface INakedFret {
  hostId: string;
  numFrets: number;
  filledDotCx: number;
  filledDotCy: number;
  fretboardId: string;
  fretboardNumberWidth: number;
  fretboardX: number;
  fretboardY: number;
  fretDotCx: number;
  fretDotCy: number;
  fretDotRadius: number;
  fretDotStartingXPositions: number[];
  fretDotStartingYPositions: number[];
  pxBetweenFrets: number;
  pxBetweenStrings: number;
  numberStrings: number;
  toolbarHeight: number;
  toolbarX: number;
  toolbarY: number;
  legendWidth: number;
  legendHeight: number;
  legendX: number;
  legendY: number;
  labelWidth: number;
  labelHeight: number;
  labelX: number;
  labelY: number;
  labelFont: string;
  labelFontSize: number;
  muteBarHeight: number;
  nutThickness: number;
  sublabelDefault: string;
  sublabelNode: any;
  sublabelWidth: number;
  sublabelHeight: number;
  sublabelX: number;
  sublabelY: number;
  sublabelFont: string;
  sublabelFontSize: number;
  labelDefault: string;
  labelNode: any;
  labelFormWidth: number;
  labelFormHeight: number;
  labelFormX: number;
  labelFormY: number;
  margins: IMargins;
  ids: IFretboardIds;
  filledDotRadius: number;
  dotColorSelectionRectWidth: number;
  dotColorSelectionRectHeight: number;
  dotColorChoices: string[];
  fretsWithNumbers: number[];
  fretsWithDots: number[];
}

export interface IFretboardIds {
  outerContainerId: string;
  fretboardColumnContainerId: string;
  legendColumnContainerId: string;
  toolbarContainerId: string;
  fretboardContainerId: string;
  labelContainerId: string;
  sublabelContainerId: string;
  legendContainerId: string;
  fieldFormContainerId: string;
  mutebarContainerId: string;
}

// export { select, selectAll, Node } from 'd3-selection';

export const NAKED_FRETS = [
  {
    ids: {
      outerContainerId: null,
      fretboardColumnContainerId: null,
      legendColumnContainerId: null,
      toolbarContainerId: null,
      fretboardContainerId: null,
      labelContainerId: null,
      sublabelContainerId: null,
      legendContainerId: null,
      fieldFormContainerId: null,
      mutebarContainerId: null
    },
    dotColorChoices: ['#000000', '#ff0000', '#4c9a2a', '#0000ff'],
    dotColorSelectionRectWidth: 30,
    dotColorSelectionRectHeight: 30,
    hostId: null,
    numFrets: 7,
    filledDotCx: 10,
    filledDotCy: 18,
    filledDotRadius: 15,
    fretboardId: null,
    fretboardNumberWidth: 35,
    fretboardX: 0,
    fretboardY: 0,
    fretDotRadius: 6,
    fretsWithDots: [3, 5, 7, 9, 12, 15, 17, 19, 21, 24],
    fretsWithNumbers: [3, 5, 7, 9, 12, 15, 17, 19, 21, 24],
    fretDotCx: 50,
    fretDotCy: 72.5,
    fretDotStartingXPositions: [101, 101, 101, 101, 51, 101, 101, 101, 101, 51],
    fretDotStartingYPositions: [85, 185, 285, 385, 535, 685, 785, 885, 985, 1135],
    pxBetweenFrets: 50,
    pxBetweenStrings: 50,
    muteBarHeight: 25,
    numberStrings: 6,
    toolbarHeight: 34,
    toolbarX: 0,
    toolbarY: 0,
    legendWidth: 5,
    legendHeight: 250,
    legendX: 0,
    legendY: 0,
    labelWidth: 50,
    labelHeight: 50,
    labelDefault: 'Label',
    labelNode: undefined,
    labelX: 0,
    labelY: 0,
    labelFont: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    labelFontSize: 70,
    nutThickness: 10,
    sublabelDefault: 'Sublabel',
    sublabelNode: undefined,
    sublabelWidth: 100,
    sublabelHeight: 45,
    sublabelX: 0,
    sublabelY: 0,
    sublabelFont: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    sublabelFontSize: 30,
    labelFormWidth: 170,
    labelFormHeight: 120,
    labelFormX: 0,
    labelFormY: 0,
    margins: {
      top: 0,
      right: 12,
      bottom: 0,
      left: 25
    }
  }
]

export const SAMPLE_CONFIGS = [
  {
    hostId: '#nakedFrets_2',
    chartTitle: 'Am',
    chartSubtitle: 'Pentatonic',
    chartTitleFont: 'bold 50px',
    chartTitleMarginTop: 50, // set to font size?
    numFrets: 5,
    numStrings: 6,
    pxBetweenFrets: 25,
    pxBetweenStrings: 25,
    nutThickness: 5,
    fretDotRadius: 2,
    fretDotCx: 72.5,
    fretDotCy: 72.5,
    filledDotRadius: 6,
    filledDotCx: 10,
    filledDotCy: 23,
    titleWidth: 50,
    titleHeight: 50,
    gutters: {
      top: 0,
      right: 10,
      bottom: 0,
      left: 10
    }
  }
  // ,
  // {
  //   hostId: '#nakedFrets_2',
  //   chartTitle: 'Am',
  //   chartSubtitle: 'Pentatonic 1',
  //   chartTitleFont: 'bold 50px',
  //   chartTitleMarginTop: 50, // set to font size?
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   nutThickness: 5,
  //   fretDotRadius: 2,
  //   fretDotCx: 72.5,
  //   fretDotCy: 72.5,
  //   filledDotRadius: 6,
  //   filledDotCx: 10,
  //   filledDotCy: 23,
  //   titleWidth: 50,
  //   titleHeight: 50,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   }
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   chartTitle: 'Am',
  //   chartSubtitle: 'Pentatonic 2',
  //   chartTitleFont: 'bold 50px',
  //   chartTitleMarginTop: 50, // set to font size?
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   nutThickness: 5,
  //   fretDotRadius: 2,
  //   fretDotCx: 72.5,
  //   fretDotCy: 72.5,
  //   filledDotRadius: 6,
  //   filledDotCx: 10,
  //   filledDotCy: 23,
  //   titleWidth: 50,
  //   titleHeight: 50,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   }
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   chartTitle: 'Am',
  //   chartSubtitle: 'Pentatonic 3',
  //   chartTitleFont: 'bold 50px',
  //   chartTitleMarginTop: 50, // set to font size?
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   nutThickness: 5,
  //   fretDotRadius: 2,
  //   fretDotCx: 72.5,
  //   fretDotCy: 72.5,
  //   filledDotRadius: 6,
  //   filledDotCx: 10,
  //   filledDotCy: 23,
  //   titleWidth: 50,
  //   titleHeight: 50,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   }
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   chartTitle: 'Am',
  //   chartSubtitle: 'Pentatonic 4',
  //   chartTitleFont: 'bold 50px',
  //   chartTitleMarginTop: 50, // set to font size?
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   nutThickness: 5,
  //   fretDotRadius: 2,
  //   fretDotCx: 72.5,
  //   fretDotCy: 72.5,
  //   filledDotRadius: 6,
  //   filledDotCx: 10,
  //   filledDotCy: 23,
  //   titleWidth: 50,
  //   titleHeight: 50,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   }
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   chartTitle: 'Am',
  //   chartSubtitle: 'Pentatonic 5',
  //   chartTitleFont: 'bold 50px',
  //   chartTitleMarginTop: 50, // set to font size?
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   nutThickness: 5,
  //   fretDotRadius: 2,
  //   fretDotCx: 72.5,
  //   fretDotCy: 72.5,
  //   filledDotRadius: 6,
  //   filledDotCx: 10,
  //   filledDotCy: 23,
  //   titleWidth: 50,
  //   titleHeight: 50,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   }
  // }
];

export const SAMPLE_TOOLBAR_CONFIGS = [
  {
    hostId: '#nakedFrets_2',
    numFrets: 16,
    numStrings: 6,
    pxBetweenFrets: 25,
    pxBetweenStrings: 25,
    gutters: {
      top: 0,
      right: 10,
      bottom: 0,
      left: 10
    },
    toolbarWidth: 111,
    toolbarHeight: 30
  }
  // ,
  // {
  //   hostId: '#nakedFrets_2',
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   toolbarWidth: 111,
  //   toolbarHeight: 30
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   toolbarWidth: 111,
  //   toolbarHeight: 30
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   toolbarWidth: 111,
  //   toolbarHeight: 30
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   toolbarWidth: 111,
  //   toolbarHeight: 30
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   numFrets: 16,
  //   numStrings: 6,
  //   pxBetweenFrets: 25,
  //   pxBetweenStrings: 25,
  //   gutters: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   toolbarWidth: 111,
  //   toolbarHeight: 30
  // }
]

export const SAMPLE_LABEL_CONFIGS = [
  {
    hostId: '#nakedFrets_2',
    margins: {
      top: 0,
      right: 10,
      bottom: 0,
      left: 10
    },
    width: 0,
    height: 0,
    label: 'Am',
    sublabel: 'Pentatonic 1'
  }
  // ,
  // {
  //   hostId: '#nakedFrets_2',
  //   margins: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   width: 0,
  //   height: 0,
  //   label: 'Am',
  //   sublabel: 'Pentatonic 2'
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   margins: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   width: 0,
  //   height: 0,
  //   label: 'Am',
  //   sublabel: 'Pentatonic 3'
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   margins: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   width: 0,
  //   height: 0,
  //   label: 'Am',
  //   sublabel: 'Pentatonic 4'
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   margins: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   width: 0,
  //   height: 0,
  //   label: 'Am',
  //   sublabel: 'Pentatonic 5'
  // },
  // {
  //   hostId: '#nakedFrets_2',
  //   margins: {
  //     top: 0,
  //     right: 10,
  //     bottom: 0,
  //     left: 10
  //   },
  //   width: 0,
  //   height: 0,
  //   label: 'Am',
  //   sublabel: 'Pentatonic Lick'
  // }
]

export const SAMPLE_LEGEND_CONFIGS = [
  {
    hostId: '#nakedFrets_2',
    margins: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 10
    },
    width: 200,
    height: 400,
    numFrets: 5
  }
]
