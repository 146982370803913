import { IMargins, INakedFret } from "./nf-interfaces";
import * as d3 from '@node_modules/d3/dist/d3';
import * as tooltips from '../constants/tooltips';
import { COMPONENT_IDS } from "../constants/constants";

export class FretboardStringMuteBar {
  private config: INakedFret;

  // container references
  private outerContainer: any;
  private fretboardColumnContainer: any;
  private legendColumnContainer: any;
  private toolbarContainer: any;
  private mutebarContainer: any;
  private fretboardContainer: any;
  private labelContainer: any;
  private sublabelContainer: any;
  private legendContainer: any;
  private fieldFormContainer: any;
  private muteBarContainer: any;
  private mutebarG: any;

  private svg: any; // the toolbar svg
  private width: number;
  private height: number;
  private margins: IMargins;

  private hostId: string; // the main uuid we're appending to all IDs
  private svgId: string; //  this toolbar's <svg>
  private defs: any; // this toolbar's defs
  private fontSizeInPx: number;
  private fontFamily: string;
  private fretboardNumberWidth: number;
  private dotColorChoices: string[];

  constructor(config: INakedFret) {
    this.config = config;
    this.parseConfig();
    this.buildChart();
  }

  private buildChart(): void {
    this.setupSvg();
    this.setupDefs();
    this.drawChart();
  }

  private parseConfig(): void {
    this.outerContainer = d3.select('#' + COMPONENT_IDS.outerContainer);
    this.fretboardColumnContainer = d3.select('#' + COMPONENT_IDS.fretboardColumnContainer);
    this.legendColumnContainer = d3.select('#' + COMPONENT_IDS.legendColumnContainer);
    this.toolbarContainer = d3.select('#' + COMPONENT_IDS.toolbarContainer);
    this.mutebarContainer = d3.select('#' + COMPONENT_IDS.mutebarContainer);
    this.fretboardContainer = d3.select('#' + COMPONENT_IDS.fretboardContainer);
    this.labelContainer = d3.select('#' + COMPONENT_IDS.labelContainer);
    this.sublabelContainer = d3.select('#' + COMPONENT_IDS.sublabelContainer);
    this.legendContainer = d3.select('#' + COMPONENT_IDS.legendContainer);
    this.fieldFormContainer = d3.select('#' + COMPONENT_IDS.fieldFormContainer);

    this.dotColorChoices = this.config.dotColorChoices;

    this.hostId = this.config.hostId;
    this.svgId = COMPONENT_IDS.mutebarSvg;
    this.margins = this.config.margins;

    this.fretboardNumberWidth = this.config.fretboardNumberWidth;

    this.calculateWidth();
    this.calculateHeight();
  }

  private calculateWidth(): void {
    this.width = ((this.config.numberStrings - 1) * this.config.pxBetweenStrings);
  }

  private calculateHeight(): void {
    this.height = this.config.muteBarHeight;
  }

  private setupSvg(): void {
    this.svg = this.mutebarContainer
      .append('svg')
        .attr('viewbox', '0 0 ' + this.width + ' ' + this.height+13)
        .attr('width', this.width + this.margins.left + this.margins.right)
        .attr('height', this.height+3)
        .attr('id', this.svgId)
        .style('background-color', '#fff');

    // this is how to add an inline stylesheet
    this.svg
      .append('style')
        .attr('type', 'text/css')
        .text(`
            .mutebar-string {
              font-family: arial, sans-serif;
              font-size: 18px;
            }
        `);
  }

  private setupDefs(): void {
    this.defs = this.svg
      .append('defs');

    this.defs
      .append('g')
        .attr('id', 'clickable-mutebar-area-g-' + this.hostId)
      .append('rect')
        .attr('width', 24)
        .attr('height', this.height + 3)
        .attr('class', 'clickable-mutebar-area')
        // .style('stroke', '#fff')
        .style('stroke-width', '1px')
        .style('fill', '#ff0000')
        .style('fill-opacity', '0');


  }

  private handleMuteOrOpenClick(thisRef: any): void {
    let colorData = this.outerContainer.data();
    let previousColor = colorData[0].previousColor;
    let color = colorData[0] !== undefined ? colorData[0].selectedColor : this.dotColorChoices[0];
    let icon = d3.select(thisRef);

    if(color === 'eraser') {
      icon.remove();
    }
  }

  private drawChart(): void {
    let self = this;

    this.mutebarContainer
      .attr('class', 'mutebar-container')
      // .style('border', 'solid 1px green')
      .style('width', this.width)
      .style('height', this.height);

    this.mutebarG = this.svg.append('g').attr('class', COMPONENT_IDS.mutebarG);

    let mutedString6 = this.mutebarG.append('text')
      .text('X')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 18)
        .attr('y', 20)
        .attr('id', 'string-6-muted-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none')
        .on('click', function(evt) {
          self.handleMuteOrOpenClick(this);
        });

    let openString6 = this.mutebarG.append('text')
      .text('O')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 17)
        .attr('y', 20)
        .attr('id', 'string-6-open-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    this.mutebarG
      .append('use')
        .attr('href', '#clickable-mutebar-area-g-' + this.hostId)
        .attr('x', 14)
        .attr('y', 0)
        .style('cursor', 'pointer')
        .on('click', function(evt, d) {
          // grab the "selectedColor" from the outerContainer's bound data
          let mutedOrOpenData = self.outerContainer.data();

          if(mutedOrOpenData[0] !== undefined) {
            let mutedOrOpen = mutedOrOpenData[0].selectedColor;

            if(mutedOrOpen === 'muted') {
              mutedString6.style('display', 'block');
              openString6.style('display', 'none');
            } else if(mutedOrOpen === 'open') {
              openString6.style('display', 'block');
              mutedString6.style('display', 'none');
            } else if(mutedOrOpen === 'eraser') {
              mutedString6.style('display', 'none');
              openString6.style('display', 'none');
            }
          }

        })
        .append('title').attr('class', 'tooltip').text(tooltips.MUTE_STRINGS_TOOLTIP.tooltip);

    let mutedString5 = this.mutebarG.append('text')
      .text('X')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 67)
        .attr('y', 20)
        .attr('id', 'string-5-muted-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    let openString5 = this.mutebarG.append('text')
      .text('O')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 66)
        .attr('y', 20)
        .attr('id', 'string-5-open-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    this.mutebarG
      .append('use')
        .attr('href', '#clickable-mutebar-area-g-' + this.hostId)
        .attr('x', 63)
        .attr('y', 0)
        .style('cursor', 'pointer')
        .on('click', function(evt, d) {
          // grab the "selectedColor" from the outerContainer's bound data
          let mutedOrOpenData = self.outerContainer.data();

          if(mutedOrOpenData[0] !== undefined) {
            let mutedOrOpen = mutedOrOpenData[0].selectedColor;

            if(mutedOrOpen === 'muted') {
              mutedString5.style('display', 'block');
              openString5.style('display', 'none');
            } else if(mutedOrOpen === 'open') {
              openString5.style('display', 'block');
              mutedString5.style('display', 'none');
            } else if(mutedOrOpen === 'eraser') {
              mutedString5.style('display', 'none');
              openString5.style('display', 'none');
            }
          }
        })
        .append('title').attr('class', 'tooltip').text(tooltips.MUTE_STRINGS_TOOLTIP.tooltip);

    let mutedString4 = this.mutebarG.append('text')
      .text('X')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 117)
        .attr('y', 20)
        .attr('id', 'string-4-muted-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    let openString4 = this.mutebarG.append('text')
      .text('O')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 116)
        .attr('y', 20)
        .attr('id', 'string-4-open-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    this.mutebarG
      .append('use')
        .attr('href', '#clickable-mutebar-area-g-' + this.hostId)
        .attr('x', 113)
        .attr('y', 0)
        .style('cursor', 'pointer')
        .on('click', function(evt, d) {
          // grab the "selectedColor" from the outerContainer's bound data
          let mutedOrOpenData = self.outerContainer.data();

          if(mutedOrOpenData[0] !== undefined) {
            let mutedOrOpen = mutedOrOpenData[0].selectedColor;

            if(mutedOrOpen === 'muted') {
              mutedString4.style('display', 'block');
              openString4.style('display', 'none');
            } else if(mutedOrOpen === 'open') {
              openString4.style('display', 'block');
              mutedString4.style('display', 'none');
            } else if(mutedOrOpen === 'eraser') {
              mutedString4.style('display', 'none');
              openString4.style('display', 'none');
            }
          }
        })
        .append('title').attr('class', 'tooltip').text(tooltips.MUTE_STRINGS_TOOLTIP.tooltip);

    let mutedString3 = this.mutebarG.append('text')
      .text('X')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 167)
        .attr('y', 20)
        .attr('id', 'string-3-muted-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    let openString3 = this.mutebarG.append('text')
      .text('O')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 166)
        .attr('y', 20)
        .attr('id', 'string-3-open-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    this.mutebarG
      .append('use')
        .attr('href', '#clickable-mutebar-area-g-' + this.hostId)
        .attr('x', 163)
        .attr('y', 0)
        .style('cursor', 'pointer')
        .on('click', function(evt, d) {
          // grab the "selectedColor" from the outerContainer's bound data
          let mutedOrOpenData = self.outerContainer.data();

          if(mutedOrOpenData[0] !== undefined) {
            let mutedOrOpen = mutedOrOpenData[0].selectedColor;

            if(mutedOrOpen === 'muted') {
              mutedString3.style('display', 'block');
              openString3.style('display', 'none');
            } else if(mutedOrOpen === 'open') {
              openString3.style('display', 'block');
              mutedString3.style('display', 'none');
            } else if(mutedOrOpen === 'eraser') {
              mutedString3.style('display', 'none');
              openString3.style('display', 'none');
            }
          }
        })
        .append('title').attr('class', 'tooltip').text(tooltips.MUTE_STRINGS_TOOLTIP.tooltip);

    let mutedString2 = this.mutebarG.append('text')
      .text('X')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 217)
        .attr('y', 20)
        .attr('id', 'string-2-muted-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    let openString2 = this.mutebarG.append('text')
      .text('O')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 216)
        .attr('y', 20)
        .attr('id', 'string-2-open-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    this.mutebarG
      .append('use')
        .attr('href', '#clickable-mutebar-area-g-' + this.hostId)
        .attr('x', 213)
        .attr('y', 0)
        .style('cursor', 'pointer')
        .on('click', function(evt, d) {
          // grab the "selectedColor" from the outerContainer's bound data
          let mutedOrOpenData = self.outerContainer.data();

          if(mutedOrOpenData[0] !== undefined) {
            let mutedOrOpen = mutedOrOpenData[0].selectedColor;

            if(mutedOrOpen === 'muted') {
              mutedString2.style('display', 'block');
              openString2.style('display', 'none');
            } else if(mutedOrOpen === 'open') {
              openString2.style('display', 'block');
              mutedString2.style('display', 'none');
            } else if(mutedOrOpen === 'eraser') {
              mutedString2.style('display', 'none');
              openString2.style('display', 'none');
            }
          }
        })
        .append('title').attr('class', 'tooltip').text(tooltips.MUTE_STRINGS_TOOLTIP.tooltip);


    let mutedString1 = this.mutebarG.append('text')
      .text('X')
        .style('stroke', '#000')
        .style('fill', '#000')
        .attr('x', 267)
        .attr('y', 20)
        .attr('id', 'string-1-muted-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    let openString1 = this.mutebarG.append('text')
      .text('O')
        .style('stroke', '#000')
        .style('fill', '#000')
        // .style('fill-opacity', '0')
        .attr('x', 266)
        .attr('y', 20)
        .attr('id', 'string-1-open-' + this.hostId)
        .attr('class', 'mutebar-string')
        .style('font-size', '24px')
        .style('display', 'none');

    this.mutebarG
      .append('use')
        .attr('href', '#clickable-mutebar-area-g-' + this.hostId)
        .attr('x', 263)
        .attr('y', 0)
        .style('cursor', 'pointer')
        .on('click', function(evt, d) {
          // grab the "selectedColor" from the outerContainer's bound data
          let mutedOrOpenData = self.outerContainer.data();

          if(mutedOrOpenData[0] !== undefined) {
            let mutedOrOpen = mutedOrOpenData[0].selectedColor;

            if(mutedOrOpen === 'muted') {
              mutedString1.style('display', 'block');
              openString1.style('display', 'none');
            } else if(mutedOrOpen === 'open') {
              openString1.style('display', 'block');
              mutedString1.style('display', 'none');
            } else if(mutedOrOpen === 'eraser') {
              mutedString1.style('display', 'none');
              openString1.style('display', 'none');
            }
          }
        })
        .append('title').attr('class', 'tooltip').text(tooltips.MUTE_STRINGS_TOOLTIP.tooltip);
  }

}
