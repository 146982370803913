import * as d3 from '@node_modules/d3/dist/d3';
import { v4 as uuidv4 } from 'uuid/dist';
import { IMargins, INakedFret } from '@models/nf-interfaces';
import { select } from 'd3';
import { FretboardSublabel } from './fretboard-sublabel';
import { FretboardLabel } from './fretboard-label';
import { FretboardLabels } from './fretboard-labels';
import { COMPONENT_IDS, INTERVALS, INTERVAL_TEXT_POSITION, NOTENAME_TEXT_POSITION } from '../constants/constants';
import { OrchestratorService } from '../services/orchestrator.service';

export class Fretboard {
  private config: INakedFret;

  // container references
  private outerContainer: any;
  private fretboardColumnContainer: any;
  private legendColumnContainer: any;
  private toolbarContainer: any;
  private fretboardContainer: any;
  private labelContainer: any;
  private sublabelContainer: any;
  private legendContainer: any;
  private fieldFormContainer: any;

  private svg: any; // the toolbar svg
  private width: number;
  private height: number;
  private margins: IMargins;
  private hostId: string; // the main uuid we're appending to all IDs
  private svgId: string; //  this toolbar's <svg>
  private defs: any; // this toolbar's defs
  private fretboardNumberWidth: number;
  private numberStrings: number;
  private numberFrets: number;
  private pxBetweenFrets: number;
  private pxBetweenStrings: number;
  private nutThickness: number;
  private fretDotRadius: number;
  private fretsWithNumbers: number[];
  private fretsWithDots: number[];
  private fretDotStartingXPositions: number[];
  private fretDotStartingYPositions: number[];
  private dotColorChoices: string[];
  private fretDotCx: number;
  private fretDotCy: number;
  private filledDotCx: number;
  private filledDotCy: number;
  private fretboardSvg: any;

  private filledDotRadius: number;
  private addedDotIds: string[] = [];
  private sublabelOffset: number = 5;
  private MUTEBAR_HEIGHT: number = 20;

  // private oService: OrchestratorService = null;

  constructor(config: INakedFret, private oService: OrchestratorService) {
    this.config = config;
    this.parseConfig();
    this.buildChart();
  }

  private parseConfig(): void {
    this.outerContainer = d3.select('#' + this.config.ids.outerContainerId);
    this.fretboardColumnContainer = d3.select('#' + this.config.ids.fretboardColumnContainerId);
    this.legendColumnContainer = d3.select('#' + this.config.ids.legendColumnContainerId);
    this.toolbarContainer = d3.select('#' + this.config.ids.toolbarContainerId);
    this.fretboardContainer = d3.select('#' + this.config.ids.fretboardContainerId);
    this.labelContainer = d3.select('#' + this.config.ids.labelContainerId);
    this.sublabelContainer = d3.select('#' + this.config.ids.sublabelContainerId);
    this.legendContainer = d3.select('#' + this.config.ids.legendContainerId);
    this.fieldFormContainer = d3.select('#' + this.config.ids.fieldFormContainerId);

    this.fretboardNumberWidth = this.config.fretboardNumberWidth;
    this.hostId = this.config.hostId;
    this.svgId = COMPONENT_IDS.fretboardSvg;
    this.margins = this.config.margins;
    this.numberStrings = this.config.numberStrings;
    this.numberFrets = this.config.numFrets;
    this.pxBetweenFrets = this.config.pxBetweenFrets;
    this.pxBetweenStrings = this.config.pxBetweenStrings;
    this.nutThickness = this.config.nutThickness;
    this.fretDotRadius = this.config.fretDotRadius;
    this.fretsWithNumbers = this.config.fretsWithNumbers;
    this.fretsWithDots = this.config.fretsWithDots;
    this.fretDotStartingXPositions = this.config.fretDotStartingXPositions;
    this.fretDotStartingYPositions = this.config.fretDotStartingYPositions;
    this.dotColorChoices = this.config.dotColorChoices;
    this.fretDotCx = this.config.fretDotCx;
    this.fretDotCy = this.config.fretDotCy;
    this.filledDotCx = this.config.filledDotCx;
    this.filledDotCy = this.config.filledDotCy;
    this.filledDotRadius = this.config.filledDotRadius;

    this.calculateWidth();
    this.calculateHeight();
  }

  private buildChart(): void {
    this.setupSvg();
    this.setupDefs();
    this.drawNut();
    this.drawStrings();
    this.drawFrets();
    this.drawFretDots();
    this.drawFretNumbers();
    this.drawClickableAreas();
  }

  private calculateWidth(): void {
    this.width = ((this.numberStrings - 1) * this.pxBetweenStrings) + this.margins.left + this.margins.right + this.fretboardNumberWidth;
  }

  private calculateHeight(): void {
    this.height = this.MUTEBAR_HEIGHT + ((this.numberFrets) * this.pxBetweenFrets) + this.config.labelHeight + this.config.sublabelHeight + this.sublabelOffset + 50;
    d3.select('#' + COMPONENT_IDS.fretboardContainer).data([{fretboardHeight: this.height, fretboardWidth: this.width}]);
  }

  public getHeight(): number {
    return this.height;
  }

  public getWidth(): number {
    return this.width;
  }

  private setupSvg(): void {
    this.svg = this.fretboardContainer
      .append('svg')
        .attr('viewbox', '0 0 ' + this.width + ' ' + this.height)
        .attr('width', this.width)
        .attr('height', this.height)
        .attr('id', this.svgId)
        .style('margin-top', '3px')
        .style('margin-bottom', '0px')
        .style('background-color', '#fff')
        .append('g')
          .attr('class', 'fretboard-g');

    // this is how to add an inline stylesheet
    // let fretboardSvg = d3.select('.fretboard-container').select('svg');
    this.svg
      .append('style')
        .attr('type', 'text/css')
        .text(`
            .filled-dot-finger-icon-text, .filled-dot-finger-icon-text {
              font-size: 18px;
              font-family: arial, sans-serif;
              transform: translate('0px','17px');
            }
            .barre {
              border: solid 12px green;
            }
        `);
  }

  private setupDefs(): void {
    // set up our defs
    this.defs = this.svg.append('defs');

    // to include our fretDot
    this.defs
      .append('g')
        .attr('id', 'fretDot')
        .style('stroke', '#000')
        .style('fill', '#000')
        .append('circle')
          .attr('cx', this.fretDotCx)
          .attr('cy', this.fretDotCy)
          .attr('r', this.fretDotRadius);

    // next our filledDot
    this.defs
      .append('g')
        .attr('id', 'filledDot')
        .append('circle')
          .attr('cx', this.filledDotCx)
          .attr('cy', this.filledDotCy)
          .attr('r', this.filledDotRadius);

    // next our fret numbers
    for(let x = 0; x < this.fretsWithNumbers.length; x++) {
      const currentFretNumber = this.fretsWithNumbers[x];
      if(currentFretNumber <= this.numberFrets) {
        const classToUse = String(currentFretNumber).length === 1 ? '_one-digit' : '_two-digits';
        this.defs
          .append('g')
            .attr('id', 'fret-number_' + currentFretNumber)
            .attr('class', 'fret-number' + classToUse)
            .style('fill', '#000')
            .append('text')
              .attr('x', 5)
              .attr('y', this.MUTEBAR_HEIGHT)
              .style('font-size', '24px')
              .text(currentFretNumber);
      }
    }

    // next our clickable areas
    this.defs
      .append('g')
        .attr('id', 'clickableStringArea')
        .append('rect')
          .attr('width', 48)
          .attr('height', 48)
          .style('stroke', '#000')
          .style('stroke-opacity', '0')
          .style('fill', '#ff0000')
          .style('fill-opacity', '0');

  }

  private drawNut(): void {
    this.svg
      .append('path')
        .attr('d', 'M ' + this.margins.left + ',' + this.margins.top + this.MUTEBAR_HEIGHT + ' ' + 'L ' + (this.margins.left + ((this.numberStrings - 1) * this.pxBetweenStrings)) + ',' + this.margins.top + this.MUTEBAR_HEIGHT)
        .style('stroke', '#000')
        .style('stroke-width', this.nutThickness)
        .style('fill', '#000');
  }

  private drawStrings(): void {
    let xpos = this.margins.left;
    for(let x = 0; x < this.numberStrings; x++) {
      let pos = xpos === this.margins.left ? this.margins.left : xpos;
      this.svg
        .append('path')
          .attr('d', 'M ' + pos + ',' + this.margins.top + this.MUTEBAR_HEIGHT + ' ' + 'L ' + pos + ',' + (this.margins.top + this.MUTEBAR_HEIGHT + (this.numberFrets * this.pxBetweenFrets)))
          .style('stroke', '#000');

        xpos += this.pxBetweenStrings;
    }
  }

  private drawFrets(): void {
    let ypos = this.MUTEBAR_HEIGHT;
    for(let x = 0; x <= this.numberFrets; x++) {
      let pos = ypos === this.margins.top ? this.margins.top : ypos;
      this.svg
        .append('path')
          .attr('d', 'M ' + this.margins.left + ',' + ypos + ' ' + 'L ' + (this.margins.left + ((this.numberStrings - 1) * this.pxBetweenStrings)) + ',' + ypos)
          .style('stroke', '#000');

      ypos += this.pxBetweenFrets;
    }
  }

  private drawFretDots(): void {
    let modifiedFretsWithDots = [];

    // based on the number of frets, determine which frets need dots
    for(let x = 0; x < this.numberFrets; x++) {
      if(this.fretsWithDots.indexOf(x + 1) !== -1) {
        modifiedFretsWithDots.push(x + 1);
      }
    }

    for(let y = 0; y < modifiedFretsWithDots.length; y++) {
        let currentFretNumber = modifiedFretsWithDots[y];

        this.svg
          .append('use')
            .attr('href', '#fretDot')
            .attr('x', this.fretDotStartingXPositions[y])
            .attr('y', this.fretDotStartingYPositions[y] - 10);

        if(currentFretNumber === 12 || currentFretNumber === 24) {
          // add the second dot
          this.svg
            .append('use')
              .attr('href', '#fretDot')
              .attr('x', this.fretDotStartingXPositions[y] + (2 * this.pxBetweenStrings))
              .attr('y', this.fretDotStartingYPositions[y] - 10);
        }
    }
  }

  private drawFretNumbers(): void {
    let prevNumber = 0;
    for(let x = 0; x < this.fretsWithNumbers.length; x++) {
      const currentFret = this.fretsWithNumbers[x];
      if(currentFret <= this.numberFrets) {
        const numFretsSpanned = currentFret - prevNumber;
        const newYPos = (numFretsSpanned * this.pxBetweenFrets) - 15; // 6.5 is a magic number but it works
        this.svg
          .append('use')
            .attr('href', '#fret-number_' + currentFret)
            .attr('x', ((this.numberStrings - 1) * this.pxBetweenStrings) + this.margins.left + this.margins.right)
            .attr('y', newYPos)
            .attr('class', 'fret-num')
            .style('display', 'block');
      }
    }
  }

  private drawClickableAreas(): void {
    let self = this;
    /**
     * Draw a clickable rectangle for each string/fret
     * intersection.
     */
     const xpositions = [];
     for(let a = 5; a > 0; a--) {
       xpositions.push((a * this.pxBetweenStrings));
     }
     xpositions.push(0);

     const ypositions = [];
     for(let b = 0; b < this.numberFrets + 1; b++) {
       ypositions.push(b * (this.pxBetweenFrets));
     }

    // for each fret
    for(let x = 1; x < this.numberFrets + 1; x++) {
      // create a clickable area for each string
      for(let y = 0; y < this.numberStrings; y++) {
        let data = [{
          string: y + 1,
          fret: x,
          svgid: this.svgId
        }];
        this.svg
          .append('use')
            .attr('href', '#clickableStringArea')
            .data(data)
            .attr('x', (this.margins.left + xpositions[y]) - this.margins.left) // the 2 is to pull the clickable area to the left more
            .attr('y', ypositions[x - 1] + 21)
            .style('cursor', 'pointer')
            .on('click', (evt, d) => {
              this.handleStringClick(d);
            });

      }
    }
  }

  private handleStringClick(d: any): void {
    let self = this;

    const svgSelector = '#' + d.svgid;
    const fret = d.fret;
    const stringNumber = d.string;

    /**
     * Toolbar writes the selected color to the outer container (bound to d).
     * We're pulling it out here to fill the selected dot with it.
     */
    let colorData = this.outerContainer.data();
    let color = colorData[0] !== undefined ? colorData[0].selectedColor : this.dotColorChoices[0];
    let selectedDotColor = (color !== null && color !== undefined) ? color : this.dotColorChoices[0];
    let innerSelf = this;

    if(selectedDotColor !== 'eraser') {
      this.svg = d3.select(svgSelector);

      let fretStarts = {};
      for(let x = 1; x < this.numberFrets + 2; x++) {
        if(x === 1) {
          fretStarts[x] = 28;
        } else {
          fretStarts[x] = fretStarts[x - 1] + this.pxBetweenFrets; // pxNetweenFrets
        }
      }

      let stringStarts = {};
      for(let y = 6; y > 0; y--) {
        if(y === 6) {
          stringStarts[y] = 15;
        } else {
          stringStarts[y] = stringStarts[y + 1] + this.pxBetweenStrings;
        }
      }

      // create an id for the dot
      let dotId = 'filled-dot-' + uuidv4();

      /**
       * When a color dot is selected from the toolbar, it writes the
       * selected color to a data attribute on the host container where
       * it is read before placing any dots on the fretboard.
       */
      let dotData = [{
        selectedDotColor: selectedDotColor,
        x: stringStarts[stringNumber],
        y: fretStarts[fret],
        fret: fret,
        string: stringNumber,
        id: dotId
      }];
      this.svg.select('.fretboard-g')
        .append('use')
          .attr('href', '#filledDot')
          .attr('class', 'filled-dot')
          .attr('id', dotId)
          .data(dotData)
          .attr('x', stringStarts[stringNumber])
          .attr('y', fretStarts[fret])
          .style('stroke', '#000')
          .style('fill', selectedDotColor)
          .style('cursor', 'pointer')
          .on('click', function(event, d) {
            innerSelf.handleFilledDotClick(d, fret, stringNumber, this);
          });

      // store the dot id so we can undo it
      this.addedDotIds.push(dotId);
    }
  }

  private handleFilledDotClick(d: any, fret: number, stringNum: number, thisRef: any): void {
    let self = this;
    let colorData = this.outerContainer.data();
    //console.log('d:', d);

    // {selectedColor, finger, hostId, previousColor}

    let fretboardSvg = d3.select('.fretboard-container').select('svg');
    this.fretboardSvg = fretboardSvg;

    let previousColor = colorData[0].previousColor;
    let color = colorData[0] !== undefined ? colorData[0].selectedColor : this.dotColorChoices[0];
    let eraserIconId = 'eraser-icon-' + colorData[0].hostId;
    let eraserIcon = this.legendContainer.select('svg').select('#' + eraserIconId);
    let barreIconId = 'barre-icon-' + colorData[0].hostId;
    let barreIcon = this.legendContainer.select('svg').select('#' + barreIconId);
    let finger = colorData[0].finger;
    let fingerIconId = 'dot-finger-' + finger;
    let fingerIcon = this.legendContainer.select('svg').select('.' + fingerIconId);
    let interval = colorData[0].interval;
    let intervalIconId = 'dot-interval-' + interval;
    let intervalIcon = this.legendContainer.select('svg').select('.' + intervalIconId);
    let rootIcon = this.legendContainer.select('svg').select('.dot-root');

    let noteName = colorData[0].noteName;
    let noteNameIconId = 'dot-notename-' + noteName;
    let noteNameIcon = this.legendContainer.select('svg').select('.' + noteNameIconId);

    let dotData = d[0];

    //this.clearAnyText(fretboardSvg, fret, stringNum);
    //this.clearButtonContents(fret, stringNum);

    if(color === 'eraser') {
      // grab all dots on the same fret as the clicked dot
      let dotsOnSameFret: any[] = [];
      let barresOnSameFret: any[] = [];
      this.fretboardContainer
        .selectAll('.filled-dot')
        .each((dot) => {
          if(dot.fret === fret) {
            dotsOnSameFret.push(dot);
          }
        });

      // grab all barres on the same fret as the clicked dot
      this.fretboardContainer
        .selectAll('.barre')
        .each((barre) => {
          if(barre.fret === fret) {
            barresOnSameFret.push(barre);
          }
        });

      if(dotsOnSameFret.length === 1) {
        // this is the last dot on this fret. remove any barres on the same fret
        barresOnSameFret.forEach(barre => {
          d3.select('#' + barre.barreId).remove();
          d3.select('#' + barre.barreClickableAreaId).remove();
        });
      }

      // remove the dot
      d3.select(thisRef).remove();

      // remove any text objects that might be there already
      this.clearButtonContents(fret, stringNum);
      // let filledDotsWithFingers = fretboardSvg.selectAll('.filled-dot-finger');
      // filledDotsWithFingers.each(function(dot) {
      //   if(dot.fret === fret && dot.string === stringNum) {
      //     d3.select('.filled-dot-finger-fret-' + fret + '-string-' + stringNum).remove();
      //   }
      // });

      // let filledDotsWithNoteNames = fretboardSvg.selectAll('.filled-dot-notename');
      // filledDotsWithNoteNames.each(function(dot) {
      //   if(dot.fret === fret && dot.string === stringNum) {
      //     d3.select('.filled-dot-notename-fret-' + fret + '-string-' + stringNum).remove();
      //   }
      // });

      // We don't want sticky buttons
      // let clrdata = [{
      //   selectedColor: d.color
      // }];

    } else if(color === 'barre') {
      // grab all dots on the same fret as the clicked dot
      let dotsOnSameFret: any[] = [];
      this.fretboardContainer
        .selectAll('.filled-dot')
        .each((dot) => {
          if(dot.fret === fret) {
            dotsOnSameFret.push(dot);
          }
        });

      // we want the dots sorted by lowest string first (6)
      dotsOnSameFret.sort((a,b) => {
        if(a.string > b.string) { return -1; }
        if(a.string < b.string) { return 1; }
        if(a.string === b.string) { return 0; }
      });

      // create an ID for the barre
      let id = uuidv4();
      let barreId = 'barre-' + id;
      let barreClickableAreaId = 'clickableBarreArea-' + id;
      let firstDot = dotsOnSameFret[0];
      let lastDot = dotsOnSameFret[dotsOnSameFret.length - 1];

      // draw a line from the center of the first dot to the center
      // of the last dot on the same fret
      let x1 = firstDot.x + 12;
      let y1 = firstDot.y + 18;
      let x2 = lastDot.x + 12;
      let y2 = lastDot.y + 18;

      //create some data first
      let barreData = [{
        fret: firstDot.fret,
        x1: x1,
        y1: y1,
        x2: x2,
        y2: y2,
        barreId: barreId,
        barreClickableAreaId: barreClickableAreaId
      }];

      let self = this;

      let barreG = this.fretboardContainer
        .select('svg')
        .append('g')
          .attr('class', 'barre-area-g');

      barreG
        .append('line')
          .attr('id', barreId)
          .attr('class', 'barre')
          .attr('x1', x1)
          .attr('y1', y1)
          .attr('x2', x2)
          .attr('y2', y2)
          .attr('class', 'barre')
          .data(barreData)
          .attr('stroke', 'black')
          .style('stroke-width', 12)
          .style('fill', '#000');

      barreG
        .lower(); // place behind the dots

      barreG
        .append('g')
          .attr('id', barreClickableAreaId)
          .attr('class', 'barre-clickable-area')
          .append('rect')
            .attr('width', x2-x1)
            .attr('height', 10) // needs to match barre's line thickness
            .style('stroke', '#000')
            .style('stroke-opacity', '0')
            .style('fill', '#ff0000')
            .style('fill-opacity', '0')
            .style('cursor', 'pointer')
            .attr('x', x1)
            .attr('y', y1 - 5) // subtract half of the height
            .on('click', (evt, d) => {
              // handler for a barre click
              let colorData = self.outerContainer.data();
              let previousColor = colorData[0].previousColor;
              let color = colorData[0] !== undefined ? colorData[0].selectedColor : self.dotColorChoices[0];
              let barre = self.fretboardContainer.select('svg').select('#' + barreId);
              let barreClickableArea = d3.select('#' + barreClickableAreaId);

              if(color === 'eraser') {
                barre.remove();
                barreClickableArea.remove();
              }
            });

      // We don't want sticky buttons
      // let clrdata = [{
      //   selectedColor: d.color
      // }];

      this.addedDotIds.push(barreId);
    } else if(color === 'finger') {
      // get this dot's x/y
      let thisDot = d3.select(thisRef);
      let filledDotX = parseInt(thisDot.attr('x'));
      let filledDotY = parseInt(thisDot.attr('y'));

      // remove any text objects that might be there already
      this.clearButtonContents(fret, stringNum);
      // let filledDotsWithFingers = fretboardSvg.selectAll('.filled-dot-finger');
      // filledDotsWithFingers.each(function(dot) {
      //   if(dot.fret === fret && dot.string === stringNum) {
      //     d3.select('.filled-dot-finger-fret-' + fret + '-string-' + stringNum).remove();
      //   }
      // });

      let text = (finger === 5) ? 'T' : finger;
      let textData = [{
        fret: fret,
        string: stringNum,
        finger: finger,
        label: text,
        x: filledDotX + 5,
        y: filledDotY + 24
      }];
      fretboardSvg
        .append('text')
        .text(text)
          // .style('stroke', '#fff')
          .style('fill', '#fff')
          .data(textData)
          .attr('x', filledDotX + 5)
          .attr('y', filledDotY + 24)
          // .style('font-size', '18px')
          .style('cursor', 'pointer')
          .style('font-weight', 'bold')
          .attr('class', 'filled-dot-finger-icon-text filled-dot-finger filled-dot-finger-fret-' + fret + '-string-' + stringNum)
          .on('click', function(d) {
            self.handleFilledDotClick(d, fret, stringNum, thisRef);
          });

      // We don't want sticky buttons
      // let clrdata = [{
      //   selectedColor: d.color
      // }];

      // this.outerContainer.data(clrdata);

      //always reset the fill on the legend icon
      // fingerIcon
      //   // .style('stroke', '#000')
      //   .style('fill', '#000')
      //   .style('fill-opacity', '1');
    } else if(color === 'interval') {
      // get this dot's x/y
      let thisDot = d3.select(thisRef);
      let filledDotX = parseInt(thisDot.attr('x'));
      let filledDotY = parseInt(thisDot.attr('y'));

      // use the position from the constants file
      filledDotX += INTERVAL_TEXT_POSITION[interval].x;



      // remove any text objects that might be there already
      this.clearButtonContents(fret, stringNum);
      // let filledDotsWithIntervals = fretboardSvg.selectAll('.filled-dot-interval');
      // filledDotsWithIntervals.each(function(dot) {
      //   if(dot.fret === fret && dot.string === stringNum) {
      //     d3.select('.filled-dot-interval-fret-' + fret + '-string-' + stringNum).remove();
      //   }
      // });

      let text = interval;
      let textData = [{
        fret: fret,
        string: stringNum,
        interval: interval,
        x: filledDotX,
        y: filledDotY + 24,
        label: interval
      }];
      fretboardSvg
        .append('text')
        .text(text)
          // .style('stroke', '#fff')
          .style('fill', '#fff')
          .data(textData)
          .attr('x', filledDotX)
          .attr('y', filledDotY + 24)
          // .style('font-size', '18px')
          .style('cursor', 'pointer')
          .style('font-weight', 'bold')
          .attr('class', 'filled-dot-interval-icon-text filled-dot-interval filled-dot-interval-fret-' + fret + '-string-' + stringNum)
          .on('click', function(d) {
            self.handleFilledDotClick(d, fret, stringNum, thisRef);
          });

      // We don't want sticky buttons
      // let clrdata = [{
      //   selectedColor: d.color
      // }];

      // this.outerContainer.data(clrdata);

      // //always reset the fill on the legend icon
      // intervalIcon
      //   // .style('stroke', '#000')
      //   .style('fill', '#000')
      //   .style('fill-opacity', '1');

    } else if(color === 'notename') {
      // get this dot's x/y
      let thisDot = d3.select(thisRef);
      let filledDotX = parseInt(thisDot.attr('x'));
      let filledDotY = parseInt(thisDot.attr('y'));

      // use the position from the constants file
      filledDotX += NOTENAME_TEXT_POSITION[noteName].x;

      // remove any text objects that might be there already
      this.clearButtonContents(fret, stringNum);
      // let filledDotsWithNoteNames = fretboardSvg.selectAll('.filled-dot-notename');
      // filledDotsWithNoteNames.each(function(dot) {
      //   if(dot.fret === fret && dot.string === stringNum) {
      //     d3.select('.filled-dot-notename-fret-' + fret + '-string-' + stringNum).remove();
      //   }
      // });

      let text = noteName;
      let textData = [{
        fret: fret,
        string: stringNum,
        noteName: noteName,
        x: filledDotX,
        y: filledDotY + 24,
        label: noteName
      }];
      fretboardSvg
        .append('text')
        .text(text)
          // .style('stroke', '#fff')
          .style('fill', '#fff')
          .data(textData)
          .attr('x', filledDotX)
          .attr('y', filledDotY + 24)
          // .style('font-size', '18px')
          .style('cursor', 'pointer')
          .style('font-weight', 'bold')
          .attr('class', 'filled-dot-notename-icon-text filled-dot-notename filled-dot-notename-fret-' + fret + '-string-' + stringNum)
          .on('click', function(d) {
            self.handleFilledDotClick(d, fret, stringNum, thisRef);
          });

      // let clrdata = [{
      //   selectedColor: d.color
      // }];

      // this.outerContainer.data(clrdata);

      //always reset the fill on the legend icon
      noteNameIcon
        // .style('stroke', '#000')
        .style('fill', '#000')
        .style('fill-opacity', '1');

    } else if(color === 'root') {
      // get this dot's x/y
      let thisDot = d3.select(thisRef);
      let filledDotX = parseInt(thisDot.attr('x'));
      let filledDotY = parseInt(thisDot.attr('y'));

      // remove any text objects that might be there already
      this.clearButtonContents(fret, stringNum);
      // let filledDotsWithRoot = fretboardSvg.selectAll('.dot-root');
      // filledDotsWithRoot.each(function(dot) {
      //   if(dot.fret === fret && dot.string === stringNum) {
      //     d3.select('.filled-dot-root-fret-' + fret + '-string-' + stringNum).remove();
      //   }
      // });

      let text = 'R';

      let textData = [{
        fret: fret,
        string: stringNum,
        x: filledDotX + 5,
        y: filledDotY + 24,
        label: text
      }];
      fretboardSvg
        .append('text')
        .text(text)
          // .style('stroke', '#fff')
          .style('fill', '#fff')
          .data(textData)
          .attr('x', filledDotX + 5)
          .attr('y', filledDotY + 24)
          .style('font-weight', 'bold')
          // .style('font-size', '18px')
          .style('cursor', 'pointer')
          .attr('class', 'filled-dot-root-icon-text filled-dot-root filled-dot-root-fret-' + fret + '-string-' + stringNum)
          .on('click', function(d) {
            self.handleFilledDotClick(d, fret, stringNum, thisRef);
          });

      // COMMENT OUT TO MAKE ROOT BUTTON STICKY
      // let clrdata = [{
      //   selectedColor: d.color
      // }];

      // this.outerContainer.data(clrdata);

      //always reset the fill on the legend icon
      rootIcon
        // .style('stroke', '#000')
        .style('fill', '#000')
        .style('fill-opacity', '1');
    } else {
      // we're passing around the selected color in the data so
      // if we change it, we need to update the datum
      // so that colors are remembered when we nuke the container
      // and rebuild the dots
      let existingDatum = d3.select(thisRef).datum();
      existingDatum.selectedDotColor = color;

      return d3.select(thisRef).style('fill', color);

    }

    colorData[0].selectedColor = color;
    // console.log('colorData after:', colorData);
    this.outerContainer.data(colorData);
    //reset the selected icon
    // if(color !== 'eraser' && color !== 'finger' && color !== 'interval' && color !== 'root') {
    //   this.resetSelectedIcon();
    //   this.oService.unlockLegend();
    // }


    // we are not resetting the eraser and barre icon borders because they're still on

    // always reset the  fill on the legend icons
    // grab all circles with a class of dot-finger-x where x is a number
    //d3.selectAll("circle[class*='dot-finger-']").style('fill', 'rgb(0,0,0)');
    // do the same for the interval buttons
    //d3.selectAll("circle[class*='dot-interval-']").style('fill', 'rgb(0,0,0)');
    // do the same for the root button
    //d3.selectAll("circle[class*='dot-root']").style('fill', 'rgb(0,0,0)');
  }

  private clearButtonContents(fret, stringNum): void {
    // remove any text objects that might be there already

    let classArray = [
      '.filled-dot-root',
      '.filled-dot-finger',
      '.filled-dot-interval',
      '.filled-dot-notename'
    ];

    // '.filled-dot-finger-fret-' + fret + '-string-' + stringNum
    // '.filled-dot-notename-fret-' + fret + '-string-' + stringNum
    // '.filled-dot-interval-fret-' + fret + '-string-' + stringNum
    // '.filled-dot-root-fret-' + fret + '-string-' + stringNum
    for(let x = 0; x < classArray.length; x++) {
      let textClass = classArray[x];
      let fullClassName = '';
      if(textClass.indexOf('filled') === -1) {
        fullClassName = '.filled-' + textClass.substring(1) + '-fret-' + fret + '-string-' + stringNum;
      } else {
        fullClassName = textClass + '-fret-' + fret + '-string-' + stringNum;
      }

      let filledDotsWithRoot = this.fretboardSvg.selectAll(textClass);
      filledDotsWithRoot.each(function(dot) {
        if(dot.fret === fret && dot.string === stringNum) {
          d3.select(fullClassName).remove();
        }
      });
    }
  }

  public addFret(): void {
    let newNumFrets = this.numberFrets + 1;
    if(typeof this.numberFrets === 'string') {
      newNumFrets = parseInt(this.numberFrets) + 1;
    }

    // set a message to the download handler not to move barres down
    // modified: true means the fretboard has been modified, don't translate the barres
    d3.select('#fretboardModified').property('value','true');

    // only proceed if the new number of frets is >= minimum number of frets to show always
    if(newNumFrets <= 24) {
      this.numberFrets = newNumFrets;

      // remember whatever dots we might have
      let dots = this.svg.selectAll('.filled-dot');

      // remember any dot labels we might have
      let dotIntervalLabels = this.svg.selectAll('.filled-dot-interval-icon-text');
      let noteNameLabels = this.svg.selectAll('.filled-dot-notename-icon-text');
      let dotRootLabels = this.svg.selectAll('.filled-dot-root-icon-text');
      let dotFingerLabels = this.svg.selectAll('.filled-dot-finger-icon-text');

      // remember any barres we might have
      let barres = d3.selectAll('line.barre');

      // remember the label
      let label = d3.select('#labelTSpan').text();
      let labelNode = document.getElementById('labelTSpan').parentNode;

      // remember the sublabel
      let sublabel = d3.select('#sublabelTSpan').text();
      let sublabelNode = document.getElementById('sublabelTSpan').parentNode;

      // nuke the container's contents
      this.fretboardContainer.html(null);

      // re-calculate the height since we're adding a fret
      this.calculateHeight();

      // redraw the chart from scratch with our new numFrets
      this.buildChart();

      //add things back that we saved before nuking
      this.addDotsBack(dots);
      this.addBarresBack(barres);
      this.recreateLabels(label, labelNode, sublabel, sublabelNode);
      this.addDotLabelsBack(dotIntervalLabels, dotFingerLabels, dotRootLabels, noteNameLabels);
    }
  }

  private addDotsBack(dots: any): void {
    let self = this;
    /**
       * Now that we nuked the container, put the dots back.
       * For each remembered dot, grab the x, y, selected-color, and
       * fret data attributes and append a new <use> element to the fretboard
       * that has the old x, y, etc.
       */

     dots.each((d, i) => {
       let x = d.x;
       let y = d.y;
       let color = d.selectedDotColor;
       let fret = d.fret;
       let string = d.string;
       let id = d.id;

       // rebuilding the data to bind it to the new filledDot <use> element
       let circleData = [{
         x: x,
         y: y,
         selectedDotColor: color,
         fret: fret,
         string: string,
         id: id
       }];

       // only put the dots back if their fret still exists
       if(fret <= this.numberFrets) {
         this.svg
           .append('use')
             .attr('href', '#filledDot')
             .attr('class', 'filled-dot')
             .data(circleData)
             .attr('x', x)
             .attr('y', y)
             .attr('id', id)
             .style('stroke', '#000')
             .style('fill', color)
             .on('click', function(d) {
              self.handleFilledDotClick(d, fret, string, this);
             });
       }
     });
  }

  private addBarresBack(barres: any): void {
    // put the barres back
    barres.each((d, i) => {
      let barreId = d.barreId;
      let x1 = d.x1;
      let y1 = d.y1;
      let x2 = d.x2;
      let y2 = d.y2;
      let fret = d.fret;
      let barreData = [{
        fret: fret,
        x1: x1,
        y1: y1,
        x2: x2,
        y2: y2,
        barreId: barreId
      }];

      // only put the barres back if their fret still exists
      if(fret <= this.numberFrets) {
        d3.select('#' + COMPONENT_IDS.fretboardSvg)
          .select('.fretboard-g')
            .append('line')
              .attr('id', barreId)
              .attr('class', 'barre')
              .attr('x1', x1)
              .attr('y1', y1)
              .attr('x2', x2)
              .attr('y2', y2)
              .data(barreData)
              .attr('stroke', 'black')
              .style('stroke-width', 12)
              .style('fill', '#000')
              .style('stroke-linecap', 'round')
              .lower(); // place behind the dots
      }
    });
  }

  private addDotLabelsBack(intervalLabels: any, fingerLabels: any, rootLabels: any, noteNameLabels: any): void {
    let self = this;
    rootLabels.each((d,i) => {

      let x = d.x;
      let y = d.y;
      let label = d.label;
      let fret = d.fret;
      let stringNum = d.string;
      let newD = d;


      // only put the labels back if their fret still exists
      if(fret <= this.numberFrets) {
        d3.select('#' + COMPONENT_IDS.fretboardSvg)
          .select('.fretboard-g')
            .append('text')
              .text(label)
              .attr('x', x)
              .attr('y', y)

              .style('fill', '#fff')
              .style('cursor', 'pointer')
              .style('font-weight', 'bold')
              .attr('class', 'filled-dot-interval-icon-text filled-dot-interval filled-dot-interval-fret-' + fret + '-string-' + stringNum)
              .data([newD])
              .on('click', function(d) {
                self.handleFilledDotClick(d, fret, stringNum, this);
               })
              ;
      }
    });

    intervalLabels.each((d,i) => {

      let x = d.x;
      let y = d.y;
      let label = d.label;
      let fret = d.fret;
      let stringNum = d.string;
      let newD = d;

      // only put the labels back if their fret still exists
      if(fret <= this.numberFrets) {
        d3.select('#' + COMPONENT_IDS.fretboardSvg)
          .select('.fretboard-g')
            .append('text')
              .text(label)
              .attr('x', x)
              .attr('y', y)

              .style('fill', '#fff')
              .style('cursor', 'pointer')
              .style('font-weight', 'bold')
              .attr('class', 'filled-dot-interval-icon-text filled-dot-interval filled-dot-interval-fret-' + fret + '-string-' + stringNum)
              .data([newD])
              .on('click', function(d) {
                self.handleFilledDotClick(d, fret, stringNum, this);
               })
              ;
      }
    });

    noteNameLabels.each((d,i) => {

      let x = d.x;
      let y = d.y;
      let label = d.label;
      let fret = d.fret;
      let stringNum = d.string;
      let newD = d;

      // only put the labels back if their fret still exists
      if(fret <= this.numberFrets) {
        d3.select('#' + COMPONENT_IDS.fretboardSvg)
          .select('.fretboard-g')
            .append('text')
              .text(label)
              .attr('x', x)
              .attr('y', y)

              .style('fill', '#fff')
              .style('cursor', 'pointer')
              .style('font-weight', 'bold')
              .attr('class', 'filled-dot-notename-icon-text filled-dot-notename filled-dot-notename-fret-' + fret + '-string-' + stringNum)
              .data([newD])
              .on('click', function(d) {
                self.handleFilledDotClick(d, fret, stringNum, this);
               })
              ;
      }
    });

    fingerLabels.each((d,i) => {

      let x = d.x;
      let y = d.y;
      let label = d.label;
      let fret = d.fret;
      let stringNum = d.string;
      let newD = d;

      // only put the labels back if their fret still exists
      if(fret <= this.numberFrets) {
        d3.select('#' + COMPONENT_IDS.fretboardSvg)
          .select('.fretboard-g')
            .append('text')
              .text(label)
              .attr('x', x)
              .attr('y', y)

              .style('fill', '#fff')
              .style('font-weight', 'bold')
              .style('cursor', 'pointer')
              .attr('class', 'filled-dot-finger-icon-text filled-dot-finger filled-dot-finger-fret-' + fret + '-string-' + stringNum)
              .data([newD])
              ;
      }
    });
  }



  private recreateLabels(label: any, labelNode: any, sublabel: any, sublabelNode: any): void {
    this.config.labelDefault = label;
    this.config.labelNode = labelNode;
    this.config.sublabelDefault = sublabel;
    this.config.sublabelNode = sublabelNode;
    let createdLabels = new FretboardLabels(this.config);
    this.config.labelNode = undefined;
    this.config.sublabelNode = undefined;
    // let createdLabel = new FretboardLabel(this.config);
    // let createdSublabel = new FretboardSublabel(this.config);
  }

  public removeFret(): void {
    const newNumFrets = this.numberFrets - 1;

    // set a message to the download handler not to move barres down
    d3.select('#fretboardModified').property('value','true');

    // only proceed if the new number of frets is >= minimum number of frets to show always
    if(newNumFrets >= 3) {
      this.numberFrets = newNumFrets;

      // remember whatever dots we might have
      let dots = this.svg.selectAll('.filled-dot');

      // remember any dot labels we might have
      let dotIntervalLabels = this.svg.selectAll('.filled-dot-interval-icon-text');
      let noteNameLabels = this.svg.selectAll('.filled-dot-notename-text');
      let dotRootLabels = this.svg.selectAll('.filled-dot-root-icon-text');
      let dotFingerLabels = this.svg.selectAll('.filled-dot-finger-icon-text');

      // remember any barres we might have
      let barres = this.svg.selectAll('.barre');

      // remember the label
      let label = d3.select('#labelTSpan').text();
      let labelNode = document.getElementById('labelTSpan').parentNode;

      // remember the sublabel
      let sublabel = d3.select('#sublabelTSpan').text();
      let sublabelNode = document.getElementById('sublabelTSpan').parentNode;

      // nuke the container's contents
      this.fretboardContainer.html(null);

      // calculate the new height since we're removing a fret
      this.calculateHeight();

      // draw the chart from scratch with a new numFrets number.
      this.buildChart();

      // put things back the way we found them
      this.addDotsBack(dots);
      this.addBarresBack(barres);
      this.recreateLabels(label, labelNode, sublabel, sublabelNode);
      this.addDotLabelsBack(dotIntervalLabels, dotFingerLabels, dotRootLabels, noteNameLabels);
    }
  }

  public undoLastDot(): void {
    let dotIdToRemove = this.addedDotIds.pop();
    let dot = d3.select('#' + dotIdToRemove);
    dot.remove();
  }

  private clearAnyText(fretboardSvg: any, fret: any, stringNum: any): void {
    // remove any text objects that might be there already
    let filledDotsWithRoot = fretboardSvg.selectAll('.filled-dot-root');
    filledDotsWithRoot.each(function(dot) {
      if(dot.fret === fret && dot.string === stringNum) {
        d3.select('.filled-dot-root-fret-' + fret + '-string-' + stringNum).remove();
      }
    });

    // remove any text objects that might be there already
    let filledDotsWithIntervals = fretboardSvg.selectAll('.filled-dot-interval');
    filledDotsWithIntervals.each(function(dot) {
      if(dot.fret === fret && dot.string === stringNum) {
        d3.select('.filled-dot-interval-fret-' + fret + '-string-' + stringNum).remove();
      }
    });
  }

  private resetSelectedIcon(): void {
    this.oService.unsetSelectedTool();
  }
}
