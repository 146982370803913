export const ARTIST_NAMES = {
  COLLECTIVE_SOUL: 'Collective Soul',
  RHCP: 'Red Hot Chili Peppers',
  JOHN_MAYER: 'John Mayer',
  JOHN_COUGAR: 'John Cougar Mellencamp',
  SNOW_PATROL: 'Snow Patrol',
  KANSAS: 'Kansas',
  STP: 'Stone Temple Pilots',
  AIC: 'Alice in Chains',
  DAUGHTRY: 'Daughtry',
  TOM_PETTY: 'Tom Petty and the Heatbreakers',
  BON_JOVI: 'Bon Jovi',
  COUNTING_CROWS: 'Counting Crows',
  CRASH_MOTIVE: 'The Crash Motive',
  DOOBIE_BROTHERS: 'The Doobie Brothers'
}

export const SONG_TITLES = {
  THE_WORLD_I_KNOW: 'The World I Know',
  HOME: 'Home',
  DANI_CALIFORNIA: 'Dani California',
  MY_FRIENDS: 'My Friends',
  PLUSH: 'Plush',
  MEATPLOW: 'Meatplow',
  SHUT_YOUR_EYES: 'Shut Your Eyes',
  JACK_AND_DIANE: 'Jack and Diane',
  YOU_WRECK_ME: 'You Wreck Me',
  DUST_IN_THE_WIND: 'Dust in the Wind',
  AMY: 'Amy',
  WAITING_SAVE_A_LIFE: 'Waiting (To Save Your Life)',
  RIGHT_TURN: 'Right Turn',
  YOUR_BODY_IS_A_WONDERLAND: 'Your Body is a Wonderland',
  WANTED_DEAD_OR_ALIVE: 'Wanted Dead or Alive',
  MR_JONES: 'Mr. Jones',
  NOBODY: 'Nobody'
}

export const TUNINGS = {
  STANDARD: 'Standard',
  STANDARD_CAPO_1: 'Standard, Capo 1st Fret',
  STANDARD_CAPO_2: 'Standard, Capo 2nd Fret',
  FLAT: 'Down 1/2 Step',
  DROPD: 'Drop-D',
  DOUBLE_DROPD: 'Double Drop-D',
  OPEN_E: 'Open E'
}
