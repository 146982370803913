import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//layout
import { FlexLayoutModule } from '@angular/flex-layout';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderComponent } from './header/header.component';
import { LandingHeaderComponent } from './landing-header/landing-header.component';
// import { SvgComponent } from './svg/svg.component';

//services
// import { MajorTemplateHomeComponent } from './svg/svgPages/majorTemplateHome/majorTemplateHome.component';
import { FormsModule } from '@angular/forms';
import { NakedFretComponent } from './naked-fret/naked-fret.component';
import { ReleaseHistoryComponent } from './release-history/release-history.component';
import { SiteMenuComponent } from './site-menu/site-menu.component';
import { NavComponent } from './nav/nav.component';
import { AboutComponent } from './about/about.component';
import { VideosComponent } from './videos/videos.component';
import { TermsComponent } from './terms/terms.component';
import { FooterComponent } from './footer/footer.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ApiService } from './services/api.service';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { HelpBtnComponent } from './controls/help-btn/help-btn.component';
import { DownloadBtnComponent } from './controls/download-btn/download-btn.component';
import { DotColorsComponent } from './controls/dot-colors/dot-colors.component';
import { ClearAllBtnComponent } from './controls/clear-all-btn/clear-all-btn.component';
import { RemoveFretBtnComponent } from './controls/remove-fret-btn/remove-fret-btn.component';
import { AddFretBtnComponent } from './controls/add-fret-btn/add-fret-btn.component';
import { EditLabelsBtnComponent } from './controls/edit-labels-btn/edit-labels-btn.component';
import { EraserBtnComponent } from './controls/eraser-btn/eraser-btn.component';
import { AddBarreBtnComponent } from './controls/add-barre-btn/add-barre-btn.component';
import { FingerNumberBtnComponent } from './controls/finger-number-btn/finger-number-btn.component';
import { IntervalBtnComponent } from './controls/interval-btn/interval-btn.component';
import { RootFingerBtnComponent } from './controls/root-finger-btn/root-finger-btn.component';
import { SongListComponent } from './songs/song-list/song-list.component';
import { SongComponent } from './songs/song/song.component';
import { LabelSizePosBtnComponent } from './controls/label-size-pos-btn/label-size-pos-btn.component';
import { NoteNamesComponent } from './controls/note-names/note-names.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LandingHeaderComponent,
    // SvgComponent,
    // MajorTemplateHomeComponent,
    NakedFretComponent,
    ReleaseHistoryComponent,
    SiteMenuComponent,
    NavComponent,
    AboutComponent,
    VideosComponent,
    TermsComponent,
    FooterComponent,
    FeedbackComponent,
    ToolbarComponent,
    HelpBtnComponent,
    DownloadBtnComponent,
    DotColorsComponent,
    ClearAllBtnComponent,
    RemoveFretBtnComponent,
    AddFretBtnComponent,
    EditLabelsBtnComponent,
    EraserBtnComponent,
    AddBarreBtnComponent,
    FingerNumberBtnComponent,
    IntervalBtnComponent,
    RootFingerBtnComponent,
    SongListComponent,
    SongComponent,
    LabelSizePosBtnComponent,
    NoteNamesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  providers: [
    ApiService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
