export const CHORDS = {
  A_1: {
    name: 'A',
    version: 1,
    imageName: 'A.png',
    imageFolder: 'chords/A'
  },
  Am_1: {
    name: 'A',
    version: 1,
    imageName: 'Am.png',
    imageFolder: 'chords/A'
  },
  A_ADD4_1: {
    name: 'Aadd4',
    version: 1,
    imageName: 'Aadd4.png',
    imageFolder: 'chords/A'
  },
  A_SUS2_1: {
    name: 'Asus2',
    version: 1,
    imageName: 'Asus2.png',
    imageFolder: 'chords/A'
  },
  Bm_1: {
    name: 'Bm',
    version: 1,
    imageName: 'Bm.png',
    imageFolder: 'chords/B'
  },
  C_1: {
    name: 'C',
    version: 1,
    imageName: 'C.png',
    imageFolder: 'chords/C'
  },
  C_2: {
    name: 'C',
    version: 1,
    imageName: 'C_2.png',
    imageFolder: 'chords/C'
  },
  C5: {
    name: 'C5',
    version: 1,
    imageName: 'C5.png',
    imageFolder: 'chords/C'
  },
  C6SUS4: {
    name: 'C6sus4',
    version: 1,
    imageName: 'C6Sus4.png',
    imageFolder: 'chords/C'
  },
  D_1: {
    name: 'D',
    version: 1,
    imageName: 'D.png',
    imageFolder: 'chords/D'
  },
  D_2: {
    name: 'D',
    version: 1,
    imageName: 'D_2.png',
    imageFolder: 'chords/D'
  },
  Dm_1: {
    name: 'Dm',
    version: 1,
    imageName: 'Dm.png',
    imageFolder: 'chords/D'
  },
  D_SUS2_1: {
    name: 'Dsus2',
    version: 1,
    imageName: 'Dsus2.png',
    imageFolder: 'chords/D'
  },
  D_SUS4_1: {
    name: 'Dsus4',
    version: 1,
    imageName: 'Dsus4.png',
    imageFolder: 'chords/D'
  },
  D6SUS4: {
    name: 'D6sus4',
    version: 1,
    imageName: 'D6sus4.png',
    imageFolder: 'chords/D'
  },
  D9_SUS4_SLASH_C: {
    name: 'D9sus4/C',
    version: 1,
    imageName: 'D9sus4SlashC.png',
    imageFolder: 'chords/D'
  },
  EM11_SLASH_G: {
    name: 'Em11/G',
    version: 1,
    imageName: 'Em11SlashG.png',
    imageFolder: 'chords/E'
  },
  F_1: {
    name: 'F',
    version: 1,
    imageName: 'F.png',
    imageFolder: 'chords/F'
  },
  FSHARP_1: {
    name: 'F#',
    version: 1,
    imageName: 'Fsnarpmsharp5.png',
    imageFolder: 'chords/Fsharp'
  },
  FSHARP_2: {
    name: 'F#m7#5',
    version: 1,
    imageName: 'Fsharpm7sharp5.png',
    imageFolder: 'chords/Fsharp'
  },
  G_ADD11_1: {
    name: 'Gadd11',
    version: 1,
    imageName: 'Gadd11.png',
    imageFolder: 'chords/G'
  },
  G_1: {
    name: 'G',
    version: 1,
    imageName: 'G.png',
    imageFolder: 'chords/G'
  },
  G_2: {
    name: 'G',
    version: 2,
    imageName: 'G_2.png',
    imageFolder: 'chords/G'
  },
  G_3: {
    name: 'G',
    version: 1,
    imageName: 'G_3.png',
    imageFolder: 'chords/G'
  },
  G6_SLASH_B: {
    name: 'G6/B',
    version: 1,
    imageName: 'GSixSlashB.png',
    imageFolder: 'chords/G'
  },
  Gm13_SLASH_Bb: {
    name: 'Gm13/Bb',
    version: 1,
    imageName: 'Gm13SlashBb.png',
    imageFolder: 'chords/G'
  },
}
