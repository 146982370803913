
<div class="box">
  <div class="columns">
    <div class="field column">
      <label class="label">Name</label>
      <div class="control">
        <input class="input" type="text" placeholder="Name" [id]="nameId">
      </div>
    </div>

    <div class="field column">
      <label class="label">Email</label>
      <div class="control has-icons-left has-icons-right">
        <input class="input" type="email" placeholder="Enter your email address" value="" [id]="emailId">
        <span class="icon is-small is-left">
          <i class="fas fa-envelope"></i>
        </span>
      </div>
    </div>

    <div class="field column is-one-fifth">
      <label class="label">Subject</label>
      <div class="control">
        <div class="select">
          <select [id]="subjectId">
            <option>Feedback</option>
            <option>Feature Request</option>
            <option>Bug</option>
            <option>Other</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="field">
    <label class="label"></label>
    <div class="control">
      <div class="questions-comments level-item has-text-centered">Report a <span class="heavy">Bug</span>, leave some <span class="heavy">Feedback</span>, suggest a <span class="heavy">Feature</span>, or just say<span class="heavy">Hi!</span></div>
    </div>
  </div> -->

  <div class="field">
    <label class="label">Message</label>
    <div class="control">
      <textarea class="textarea" placeholder="Enter your message" [id]="messageId"></textarea>
    </div>
  </div>

  <div class="field is-grouped">
    <div class="control">
      <button class="button is-link" (click)="handleFeedbackSubmit()">Submit</button>
    </div>
  </div>

</div>

<div class="modal" id="contact-submit-modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Status</p>
      <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body" style="overflow: hidden;">
      <!-- Content ... -->
      <div class="modal-content is-flex is-flex-direction-row is-justify-content-center"></div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success">Close</button>
    </footer>
  </div>
</div>

<div class="modal" id="feedback-submit-modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Thank you!</p>
      <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body" style="overflow: hidden;">
      <!-- Content ... -->
      <div class="modal-content is-flex is-flex-direction-row is-justify-content-center"></div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success">Close</button>
    </footer>
  </div>
</div>
