import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public handleMenuDropdown(targetSelector: string): void {
    let existingClassesOnTarget = d3.select('#tools-dropdown').attr('class');
    let dropdown = d3.select('#dropdown-menu');
    let classExists = dropdown.classed('is-active');
    let iconClassExists = dropdown.classed('expanded-dropdown');

    if(classExists) {
      dropdown.style('display', 'none');
      dropdown.classed('is-active', false);
    } else {
      dropdown.style('display', 'block');
      dropdown.classed('is-active', true);
    }

    d3.select('#tools-dropdown')
      .classed('expanded-dropdown', d3.select(targetSelector).classed('expanded-dropdown') ? false : true);
  }

  public handleHamburger(targetSelector: string): void {

    d3.select(targetSelector)
      .classed('is-active', d3.select(targetSelector).classed('is-active') ? false : true);


      // .classed('menu-dropdown-hidden', d3.select(targetSelector).classed('menu-dropdown-hidden') ? false : true);
    // d3.select('.navbar-burger').classed('is-active', d3.select('.navbar-burger').classed("is-active") ? false : true)
  }

  public handleReleaseHistoryPage(): void {
    d3.select('.release-history-container').style('display', 'block');

    d3.select('.app').style('display', 'none');
    d3.select('.why-container').style('display', 'none');
    d3.select('.videos-container').style('display', 'none');
    d3.select('.terms-container').style('display', 'none');
    d3.select('.feedback-container').style('display', 'none');
  }

  public handleVideosPage(): void {
    d3.select('.videos-container').style('display', 'block');

    d3.select('.app').style('display', 'none');
    d3.select('.why-container').style('display', 'none');
    d3.select('.release-history-container').style('display', 'none');
    d3.select('.terms-container').style('display', 'none');
    d3.select('.feedback-container').style('display', 'none');
  }

  public handleHomePage(): void {
    d3.select('.app').style('display', 'block');

    d3.select('.why-container').style('display', 'none');
    d3.select('.terms-container').style('display', 'none');
    d3.select('.videos-container').style('display', 'none');
    d3.select('.release-history-container').style('display', 'none');
    d3.select('.feedback-container').style('display', 'none');
  }

  public handleAboutPage(): void {
    d3.select('.why-container').style('display', 'block');

    d3.select('.terms-container').style('display', 'none');
    d3.select('.videos-container').style('display', 'none');
    d3.select('.app').style('display', 'none');
    d3.select('.release-history-container').style('display', 'none');
    d3.select('.feedback-container').style('display', 'none');
  }

  public handleFeedbackPage(): void {
    d3.select('.feedback-container').style('display', 'block');

    d3.select('.terms-container').style('display', 'none');
    d3.select('.videos-container').style('display', 'none');
    d3.select('.app').style('display', 'none');
    d3.select('.release-history-container').style('display', 'none');
    d3.select('.why-container').style('display', 'none');
  }

}
