export const APP_CONFIG = {
  api_host: 'https://hituptheband.com/api',
  app_id: '181b6ca5-4319-4c65-9b26-487241fbb564',
  app_pw: '659a5346c3abe52183f33fd9f27d46ee1f9b6e6ca06dc3f6e0ea8ee712412981'
}

export const ICON_NAMES = {
  blank: '',
  help: '',
  download: '',
  clearAll: '',
  removeFret: '',
  addFret: '',
  editLabels: '',
  eraser: '',

}

export const INTERVALS = {
  perfectUnison: 'P1',
  majorSecond: 'M2',
  minorSecond: 'm2',
  majorThird: 'M3',
  minorThird: 'm3',
  perfectFourth: 'P4',
  perfectFifth: 'P5',
  majorSixth: 'M6',
  minorSixth: 'm6',
  majorSeventh: 'M7',
  minorSeventh: 'm7',
  perfectOctave: 'P8',
  perfectUnisonRoman: 'I',
  majorSecondRoman: 'II',
  minorSecondRoman: 'ii',
  majorThirdRoman: 'III',
  minorThirdRoman: 'iii',
  perfectFourthRoman: 'IV',
  perfectFifthRoman: 'V',
  majorSixthRoman: 'VI',
  minorSixthRoman: 'vi',
  majorSeventhRoman: 'VII',
  minorSeventhRoman: 'vii',
  perfectOctaveRoman: 'VIII',
}

export const NOTE_NAMES = {
  a: 'A',
  aSharp: 'A#',
  b: 'B',
  c: 'C',
  cSharp: 'C#',
  d: 'D',
  dSharp: 'D#',
  e: 'E',
  f: 'F',
  fSharp: 'F#',
  g: 'G',
  gSharp: 'G#',
  aFlat: 'Ab',
  bFlat: 'Bb',
  dFlat: 'Db',
  eFlat: 'Eb',
  gFlat: 'Gb'
}

export const NOTENAME_TEXT_POSITION = {
  "A": {
    x: 5,
    y: 0
  },
  "A#": {
    x: 1,
    y: 0
  },
  "B": {
    x: 5,
    y: 0
  },
  "C": {
    x: 4,
    y: 0
  },
  "C#": {
    x: 0,
    y: 0
  },
  "D": {
    x: 5,
    y: 0
  },
  "D#": {
    x: 0,
    y: 0
  },
  "E": {
    x: 5,
    y: 0
  },
  "F": {
    x: 5,
    y: 0
  },
  "F#": {
    x: 2,
    y: 0
  },
  "G": {
    x: 4,
    y: 0
  },
  "G#": {
    x: 0,
    y: 0
  },
  "Ab": {
    x: 0,
    y: 0
  },
  "Bb": {
    x: 0,
    y: 0
  },
  "Db": {
    x: 0,
    y: 0
  },
  "Eb": {
    x: 0,
    y: 0
  },
  "Gb": {
    x: -1,
    y: 0
  }
}

export const INTERVAL_TEXT_POSITION = {
  P1: {
    x: 0,
    y: 0
  },
  M2: {
    x: -1,
    y: 0
  },
  m2: {
    x: -1,
    y: 0
  },
  M3: {
    x: -1,
    y: 0
  },
  m3: {
    x: -1,
    y: 0
  },
  P4: {
    x: 0,
    y: 0
  },
  P5: {
    x: 0,
    y: 0
  },
  M6: {
    x: -1,
    y: 0
  },
  m6: {
    x: -1,
    y: 0
  },
  M7: {
    x: -1,
    y: 0
  },
  m7: {
    x: -1,
    y: 0
  },
  P8: {
    x: 0,
    y: 0
  },
  I: {
    x: 7,
    y: 0
  },
  II: {
    x: 6,
    y: 0
  },
  ii: {
    x: 6,
    y: 0
  },
  III: {
    x: 4,
    y: 0
  },
  iii: {
    x: 5,
    y: 0
  },
  IV: {
    x: 4,
    y: 0
  },
  V: {
    x: 5,
    y: 0
  },
  VI: {
    x: 3,
    y: 0
  },
  vi: {
    x: 4,
    y: 0
  },
  VII: {
    x: 1,
    y: 0
  },
  vii: {
    x: 2,
    y: 0
  },
  VIII: {
    x: -2,
    y: 0
  }
}

export const FILLED_DOT_TEXT = {
  root: 'R'
}

export const COMPONENT_IDS = {
  fretboard: 'nf-fretboard',
  mutebarContainer: 'nf-mutebar',
  mutebarG: 'nf-mutebar-g',
  mutebarSvg: 'nf-mutebar-svg',
  nakedFrets: 'nf-app',
  outerContainer: 'nf-outer-container',
  fretboardColumnContainer: 'nf-fretboard-column-container',
  fretboardContainer: 'nf-fretboard-container',
  fretboardSvg: 'nf-fretboard-svg',
  toolbarContainer: 'nf-fretboard-toolbar-container',
  labelContainer: 'nf-label-container',
  sublabelContainer: 'nf-sublabel-container',
  fieldFormContainer: 'nf-field-form-container',
  downloadBtn: 'nf-download-btn',
  clearAllBtn: 'nf-clear-all-btn',
  removeOneFretBtn: 'nf-remove-one-fret-btn',
  addOneFretBtn: 'nf-add-one-fret-btn',
  editLabelsBtn: 'nf-edit-labels-btn',
  eraserBtn: 'nf-eraser-btn',
  eraserBtnContainer: 'nf-eraser-btn-container',
  addBarreBtn: 'nf-add-barre-btn',
  addBarreBtnContainer: 'nf-add-barre-btn-container',
  dotColorBlack: 'nf-dot-color-black',
  dotColorRed: 'nf-dot-color-red',
  dotColorGreen: 'nf-dot-color-green',
  dotColorBlue: 'nf-dot-color-blue',
  muteBtn: 'nf-mute-string-btn',
  openBtn: 'nf-open-string-btn',
  legendContainer: 'nf-legend',
  legendColumnContainer: 'nf-legend-column-container',
  fingerNumberContainer: 'nf-finger-number-container',
  intervalBtnContainer: 'nf-interval-btn-container',
  rootFingerBtnContainer: 'nf-root-finger-btn-container',
  noteNamesContainer: 'nf-note-names-container'
}
