import { Component, OnInit } from '@angular/core';
import { SONGS } from '@src/app/constants/song-chord-mapping';

@Component({
  selector: 'app-song-list',
  templateUrl: './song-list.component.html',
  styleUrls: ['./song-list.component.scss']
})
export class SongListComponent implements OnInit {
  public songs: any[] = [];

  constructor() {
    this.prepareSongs();
   }

  ngOnInit(): void {
  }

  private prepareSongs(): void {
    this.songs = SONGS;
  }

}
