<a class="item" (click)="openLabelFormat()">
  <div class="">
    <img src="../assets/images/baseline_text_rotation_none_black_24dp.png" width="24" height="24" style="width: 50px; height: 50px;">
  </div>
</a>

<!-- Content ... -->
<div class="box label-toolbar close" id="labelToolbar">
  <div class="label-close">
    <a class="" (click)="closeLabelFormat()">
      <img src="../assets/images/close-box-outline.svg" width="12" height="12" style="width: 25px; height: 25px;">
    </a>
  </div>
  <h1>Label</h1>
  <div class="field">
    <div class="control">
      <!-- <h3>Size</h3> -->
      <a class="" (click)="labelSizeIncrease()">
        <img src="../assets/images/format-font-size-increase.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="labelSizeDecrease()">
        <img src="../assets/images/format-font-size-decrease.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <!-- <h3>Position</h3> -->
      <div class="slider-container">
        <span>Left</span>
        <input type="range" min="1" step="1" max="250" (change)="setLabelX()" value="22" id="labelX" class="slider">
        <span class="bump-right">Right</span>
      </div>
      <div class="slider-container">
        <span>Up</span>
        <input type="range" min="427" step="1" max="520" (change)="setLabelY()" value="443" id="labelY" class="slider">
        <span class="bump-right">Down</span>
      </div>

      <!-- <a class="" (click)="labelUp()">
        <img src="../assets/images/arrow-up-bold.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="labelDown()">
        <img src="../assets/images/arrow-down-bold.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="labelLeft()">
        <img src="../assets/images/arrow-left-bold.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="labelRight()">
        <img src="../assets/images/arrow-right-bold.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a> -->
    </div>
  </div>
  <h1>Sub-Label</h1>
  <div class="field">
    <div class="control">
      <!-- <h3>Size</h3> -->
      <a class="" (click)="sublabelSizeIncrease()">
        <img src="../assets/images/format-font-size-increase.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="sublabelSizeDecrease()">
        <img src="../assets/images/format-font-size-decrease.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <!-- <h3>Position</h3> -->
      <div class="slider-container">
        <span>Left</span>
        <input type="range" min="1" step="1" max="250" (change)="setSublabelX()" value="22" id="sublabelX" class="slider">
        <span class="bump-right">Right</span>
      </div>
      <div class="slider-container">
        <span>Up</span>
        <input type="range" min="427" step="1" max="520" (change)="setSublabelY()" value="478" id="sublabelY" class="slider">
        <span class="bump-right">Down</span>
      </div>
      <!-- <div class="slider-container">
        <span>Left</span>
        <input type="range" min="1" step="5" max="100" value="50" id="sublabelX" class="slider">
        <span class="bump-right">Right</span>
      </div>
      <div class="slider-container">
        <span>Up</span>
        <input type="range" min="1" step="5" max="100" value="50" id="sublabelY" class="slider">
        <span class="bump-right">Down</span>
      </div> -->
      <!-- <a class="" (click)="sublabelSizeIncrease()">
        <img src="../assets/images/format-font-size-increase.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="sublabelSizeDecrease()">
        <img src="../assets/images/format-font-size-decrease.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="sublabelUp()">
        <img src="../assets/images/arrow-up-bold.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="sublabelDown()">
        <img src="../assets/images/arrow-down-bold.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="sublabelLeft()">
        <img src="../assets/images/arrow-left-bold.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a>
      <a class="" (click)="sublabelRight()">
        <img src="../assets/images/arrow-right-bold.svg" width="24" height="24" style="width: 50px; height: 50px;">
      </a> -->
    </div>
  </div>
</div>
