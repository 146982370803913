import { Component, OnInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { COMPONENT_IDS } from '@src/app/constants/constants';
import * as saveAs from "file-saver";
import { v4 as uuidv4 } from 'uuid/dist';
import { NAKED_FRETS } from '@src/app/models/nf-interfaces';

declare let gtag:Function;

@Component({
  selector: 'app-download-btn',
  templateUrl: './download-btn.component.html',
  styleUrls: ['./download-btn.component.scss']
})
export class DownloadBtnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Converts an svg string into a data:image/png;base64 string.
   * @param {string} svg
   * @param {number | undefined} width
   * @param {number | undefined} height
   */
  private async svg2png(svg, width = 1000, height = 1000) {
    const img = new Image();
    img.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;

    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });

    const canvas = document.createElement("canvas");
    [canvas.width, canvas.height] = [width, height];

    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    return canvas.toDataURL("image/png");
  }

  public handleClick(): void {
    let fretboardSvgSelector = '#' + COMPONENT_IDS.fretboardSvg;
    let fretboardHeight = d3.select('#' + COMPONENT_IDS.fretboardContainer).data()[0]['fretboardHeight'];
    let fretboardWidth = d3.select('#' + COMPONENT_IDS.fretboardContainer).data()[0]['fretboardWidth'];
    let svg = d3.select(fretboardSvgSelector);
    //let dataUrl = this.svg2png(svg, fretboardWidth, fretboardHeight)

    function save(dataBlob, filesize) {
      saveAs(dataBlob, 'NakedFretsDownload.png'); // FileSaver.js function
    }

    let clonedNode = svg.node().cloneNode(true);

    // serialize the svg
    let serializer = new XMLSerializer();
    let svgString = serializer.serializeToString(clonedNode);
    this.svgString2Image(svgString, fretboardWidth, fretboardHeight, 'png', save); // passes Blob and filesize String to the callback

  }

  public handleClick2(): void {
    // we don't care if the legend is locked to download

    let fretboardSvgSelector = '#' + COMPONENT_IDS.fretboardSvg;
    let svg = d3.select(fretboardSvgSelector);
    let fretboardHeight = d3.select('#' + COMPONENT_IDS.fretboardContainer).data()[0]['fretboardHeight'];

    svg.attr('height', fretboardHeight + 30); // need a little something extra for the bottom

    let muteBarSvg = d3.select('#' + COMPONENT_IDS.mutebarContainer).select('svg');
    let muteBarDefs = muteBarSvg.select('defs');
    let muteBarG = muteBarSvg.select('.' + COMPONENT_IDS.mutebarG);

    // add some margins
    let newWidth = d3.select('#' + COMPONENT_IDS.fretboardContainer).data()[0]['fretboardWidth'];
    let newHeight = fretboardHeight;
    let svgNode = svg.node();

    // using cloned nodes so it doesn't rip the mutebar out of the DOM

    // create a copy of our fretboard
    let clonedNode = svg.node().cloneNode(true);
    d3.select(clonedNode).select('.fretboard-g').style('transform', 'translate(0px,20px)');

    // position any barres lower
    let isFretboardModified = d3.select('#fretboardModified').property('value');
    if(isFretboardModified === 'false'){
      d3.select(clonedNode).selectAll('.barre').style('transform', 'translate(0px,20px)');
    }

    //position the finger text lower since it's off for some reason
    d3.select(clonedNode).selectAll('.filled-dot-finger-icon-text').style('transform', 'translate(0px,20px)');

    //position the root text lower since it's off for some reason
    d3.select(clonedNode).selectAll('.filled-dot-root-icon-text').style('transform', 'translate(0px,20px)');

    d3.select(clonedNode).selectAll('.chart-labels').style('transform', 'translate(0px,20px)');


    //position the interval text lower since it's "off" for some reason
    d3.select(clonedNode).selectAll('.filled-dot-interval-icon-text').style('transform', 'translate(0px,20px)');

    //position the notename text lower since it's "off" for some reason
    d3.select(clonedNode).selectAll('.filled-dot-notename-icon-text').style('transform', 'translate(0px,20px)');


    // insert a copy of the mutebar <defs> before the fretboard
    clonedNode.insertBefore(muteBarDefs.node().cloneNode(true), null);

    // insert a copy of the mutebar <g> before the fretboard
    clonedNode.insertBefore(muteBarG.node().cloneNode(true), null);

    d3.select(clonedNode).select('defs').lower();

    // serialize the svg
    let serializer = new XMLSerializer();
    let svgString = serializer.serializeToString(clonedNode);
    // console.log('svgString:', svgString);

    svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink='); // Fix root xlink without namespace
    svgString = svgString.replace(/NS\d+:href/g, 'href'); // Safari NS namespace fix

    function save(dataBlob, filesize) {
      saveAs(dataBlob, 'NakedFretsDownload.png'); // FileSaver.js function
    }

    // send a ga message to collect downloads
    this.sendGAEvent();
    let config = NAKED_FRETS[0];
    this.svgString2Image(svgString, newWidth + config.fretboardNumberWidth, newHeight, 'png', save); // passes Blob and filesize String to the callback
  }

  public svgString2Image(svgString, width, height, format, callback) {
    var format = format ? format : 'png';
    var imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");

    canvas.width = width;
    canvas.height = height;

    var image = new Image();
    image.onload = function () {
      context.clearRect(0, 0, width, height);
      context.drawImage(image, 0, 0, width, height);

      canvas.toBlob(function (blob: any) {
        var filesize = Math.round(blob.length / 1024) + ' KB';
        if (callback) callback(blob, filesize);
      });
    };

    image.src = imgsrc;
  }

  private sendGAEvent(): void {
    gtag("event", "select_content", {
      content_type: "download",
      item_id: 'downloadId' + uuidv4()
    });
  }

}
