import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { COMPONENT_IDS } from '@src/app/constants/constants';
import { OrchestratorService } from '@src/app/services/orchestrator.service';

@Component({
  selector: 'app-finger-number-btn',
  templateUrl: './finger-number-btn.component.html',
  styleUrls: ['./finger-number-btn.component.scss']
})
export class FingerNumberBtnComponent implements AfterViewInit {
  public fingerNumberContainerId: string = COMPONENT_IDS.fingerNumberContainer;
  constructor(private oService: OrchestratorService) { }

  ngAfterViewInit(): void {
    this.createIcons();
  }

  private createIcons(): void {
    let self = this;

    let container = d3.select('#' + this.fingerNumberContainerId);
    let svg = container
      .append('svg')
        .attr('viewbox', '0 0 70 300')
        .attr('width', 70)
        .attr('height', 300);
        // .attr('id', 'nf-finger-number-svg')


    let iconStartY = 0;
    // draw finger number icons
    for(let x = 1; x < 6; x++) {
      let fingerDot = svg
        .append('circle')
          .attr('cx', 32)
          .attr('cy', iconStartY + 32 )
          .attr('r', 25)
          .attr('class', 'dot-finger-' + x)
          .style('stroke', '#000')
          .style('fill', '#000')
          .style('fill-opacity', '1')
          .on('click', function(event) {
            self.handleFingerIconClick(x, this);
          });

      let text = (x === 5) ? 'T' : x;
      svg.append('text')
        .append('tspan')
        .text(text)
          .attr('class', 'filled-dot-finger-icon-text')
          .style('stroke', 'none')
          .style('fill', '#fff')
          .attr('x', 23)
          .attr('y', iconStartY + 42)
          .style('font-size', '30px')
          .style('font-weight', 'bold')
          .style('cursor', 'pointer')
          .on('click', function(event) {
            self.handleFingerIconClick(x, this);
          });

      iconStartY += 55;
    }
  }

  private handleFingerIconClick(finger: number, thisRef: any): void {
    let outerContainer = d3.select('#' + COMPONENT_IDS.outerContainer);

    this.oService.unsetSelectedTool();
    this.oService.unlockLegend();
    let fingerString = (finger === 5) ? 'T' : finger.toString();
    this.createSelectedToolIcon(fingerString, 16, 36);
    let data = [{
      selectedColor: 'finger',
      previousColor: null,
      finger: finger
    }];

    return outerContainer.data(data);

    // only continue if another tool is not left on
    // if(!this.oService.isLegendLocked()) {
    //   let fingerString = (finger === 5) ? 'T' : finger.toString();
    //   this.createSelectedToolIcon(fingerString, 16, 36);

    //   let data = [{
    //     selectedColor: 'finger',
    //     previousColor: null,
    //     finger: finger
    //   }];

    //   this.oService.lockLegend();

    //   // bind the data to the outer container
    //   return outerContainer.data(data);
    // } else {
    //   this.oService.unsetSelectedTool();

    //   // clear the data
    //   let data = [{
    //     selectedColor: null,
    //     previousColor: null
    //   }];

    //   this.oService.unlockLegend();

    //   // hide the lock icon
    //   d3.select('img.eraser-btn').style('visibility', 'hidden');

    //   //get rid of the left column border color
    //   d3.select('div.eraser').style('border-left', 'solid 3px #eee');

    //   // bind the data to the outer container
    //   return outerContainer.data(data);
    // }
  }

  private createSelectedToolIcon(finger: string, textX: number, textY: number): void {
    // add the icon to the selected tool container
    let iconHomeContainer = d3.select('#' + this.fingerNumberContainerId);
    let svg = iconHomeContainer.select('svg').node();
    let newSvg = this.oService.createCircleIcon(svg, finger, textX, textY);
    // let newSvg = this.oService.createCircleIcon(svg, interval, 12.5, 38);

    this.oService.setSelectedTool(newSvg.node());
  }
}
