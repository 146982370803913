<section>
  <div class="container">
    <div class="tile is-ancestor">
      <div class="column terms-container">
        <h1>Terms of Use</h1>
        <p>The site is free to use but there are no warranties, express or implied - use at your own risk.  While I make every effort
          to test something before I publish, I may not catch everything.  If you find something wrong, use the <a class="" [routerLink]="['/feedback']">feedback link</a> or <a href="https://www.facebook.com/groups/nakedfrets/"  target="_blank" style="">Join the NakedFrets Facebook discussion group</a> to post a bug and I'll get to it as soon as I can.
        </p>
        <h1>Acceptance of Cookie Usage</h1>
        <p>I use Google Analytics to track feature usage - that's it.  I want to know when things are clicked on.
        Nothing nefarious going on.  By using this site, you are agreeing to let me drop standard Google Analytics cookie(s) in your browser (they start with _ga). Help me help you.</p>
      </div>
    </div>
  </div>
</section>
