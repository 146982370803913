import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public handleHamburger(targetSelector: string) {
    d3.select(targetSelector).classed('is-active', d3.select(targetSelector).classed("is-active") ? false : true);
    d3.select('.navbar-burger').classed('is-active', d3.select('.navbar-burger').classed("is-active") ? false : true)
  }

}
