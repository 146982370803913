import { AfterViewInit, Component } from '@angular/core';
import * as d3 from '@node_modules/d3/dist/d3';
import { COMPONENT_IDS, INTERVALS, NOTE_NAMES } from '@src/app/constants/constants';
import { OrchestratorService } from '@src/app/services/orchestrator.service';

@Component({
  selector: 'app-note-names',
  templateUrl: './note-names.component.html',
  styleUrls: ['./note-names.component.scss']
})
export class NoteNamesComponent implements AfterViewInit {

  public noteNamesContainerId: string = COMPONENT_IDS.noteNamesContainer;

  constructor(private oService: OrchestratorService) { }

  ngAfterViewInit(): void {
    this.createIcons();
  }

  private createIcons(): void {
    let self = this;

    let container = d3.select('#' + this.noteNamesContainerId);
    let svg = container
      .append('svg')
        //.attr('viewbox', '0 0 70 1360')
        .attr('viewbox', '0 0 70 950')
        .attr('width', 70)
        //.attr('height', 1360)
        .attr('height', 950)
        .attr('id', 'nf-notenames-svg');

    // draw note name icons
    let note_a = svg
      .append('circle')
        .attr('cx', 30)
        .attr('cy', 30)
        // .attr('cx', 26)
        // .attr('cy', 34)
        .attr('r', 25)
        .attr('class', 'dot-notename-' + NOTE_NAMES.a)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleNoteNameIconClick(NOTE_NAMES.a, this, 15, 35);
        });

    let note_a_text = NOTE_NAMES.a;
    svg.append('text')
      .append('tspan')
      .text(note_a_text)
        .attr('class', 'filled-dot-notename-icon-text')
        .style('stroke', 'none')
        .style('fill', '#fff')
        //.attr('x', 16)
        .attr('x', 20)
        .attr('y', 40)
        .style('font-size', '30px')
        .style('font-weight', 'bold')
        .style('cursor', 'pointer')
        .on('click', function(event) {
          self.handleNoteNameIconClick(NOTE_NAMES.a, this, 15, 35);
        });

    let note_aSharp = svg
      .append('circle')
        .attr('cx', 30)
        .attr('cy', 89)
        .attr('r', 25)
        .attr('class', 'dot-notename-' + NOTE_NAMES.aSharp)
        .style('stroke', '#000')
        .style('fill', '#000')
        .on('click', function(event) {
          self.handleNoteNameIconClick(NOTE_NAMES.aSharp, this, 15, 35);
        });

      let note_aSharp_text = NOTE_NAMES.aSharp;
      svg.append('text')
        .append('tspan')
        .text(note_aSharp_text)
          .attr('class', 'filled-dot-notename-icon-text')
          .style('stroke', 'none')
          .style('fill', '#fff')
          .attr('x', 13)
          .attr('y', 99)
          .style('font-size', '30px')
          .style('font-weight', 'bold')
          .style('cursor', 'pointer')
          .on('click', function(event) {
            self.handleNoteNameIconClick(NOTE_NAMES.aSharp, this, 8, 35);
          });

      let note_b = svg
        .append('circle')
          .attr('cx', 30)
          .attr('cy', 144)
          .attr('r', 25)
          .attr('class', 'dot-notename-' + NOTE_NAMES.b)
          .style('stroke', '#000')
          .style('fill', '#000')
          .on('click', function(event) {
            self.handleNoteNameIconClick(NOTE_NAMES.b, this, 8, 35);
          });

      let note_b_text = NOTE_NAMES.b;
        svg.append('text')
          .append('tspan')
          .text(note_b_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 20)
            .attr('y', 154)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.b, this, 15, 35);
            });

      let note_c = svg
        .append('circle')
          .attr('cx', 30)
          .attr('cy', 199)
          .attr('r', 25)
          .attr('class', 'dot-notename-' + NOTE_NAMES.c)
          .style('stroke', '#000')
          .style('fill', '#000')
          .on('click', function(event) {
            self.handleNoteNameIconClick(NOTE_NAMES.c, this, 15, 35);
          });

      let note_c_text = NOTE_NAMES.c;
        svg.append('text')
          .append('tspan')
          .text(note_c_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 19)
            .attr('y', 210)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.c, this, 15, 35);
            });

      let note_cSharp = svg
        .append('circle')
          .attr('cx', 30)
          .attr('cy', 254)
          .attr('r', 25)
          .attr('class', 'dot-notename-' + NOTE_NAMES.cSharp)
          .style('stroke', '#000')
          .style('fill', '#000')
          .on('click', function(event) {
            self.handleNoteNameIconClick(NOTE_NAMES.cSharp, this, 15, 35);
          });

        let note_cSharp_text = NOTE_NAMES.cSharp;
        svg.append('text')
          .append('tspan')
          .text(note_cSharp_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 12)
            .attr('y', 265)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.cSharp, this, 7, 35);
            });

      let note_d = svg
        .append('circle')
          .attr('cx', 30)
          .attr('cy', 309)
          .attr('r', 25)
          .attr('class', 'dot-notename-' + NOTE_NAMES.d)
          .style('stroke', '#000')
          .style('fill', '#000')
          .on('click', function(event) {
            self.handleNoteNameIconClick(NOTE_NAMES.d, this, 15, 35);
          });

      let note_d_text = NOTE_NAMES.d;
        svg.append('text')
          .append('tspan')
          .text(note_d_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 20)
            .attr('y', 320)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.d, this, 15, 35);
            });

        let note_dSharp = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 364)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.dSharp)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.dSharp, this, 15, 35);
            });

        let note_dSharp_text = NOTE_NAMES.dSharp;
        svg.append('text')
          .append('tspan')
          .text(note_dSharp_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 12)
            .attr('y', 375)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.dSharp, this, 7, 35);
            });

        let note_e = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 419)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.e)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.e, this, 15, 35);
            });

        let note_e_text = NOTE_NAMES.e;
        svg.append('text')
          .append('tspan')
          .text(note_e_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 20)
            .attr('y', 430)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.e, this, 15, 35);
            });

        let note_f = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 474)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.f)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.f, this, 15, 35);
            });

        let note_f_text = NOTE_NAMES.f;
        svg.append('text')
          .append('tspan')
          .text(note_f_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 21)
            .attr('y', 485)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.f, this, 15, 35);
            });

        let note_fSharp = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 529)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.fSharp)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.fSharp, this, 15, 35);
            });

        let note_fSharp_text = NOTE_NAMES.fSharp;
        svg.append('text')
          .append('tspan')
          .text(note_fSharp_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 14)
            .attr('y', 540)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.fSharp, this, 8, 35);
            });

        let note_g = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 584)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.g)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.g, this, 15, 35);
            });

        let note_g_text = NOTE_NAMES.g;
        svg.append('text')
          .append('tspan')
          .text(note_g_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 18)
            .attr('y', 594)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.g, this, 13, 35);
            });

        let note_gSharp = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 639)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.gSharp)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.gSharp, this, 15, 35);
            });

        let note_gSharp_text = NOTE_NAMES.gSharp;
        svg.append('text')
          .append('tspan')
          .text(note_gSharp_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 11)
            .attr('y', 649)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.gSharp, this, 7, 35);
            });

        let note_aFlat = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 694)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.aFlat)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.aFlat, this, 15, 35);
            });

        let note_aFlat_text = NOTE_NAMES.aFlat;
        svg.append('text')
          .append('tspan')
          .text(note_aFlat_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 12)
            .attr('y', 704)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.aFlat, this, 7, 35);
            });

        let note_bFlat = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 749)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.bFlat)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.bFlat, this, 15, 35);
            });

        let note_bFlat_text = NOTE_NAMES.bFlat;
        svg.append('text')
          .append('tspan')
            .text(note_bFlat_text)
          .attr('class', 'filled-dot-notename-icon-text')
          .style('stroke', 'none')
          .style('fill', '#fff')
          .attr('x', 12)
          .attr('y', 759)
          .style('font-size', '30px')
          .style('font-weight', 'bold')
          .style('cursor', 'pointer')
          .on('click', function(event) {
            self.handleNoteNameIconClick(NOTE_NAMES.bFlat, this, 7, 35);
          });

        let note_dFlat = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 804)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.dFlat)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.dFlat, this, 15, 35);
            });

        let note_dFlat_text = NOTE_NAMES.dFlat;
        svg.append('text')
          .append('tspan')
          .text(note_dFlat_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 11)
            .attr('y', 814)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.dFlat, this, 7, 35);
            });

        let note_eFlat = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 859)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.eFlat)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.eFlat, this, 15, 35);
            });

        let note_eFlat_text = NOTE_NAMES.eFlat;
        svg.append('text')
          .append('tspan')
          .text(note_eFlat_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 12)
            .attr('y', 869)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.eFlat, this, 7, 35);
            });

        let note_gFlat = svg
          .append('circle')
            .attr('cx', 30)
            .attr('cy', 914)
            .attr('r', 25)
            .attr('class', 'dot-notename-' + NOTE_NAMES.gFlat)
            .style('stroke', '#000')
            .style('fill', '#000')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.gFlat, this, 15, 35);
            });

        let note_gFlat_text = NOTE_NAMES.gFlat;
        svg.append('text')
          .append('tspan')
          .text(note_gFlat_text)
            .attr('class', 'filled-dot-notename-icon-text')
            .style('stroke', 'none')
            .style('fill', '#fff')
            .attr('x', 11)
            .attr('y', 924)
            .style('font-size', '30px')
            .style('font-weight', 'bold')
            .style('cursor', 'pointer')
            .on('click', function(event) {
              self.handleNoteNameIconClick(NOTE_NAMES.gFlat, this, 7, 35);
            });


  }

  private handleNoteNameIconClick(noteName: string, thisRef: any, textX: number, textY: number): void {
    let outerContainer = d3.select('#' + COMPONENT_IDS.outerContainer);

    this.oService.unsetSelectedTool();
    this.oService.unlockLegend();
    this.createSelectedToolIcon(noteName, textX, textY);
    let data = [{
      selectedColor: 'notename',
      previousColor: null,
      noteName: noteName
    }];

    return outerContainer.data(data);
  }

  private createSelectedToolIcon(notename: string, textX: number, textY: number): void {
    // add the icon to the selected tool container
    let iconHomeContainer = d3.select('#' + this.noteNamesContainerId);
    let svg = iconHomeContainer.select('svg').node();
    let newSvg = this.oService.createCircleIcon(svg, notename, textX, textY);
    // let newSvg = this.oService.createCircleIcon(svg, interval, 12.5, 38);

    this.oService.setSelectedTool(newSvg.node());
  }

}
