<nav class="navbar has-shadow" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">

    <a
      role="button"
      class="navbar-burger ml-0"
      (click)="handleHamburger('#app-navbar')"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="app-navbar" class="navbar-menu">
    <div class="navbar-start">
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          Symbols
        </a>

        <div class="navbar-dropdown">
          <a class="navbar-item">
            <img src="../../assets/images/video-images/change-dot-color.png">
          </a>
          <a class="navbar-item">
            <img src="../../assets/images/video-images/add-barre.png">
          </a>
          <a class="navbar-item">
            <img src="../../assets/images/video-images/clear-all.png">
          </a>
        </div>
      </div>
    </div>

    <div class="navbar-end">
      <!-- <div class="navbar-item">

      </div> -->
    </div>
  </div>
</nav>
