import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public handleVideosPage(): void {
    d3.select('.videos-container').style('display', 'flex');

    d3.select('.app').style('display', 'none');
    d3.select('.why-container').style('display', 'none');
    d3.select('.release-history-container').style('display', 'none');
    d3.select('.terms-container').style('display', 'none');
  }

  public handleSwapVideo(id: string): void {
    // hide all videos
    d3.select('.all-videos').selectAll('.video-container').style('display', 'none');

    // then show the right one
    d3.select('#' + id).style('display', 'flex');

    // move cursor to the anchor
    // window.location.href = '#' + id + '-anchor';
  }
}
