import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public handleTermsPage(): void {
    d3.select('.terms-container').style('display', 'block');

    d3.select('.videos-container').style('display', 'none');
    d3.select('.app').style('display', 'none');
    d3.select('.why-container').style('display', 'none');
    d3.select('.release-history-container').style('display', 'none');
  }

}
